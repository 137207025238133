<template>
    <div class="remote-select">
        <ui-button-link
            v-if="withLink"
            :disabled="!currentValue"
            @click="handleClick"
        />
        <el-select
            ref="select"
            v-model="currentValue"
            :loading="loading"
            :remote-method="remoteMethod"
            :clearable="clearable"
            filterable
            remote
            :reserve-keyword="reserveKeyword"
            :loading-text="loadingText"
            :multiple="multiple"
            :multiple-limit="multipleLimit"
            :placeholder="placeholder"
            :disabled="disabled"
            class="select"
            :title="title"
            :size="size"
            :value-key="objectMode ? valueKey : 'value'"
            @focus="handleFocus"
            @visible-change="handleVisibleChange"
            @change="handleChange"
            @clear="clear"
            @remove-tag="handleRemove"
        >
            <el-option
                v-if="items.length === 0 && currentValue && currentLabel"
                :value="currentValue"
                :label="currentLabel"
                :class="optionClass"
            >
                <slot
                    :item="selectedItem ?? currentValue"
                    name="option"
                />
            </el-option>
            <el-option
                v-for="item in allItems"
                :key="item[valueKey]"
                :value="objectMode ? item : item[valueKey]"
                :class="[{ 'is-group': item?.[groupKey], 'enable-select-group': enableSelectGroup }, optionClass]"
                :label="nameMethod(item) ? nameMethod(item) : item[labelKey]"
                :disabled="(!enableSelectGroup && item?.[groupKey]) || disabledItemMethod(item)"
                :style="setStyle(item)"
            >
                <slot
                    :item="item"
                    name="option"
                >
                    {{ nameMethod(item) ? nameMethod(item) : item[labelKey] }}
                </slot>
                <span
                    v-if="showInactiveLabel && get(item, activeKey) === false"
                    class="select__inactive-label"
                >
                    (деактивирован)
                </span>
            </el-option>

            <template
                v-if="createNew"
                #empty
            >
                <div class="el-select-dropdown__empty">
                    Ничего не найдено
                </div>
                <div class="el-select-dropdown__create-panel">
                    <el-button
                        plain
                        size="small"
                        type="primary"
                        @click="handleCreateButtonClick"
                    >
                        {{ createNewButtonName || 'Добавить' }}
                    </el-button>
                </div>
            </template>
        </el-select>
    </div>
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';
import { get } from 'lodash';
import SelectMixin from '@/mixins/select';

export default {
    name: 'UiRemoteSelect',
    mixins: [WrapperMixin, SelectMixin],

    props: {
        value: {
            type: [String, Array],
            default: ''
        },

        fetchItems: {
            type: Function,
            required: true
        },

        valueKey: {
            type: String,
            default: 'id'
        },

        labelKey: {
            type: String,
            default: 'name'
        },

        createNew: {
            type: Boolean,
            default: false
        },

        createNewButtonName: {
            type: String,
            default: ''
        },

        currentLabel: {
            type: String,
            default: ''
        },

        // Если передано, то можно не указывать currentLabel
        // TODO переименовать, так как неочевидно, что теперь можно пробросить массив
        persistentItem: {
            type: [Object, Array],
            default: null
        },

        nameMethod: {
            type: Function,
            default: () => {
                return null;
            }
        },

        placeholder: {
            type: String,
            default: 'Выбрать'
        },

        multiple: {
            type: Boolean,
            default: false
        },

        multipleLimit: {
            type: Number,
            default: 0
        },

        fetchOnCreate: {
            type: Boolean,
            default: false
        },

        initMethod: {
            type: Function,
            default: () => null
        },

        optionClass: {
            type: String,
            default: ''
        },

        disabled: {
            type: Boolean,
            default: false
        },

        disabledItemMethod: {
            type: Function,
            default: () => false
        },

        setStyle: {
            type: Function,
            default: () => null
        },

        clearable: {
            type: Boolean,
            default: true
        },

        showInactiveLabel: {
            type: Boolean,
            default: false
        },

        activeKey: {
            type: String,
            default: 'active'
        },

        itemsProcessor: {
            type: Function,
            default: items => items
        },

        emitSelectOnClear: {
            type: Boolean,
            default: false
        },

        withLink: {
            type: Boolean,
            default: false
        },

        groupKey: {
            type: String,
            default: 'isGroup'
        },

        enableSelectGroup: {
            type: Boolean,
            default: false
        },

        size: {
            type: String,
            default: undefined
        },

        reserveKeyword: {
            type: Boolean,
            default: false
        },

        // TODO: Может криво работать с какими-то другими свойствами
        objectMode: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            items: [],
            loading: false,
            loadingText: '',
            initLoading: false,
            isFetched: false,
            queryString: ''
        };
    },

    computed: {
        currentValue: {
            get () {
                if (this.initLoading) {
                    return this.multiple ? [] : '';
                } else {
                    return this.value;
                }
            },

            set (value) {
                this.$emit('input', value);
            }
        },

        allItems () {
            // Если есть сохранённое в базе значение, то оно должно быть всегда доступно для выбора
            if (!this.queryString) {
                if (
                    Array.isArray(this.persistentItem) &&
                    this.persistentItem.length &&
                    (this.items.length === 0 || !this.items.some(item => this.persistentItem?.some(i => i.id === item?.id)))
                ) {
                    return this.itemsProcessor([...this.persistentItem, ...this.items]);
                }

                if (
                    !Array.isArray(this.persistentItem) &&
                    this.persistentItem &&
                    !this.items.some(item => item?.id === this.persistentItem?.id)
                ) {
                    return this.itemsProcessor([this.persistentItem, ...this.items]);
                }
            }

            return this.itemsProcessor(this.items);
        },

        selectedItem () {
            return this.allItems.find(item => item[this.valueKey] === this.currentValue);
        }
    },

    created () {
        if (this.fetchOnCreate) {
            this.init();
        }
    },

    methods: {
        handleRemove (removedValue) {
            this.$emit('remove-tag', removedValue);
        },
        get,

        focus () {
            this.$refs.select.focus();
        },

        blur () {
            this.$refs.select.blur();
        },

        clear () {
            this.currentValue = Array.isArray(this.value) ? [] : '';
            this.resetItems();
            if (this.emitSelectOnClear) {
                this.$emit('select', null);
            }
        },

        remoteMethod (queryString) {
            this.loading = true;
            this.loadingText = '';
            this.queryString = queryString;
            this.fetchItems(queryString, items => {
                if (items.length) {
                    this.loading = false;
                }
                this.loadingText = 'Нет данных';
                this.items = Array.isArray(items) ? items : [];
                this.isFetched = true;
            });
        },

        async init () {
            this.initLoading = true;
            this.items = await this.initMethod(this.value) || [];
            this.initLoading = false;
        },

        handleFocus () {
            if (!this.isFetched) {
                this.remoteMethod();
            }
            this.$emit('focus');
        },

        handleVisibleChange (value) {
            if (this.queryString) {
                this.queryString = '';
                this.isFetched = false;
            }
            this.$emit('visible-change', value);
        },

        handleCreateButtonClick () {
            const input = this.$el.querySelector('.el-input__inner');
            if (input) {
                this.$emit('create', input.value);
            } else {
                this.$emit('create', '');
            }
        },

        handleChange (value) {
            if (Array.isArray(value)) {
                this.$emit('select', this.allItems.filter(item => value.includes(item?.[this.valueKey])));
            } else {
                this.$emit('select', this.allItems.find(item => item?.[this.valueKey] === value));
            }

            if (this.queryString) {
                this.resetItems();
            }
            this.queryString = '';
        },

        resetItems () {
            this.isFetched = false;
            this.items = [];
        },

        getSelectedItem () {
            return this.selectedItem;
        },

        handleClick () {
            this.$emit('link-click', this.currentValue);
        }
    }
};
</script>

<style lang="scss">
.remote-select {
    display: flex;
    align-items: center;
    width: 100%;

    .select {
        &__inactive-label {
            color: $--color-text-secondary;
            font-size: 12px;
        }
    }
}
</style>
