<template>
    <el-popover
        ref="popover"
        class="select-tree"
        popper-class="select-tree__popover"
        @show="handleFocus"
    >
        <template #reference>
            <div
                ref="select"
                class="el-select select"
                :class="`el-select--${size}`"
            >
                <div
                    ref="tags"
                    class="el-select__tags"
                    :style="{ 'max-width': inputWidth - 32 + 'px', width: '100%' }"
                >
                    <span>
                        <el-tag
                            v-for="(val, index) in shownItems"
                            :key="val.id ?? index"
                            size="mini"
                            :closable="!selectDisabled"
                            disable-transitions
                            :title="val.name ?? val"
                            @close="handleClose(val)"
                        >
                            <div class="el-select__tags-text">
                                {{ val.name ?? val }}
                            </div>
                        </el-tag>
                    </span>
                </div>
                <el-input
                    ref="reference"
                    readonly
                    :size="size"
                    :placeholder="currentValue.length === 0 ? 'Выбрать' : ''"
                />
            </div>
        </template>

        <el-scrollbar>
            <div class="select-tree__wrapper">
                <ui-select-tree-group
                    v-model="currentValue"
                    :fetch-items="fetchItems"
                    :visible="visible"
                    :local-items.sync="localValue"
                    :persistent-items="persistentItems"
                    :disabled="selectDisabled"
                    @collapse-change="handleCollapseChange"
                >
                    <template #default="{ item }">
                        <slot :item="item" />
                    </template>
                </ui-select-tree-group>
            </div>
        </el-scrollbar>
    </el-popover>
</template>

<script setup lang="ts">
import { computed, inject, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';

/**
 * Компонент очень негибкий получился, но по логике для редактирования прав подходит
 * Да и вряд ли где-то еще будет нужна подобная хуйня
 */

const elForm = inject('elForm', '');

interface Props {
    value: string[],
    fetchItems: (query: string, node: any, cb: (items: any[]) => void) => void,
    persistentItems?: any[],
    disabled?: boolean,
    size?: 'medium' | 'small' | 'mini'
}

const props = withDefaults(defineProps<Props>(), {
    size: 'medium',
    disabled: false,
    persistentItems: () => []
});

const emit = defineEmits<{
    (e: 'input', value: string[]): void,
    (e: 'change', value: any[]): void
}>();

const visible = ref(false);
const popover = ref(null);
const localValue = ref([]);
const shownItems = computed(() => props.value.map(i =>
    localValue.value.find(val => val.id === i) ??
    props.persistentItems.find(val => val.id === i) ??
    i
));
const selectDisabled = computed(() => {
    return props.disabled || (elForm || {}).disabled;
});

const currentValue = computed({
    get () {
        return props.value;
    },
    set (val: string[]) {
        emit('input', val);
    }
});

onMounted(() => {
    localValue.value = props.persistentItems;

    observe();
    if (reference.value) {
        const sizeMap = {
            medium: 36,
            small: 32,
            mini: 28
        };
        const input = reference.value.$el.querySelector('input');
        initialInputHeight.value = input.getBoundingClientRect().height || sizeMap[props.size];
    }

    watch(localValue, (val) => {
        resetInputHeight();
        emit('input', val.map(i => i.id));
        emit('change', val);
    });
});

onBeforeUnmount(() => {
    resizeObserver.value?.unobserve(select.value);
});

function handleFocus () {
    visible.value = true;
}

function handleCollapseChange () {
    setTimeout(() => {
        popover.value?.updatePopper();
    }, 350);
}

function handleClose (item) {
    const val = localValue.value.slice();
    const optionIndex = val.findIndex(i => i.id === item.id);
    val.splice(optionIndex, 1);
    localValue.value = val;
}

const reference = ref(null);
const tags = ref(null);
const initialInputHeight = ref(0);

function resetInputHeight () {
    nextTick(() => {
        if (!reference.value) {
            return;
        }
        const inputChildNodes = reference.value.$el.childNodes;
        const input = [].filter.call(inputChildNodes, item => item.tagName === 'INPUT')[0];
        const tagsHeight = tags.value ? Math.round(tags.value.getBoundingClientRect().height) : 0;
        const sizeInMap = initialInputHeight.value || 40;
        input.style.height = currentValue.value.length === 0
            ? sizeInMap + 'px'
            : Math.max(tags.value ? (tagsHeight + (tagsHeight > sizeInMap ? 6 : 0)) : 0, sizeInMap) + 'px';
    });
}

const select = ref<HTMLElement | null>(null);
const inputWidth = ref(0);
const resizeObserver = ref<ResizeObserver | null>(null);

function resetInputWidth () {
    inputWidth.value = reference.value?.$el.getBoundingClientRect().width;
}

function observe () {
    if (select.value) {
        resizeObserver.value = new ResizeObserver(handleResize);
        resizeObserver.value.observe(select.value as HTMLElement);
    }
}

function handleResize () {
    resetInputWidth();
    resetInputHeight();
}

function handleChange (items: any[]) {
    localValue.value = items;
}
</script>

<style lang="scss">
.select-tree {
  &__popover {
    padding: 0;
  }

  &__wrapper {
    max-height: 274px;
    padding: 3px 0;
  }

  &__input {
    flex-grow: 1;
    width: 0.143885%;
    max-width: 129px;
  }
}
</style>
