import { getContractTemplate, createContractTemplate, deleteContractTemplate, getContractTemplates, updateContractTemplate } from '@/api/contractTemplate';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return getContractTemplates(params);
    },
    doFetchSingle (id: string) {
        return getContractTemplate(id);
    },
    doCreate (data: object) {
        return createContractTemplate(data);
    },
    doUpdate (id: string, data: object) {
        return updateContractTemplate(id, data);
    },
    doDelete (id: string) {
        return deleteContractTemplate(id);
    }
});
