<template>
    <div class="bar-chart-scale">
        <div
            v-for="mark in marks"
            :key="mark"
            class="bar-chart-scale__mark"
        >
            <div class="bar-chart-scale__mark-inner">
                {{ formatNumber(mark, 0) }}
            </div>
        </div>
    </div>
</template>

<script>
import FormatMixin from '@/mixins/format';

export default {
    name: 'UiBarChartScale',
    mixins: [FormatMixin],
    props: {
        maxValue: {
            type: Number,
            required: true,
        },

        numberOfMarks: {
            type: Number,
            default: 5,
        },
    },

    computed: {
        marks () {
            const marks = [];

            for (let i = 0; i < this.numberOfMarks; i++) {
                marks.push(this.maxValue * i / (this.numberOfMarks - 1));
            }

            return marks;
        },
    },
};
</script>

<style lang="scss">
.bar-chart-scale {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $--color-text-secondary;
    }

    &__mark {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: -4px;
            width: 1px;
            height: 9px;
            background-color: $--color-text-secondary;
        }
    }

    &__mark-inner {
        position: absolute;
        left: 0;
        top: 8px;
        transform: translateX(-50%);
        color: $--color-text-secondary;
        font-size: 11px;
    }
}
</style>
