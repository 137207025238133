import { createSickList, getSickList, updateSickList } from '@/api/staff/personnelDocument';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchSingle (id, params) {
        return getSickList(id, params);
    },
    doCreate (data) {
        return createSickList(data);
    },
    doUpdate (id, data) {
        return updateSickList(id, data);
    },
});
