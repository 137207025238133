import * as InvoiceAPI from '@/api/contractor/invoice';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return InvoiceAPI.getInvoices(params);
    },
    doFetchSingle (id, params) {
        return InvoiceAPI.getInvoice(id, params);
    },
    doCreate (data, params) {
        return InvoiceAPI.createInvoice(data, params);
    },
    doUpdate (id, data, params) {
        return InvoiceAPI.updateInvoice(id, data, params);
    },
    doDelete (id) {
        return InvoiceAPI.deleteInvoice(id);
    },
});
