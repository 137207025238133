<template>
    <ui-remote-select
        ref="select"
        v-model="currentValue"
        :current-label="currentLabel"
        :fetch-items="fetchReorderedItems"
        :set-style="setStyle"
        v-bind="proxyProps"
        v-on="$listeners"
    />
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';
import { reorderNested } from '@/utils';
import SelectNestedMixin from '@/mixins/select-nested';
import { uniqBy } from 'lodash';

export default {
    name: 'UiRemoteSelectNested',

    mixins: [
        WrapperMixin,
        SelectNestedMixin,
    ],

    props: {
        value: {
            type: [String, Array],
            default: '',
        },

        fetchItems: {
            type: Function,
            required: true,
        },

        showParentsWhenFilter: {
            type: Boolean,
            default: false,
        },
    },

    data () {
        return {
            items: [],
        };
    },

    computed: {
        parents () {
            return this.items.reduce((newArr, currentItem) => {
                if (currentItem.parent && !newArr.find(item => item.id === currentItem.parent?.id)) {
                    newArr.push(currentItem.parent);
                }
                return newArr;
            }, []);
        },
    },

    methods: {
        blur () {
            this.$refs.select.blur();
        },

        reorderData (items) {
            return [...this.beforeItems, ...reorderNested(items, this.sortByField)];
        },

        fetchReorderedItems (query, cb) {
            this.fetchItems(query, items => {
                if (query && this.showParentsWhenFilter) {
                    // TODO Эта ебанина перестанет работать правильно, если будет несколько уровней вложенности
                    const data = [];
                    items.forEach(item => {
                        if (item?.parent) {
                            if (!data.find(c => c.id === item?.parent?.id)) {
                                data.push(item?.parent, { ...item, level: 1 });
                            } else {
                                data.push({ ...item, level: 1 });
                            }
                        } else {
                            data.push({ ...item, level: 0 });
                        }
                    });
                    // В расшифровке у договоров кредита на фронте докидываю итемы родителей,
                    // поэтому доп. фильтрация, чтобы не объебывалось в дубликаты
                    this.items = uniqBy(data, 'id');
                } else {
                    this.items = items;
                }

                cb(this.reorderData(this.items));
            });
        },

        resetItems () {
            this.$refs.select.resetItems();
            this.items = [];
        },
    },
};
</script>
