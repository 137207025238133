import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { ContractorAccount } from '@/types/Account';

const baseUri = '/contractor_accounts';

export const getAccounts = (params: object): Promise<PagedCollection<ContractorAccount>> =>
    request({
        url: baseUri,
        method: 'GET',
        params: {
            'contractorRequisite.isThirdPerson': false,
            ...params,
        },
    });

export const getAccount = (id: string): Promise<ContractorAccount> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    });

export const getThirdPersonAccount = async (params: object): Promise<ContractorAccount | null> => {
    const data: PagedCollection<ContractorAccount> = await request({
        url: baseUri,
        method: 'GET',
        params: {
            'contractorRequisite.isThirdPerson': true,
            ...params,
        },
    });

    return data['hydra:member']?.[0] ?? null;
};

export const createAccount = ({ data, params }: { data: object, params: object }): Promise<ContractorAccount> =>
    request({
        url: '/contractor_bank_accounts',
        method: 'POST',
        params,
        data,
    });

export const updateAccount = ({ id, data, params }: { id: string, data: object, params: object }): Promise<ContractorAccount> =>
    request({
        url: `/contractor_bank_accounts/${id}`,
        method: 'PATCH',
        params,
        data,
    });

export const deleteAccount = ({ id }: { id: string }): Promise<void> =>
    request({
        url: `/contractor_bank_accounts/${id}`,
        method: 'DELETE',
    });

export const moveToBusinessAccount = ({ id, data, params }: { id: string, data: object, params: object }) =>
    request({
        url: `/contractor_accounts/${id}/move_to_business_account`,
        method: 'POST',
        data,
        params,
    });
