<template>
    <el-menu
        router
        mode="horizontal"
        :default-active="activeMenu"
        class="nav-menu"
    >
        <template v-for="(item, index) in items">
            <el-submenu
                v-if="!flat && (!item.meta || !item.meta.hidden) && item.children && item.children.length > 0"
                :key="`${index}-submenu`"
                :index="resolvePath(item.name)"
                popper-class="nav-submenu"
                :show-timeout="100"
            >
                <template slot="title">
                    {{ item.meta && item.meta.title ? item.meta.title : item.name }}
                </template>
                <template v-for="(child, childIndex) in item.children">
                    <el-menu-item
                        v-if="!child.hidden"
                        :key="childIndex"
                        :index="resolvePath(child.name)"
                    >
                        <router-link
                            :to="resolvePath(child.name)"
                            class="nav-submenu__link"
                        >
                            {{ child.meta && child.meta.title ? child.meta.title : child.name }}
                        </router-link>
                    </el-menu-item>
                </template>
            </el-submenu>
            <el-menu-item
                v-else-if="!item.meta || !item.meta.hidden"
                :key="`${index}-menu-item`"
                :index="resolvePath(getFirstChildRoute(item)?.name)"
                class="nav-menu__item"
                :class="{ 'is-active': $route.matched.some(route => route.name === item.name) }"
            >
                <router-link
                    :to="resolvePath(getFirstChildRoute(item)?.name)"
                    class="nav-menu__link"
                >
                    {{ item.meta && item.meta.title ? item.meta.title : item.name }}
                </router-link>
            </el-menu-item>
        </template>
    </el-menu>
</template>

<script>
import { getFirstChildRoute } from '@/utils/route';

export default {
    props: {
        items: {
            type: Array,
            required: true,
        },

        params: {
            type: Object,
            default: () => ({}),
        },

        flat: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        activeMenu () {
            return String(this.$route.path);
        },
    },

    methods: {
        getFirstChildRoute,
        resolvePath (name) {
            const resolved = this.$router.resolve({
                name,
                params: this.params,
            });

            return resolved.href;
        },
    },
};
</script>
