import DaDataAPI from '@/utils/dadata';
import { getDadataKey } from '@/api/integration/businessIntegration';

export default {
    namespaced: true,

    state: {
        token: '',
        location: null,

        isFetchingBusinessToken: false,
        fetchingBusinessTokenError: null,
        isFetchingAddressByIp: false,
        fetchingAddressByIpError: null,
    },

    getters: {
        hasToken: state => state.token !== null && state.token !== '',

        hasLocation: state => state.location !== null,

        isLoading: state => state.isFetchingBusinessToken || state.isFetchingAddressByIp,
    },

    mutations: {
        FETCH_BUSINESS_TOKEN_START (state) {
            state.isFetchingBusinessToken = true;
        },

        FETCH_BUSINESS_TOKEN_SUCCESS (state, token) {
            state.isFetchingBusinessToken = false;
            state.fetchingBusinessTokenError = null;
            state.token = token;
        },

        FETCH_BUSINESS_TOKEN_ERROR (state, error) {
            state.isFetchingBusinessToken = false;
            state.fetchingBusinessTokenError = error;
            state.token = '';
        },

        FETCH_ADDRESS_BY_IP_START (state) {
            state.isFetchingAddressByIp = true;
        },

        FETCH_ADDRESS_BY_IP_SUCCESS (state, data) {
            state.isFetchingAddressByIp = false;
            state.fetchingAddressByIpError = null;
            state.location = data.location;
        },

        FETCH_ADDRESS_BY_IP_ERROR (state, error) {
            state.isFetchingAddressByIp = false;
            state.fetchingAddressByIpError = error;
            state.location = null;
        },

        SET_TOKEN (state, token) {
            state.token = token;
        },
    },

    actions: {
        async fetchBusinessToken ({ commit }, { businessId }) {
            commit('FETCH_BUSINESS_TOKEN_START');
            try {
                // const { config } = await IntegrationEngineAPI.findByCode('dadata', { businessId });
                // const apiKey = config && config.apiKey ? config.apiKey : '';
                const apiKey = await getDadataKey({
                    business: businessId,
                });
                commit('FETCH_BUSINESS_TOKEN_SUCCESS', apiKey || '');
                return apiKey;
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    commit('FETCH_BUSINESS_TOKEN_SUCCESS', '');
                    return '';
                }
                commit('FETCH_BUSINESS_TOKEN_ERROR', error);
                return null;
            }
        },

        async fetchLocationByIp ({ commit }) {
            commit('FETCH_ADDRESS_BY_IP_START');
            try {
                const { data } = await DaDataAPI.getAddressByIp();
                commit('FETCH_ADDRESS_BY_IP_SUCCESS', data);
                return data;
            } catch (error) {
                commit('FETCH_ADDRESS_BY_IP_ERROR', error);
                return null;
            }
        },

        changeToken ({ commit }, { token }) {
            commit('SET_TOKEN', token);
        },

        resetToken ({ commit }) {
            commit('SET_TOKEN', '');
        },
    },
};
