<template>
    <ui-table-filter-select
        v-model="currentValue"
        v-bind="proxyProps"
        :fetch-items="fetchContractorsWithRequisites"
        :init-method="fetchInitFilterContractorsWithRequisites"
        :set-style="nested ? setStyle : () => {}"
        :disabled-item-method="disabledItemMethod"
        remote
        enable-select-group
        v-on="$listeners"
        @select="handleSelect"
    />
</template>
<script>
import WrapperMixin from '@/mixins/wrapper';
import SelectNestedMixin from '@/mixins/select-nested';
import { getContractorsWithRequisites } from '@/api/search';
import { getContractors } from '@/api/contractor/contractor';
import { getRequisites } from '@/api/requisite';

export default {
    name: 'UiTableFilterContractorWithRequisites',

    mixins: [WrapperMixin, SelectNestedMixin],

    props: {
        multiple: {
            type: Boolean,
            default: true,
        },

        fetchOnCreate: {
            type: Boolean,
            default: false,
        },

        value: {
            type: [String, Array],
            default: '',
        },

        nested: {
            type: Boolean,
            default: true,
        },

        fetchParams: {
            type: Object,
            default: null,
        },

        requisites: {
            type: Array,
            default: null,
        },

        contractors: {
            type: Array,
            default: null,
        }
    },

    computed: {
        business () {
            return this.$store.getters['business/current'];
        },
    },

    methods: {
        async fetchContractorsWithRequisites (query, cb) {
            const params = {
                business: this.business.id,
                withoutThirdPersonRequisites: true
            };

            if (query) {
                params.query = query;
            }

            try {
                const data = await getContractorsWithRequisites(params);
                const items = (data.contractors || []).flatMap(i => {
                    const contractor = {
                        id: i.id,
                        name: i.name,
                        isGroup: true,
                    };

                    return [
                        contractor,
                        ...i.requisites.map(requisite => ({
                            id: requisite.id,
                            name: requisite.shortName,
                            parent: contractor,
                            type: 'requisite',
                        })),
                    ];
                });
                cb(items);
            } catch (e) {
                this.$flashError(e);
            }
        },

        async fetchInitFilterContractorsWithRequisites () {
            const items = [];

            if (this.contractors.length) {
                const contractors = await this.fetchInitContractors();
                items.push(...contractors.map(i => ({ ...i, type: 'parent' })));
            }

            if (this.requisites.length) {
                const requisites = await this.fetchInitRequisites();
                items.push(...requisites.map(i => ({ ...i, name: i.info?.shortName })));
            }

            return items;
        },

        async fetchInitContractors () {
            const params = {
                business: this.business.id,
                id: this.contractors,
            };

            const data = await getContractors(params);
            return data['hydra:member'] || [];
        },

        async fetchInitRequisites () {
            const params = {
                business: this.business.id,
                id: this.requisites,
            };

            const data = await getRequisites(params);
            return data['hydra:member'] || [];
        },

        handleSelect (item) {
            this.$emit('update:selectedItem', item);
            this.$emit('select', item);
        },

        disabledItemMethod (item) {
            if (this.contractors.length > 0 || this.requisites.length > 0) {
                return Boolean(this.contractors.length > 0) === Boolean(item.parent);
            }
            return false;
        },
    },
};
</script>
