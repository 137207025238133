import type { VueConstructor, WritableComputedRef } from 'vue';
import Vue, { computed, getCurrentInstance } from 'vue';
import VueI18n from 'vue-i18n';

let i18nInstance: VueI18n | undefined;

export function createI18n (options?: VueI18n.I18nOptions): VueI18n {
    i18nInstance = new VueI18n(options);

    return i18nInstance;
}

export interface Composer {
    locale: WritableComputedRef<string>;
    t: typeof VueI18n.prototype.t;
    tc: typeof VueI18n.prototype.tc;
    te: typeof VueI18n.prototype.te;
    d: typeof VueI18n.prototype.d;
    n: typeof VueI18n.prototype.n;
}

/**
 * В I18n@8 нет поддержки Composition API, а 9 версия только для Vue 3
 * По идее при переходе на 3 версию можно безболезненно заменить composable
 * https://github.com/intlify/vue-i18n-composable/blob/master/src/index.ts
 */
export function useI18n (): Composer {
    if (!i18nInstance) throw new Error('vue-i18n not initialized');

    const i18n = i18nInstance;

    const instance = getCurrentInstance();
    const vm =
        instance?.proxy ||
        (instance as unknown as InstanceType<VueConstructor>) ||
        new Vue({});

    const locale = computed({
        get () {
            return i18n.locale;
        },
        set (v: string) {
            i18n.locale = v;
        },
    });

    return {
        locale,
        t: vm.$t.bind(vm),
        tc: vm.$tc.bind(vm),
        d: vm.$d.bind(vm),
        te: vm.$te.bind(vm),
        n: vm.$n.bind(vm),
    };
}
