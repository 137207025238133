import type { AxiosRequestConfig } from 'axios';
import request from '@/utils/request';
import { Genders } from '@/enums';

const baseUri = '/users';

export const accessRecovery = (data: any) =>
    request({
        url: `${baseUri}/access_recovery`,
        method: 'POST',
        data,
    } as AxiosRequestConfig);

export const resetPassword = (data: any) =>
    request({
        url: `${baseUri}/reset_password`,
        method: 'POST',
        data,
    } as AxiosRequestConfig);

/** @deprecated **/
export const getUser = (id: string, params: any) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    } as AxiosRequestConfig);

export const getMe = (params: any) =>
    request({
        url: `${baseUri}/me`,
        method: 'GET',
        params,
    } as AxiosRequestConfig);

export const updateUser = (id: string, data: any, params?: object) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
        params
    } as AxiosRequestConfig);

export const getPermissions = (userId: string) =>
    request({
        url: `${baseUri}/${userId}/rights`,
        method: 'GET',
    } as AxiosRequestConfig);

export const changePassword = (id: string, data: any) =>
    request({
        url: `${baseUri}/${id}/change_password`,
        method: 'POST',
        data,
    } as AxiosRequestConfig);

export interface CompleteSignupRequest {
    userId: string;
    invitationCode: string;
    password: string;
    lastName: string;
    firstName: string;
    patronymic?: string | null;
    gender?: Genders | null,
    phoneNumber?: string | null;
}

export interface CompleteSignupResponse {
    token: string;
}

export const completeSignup = ({ userId, ...data }: CompleteSignupRequest): Promise<CompleteSignupResponse> =>
    request({
        url: `${baseUri}/${userId}/complete_signup`,
        method: 'POST',
        data,
    } as AxiosRequestConfig);

export interface ImpersonateRequest {
    userId: string;
}

export interface ImpersonateResponse {
    token: string;
}

export const impersonate = ({ userId }: ImpersonateRequest): Promise<ImpersonateResponse> =>
    request({
        url: `${baseUri}/${userId}/impersonate`,
        method: 'POST',
    } as AxiosRequestConfig);
