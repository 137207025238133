import request from '@/utils/request';

const baseUri = '/account_groups';

export const getAccountGroups = (params) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getAccountGroup = (id, params) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createAccountGroup = (data) =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });

export const updateAccountGroup = (id, data) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });

export const deleteAccountGroup = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });
