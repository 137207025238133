<template>
    <el-tag
        ref="tag"
        :color="color"
        :style="`border-color: ${color}; color: ${textColor}`"
        v-bind="proxyProps"
        v-on="$listeners"
    >
        <slot />
    </el-tag>
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';

export default {
    name: 'UiStatus',

    mixins: [WrapperMixin],

    props: {
        color: {
            type: String,
            required: true,
        },
    },

    computed: {
        textColor () {
            return this.$tinycolor(this.color).getBrightness() < 160 ? '#fff' : '#000';
        },
    },
};
</script>
