import type { PluginFunction } from 'vue';
import Vue from 'vue';

declare module 'vue/types/vue' {
    interface Vue {
        $dirty: boolean;
    }
}

export default ((app: typeof Vue): void => {
    if (app.prototype.$dirty) {
        return;
    }

    Object.defineProperties(app.prototype, {
        $dirty: {
            get (): boolean {
                return Boolean(this.$route.meta.isDirty ?? false);
            },
            set (value: boolean) {
                this.$route.meta.isDirty = value;
                this.$drawer.markDirty(value, this.$route);
            },
        },
    });
}) as PluginFunction<any>;
