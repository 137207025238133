import type { PluginFunction } from 'vue';
import Vue from 'vue';
import Element from 'element-ui';
import locale from 'element-ui/lib/locale';
import lang from 'element-ui/lib/locale/lang/ru-RU';
import '@/styles/element-ui.scss';

export default ((app: typeof Vue): void => {
    locale.use(lang);
    app.use(Element, { size: 'medium' });
}) as PluginFunction<any>;
