import * as api from '@/api/money/transaction';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    only: ['FETCH_LIST', 'FETCH_SINGLE', 'UPDATE', 'DELETE', 'RESET'],
    doFetchList (params) {
        return api.getTransactions(params);
    },
    doFetchSingle (id, params) {
        return api.getTransaction(id, params);
    },
});
