import { omit } from 'lodash';
import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import type { AxiosRequestConfig } from 'axios';
import { ContractorProject } from '@/types/ContractorProject';

const baseUri = '/contractor_projects';

export interface GetProjectsRequest {
    business?: string | string[];
    id?: string | string[];
    contractor?: string | string[];
    name?: string;
    responsible?: string | string[];
    externalId?: string | string[];
    page?: number;
    pageSize?: number;
    order?: {
        id?: 'asc' | 'desc',
        name?: 'asc' | 'desc',
    };
}

export const getProjects = (params: GetProjectsRequest): Promise<PagedCollection<ContractorProject>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    } as AxiosRequestConfig);

export const getProject = ({ projectId }: { projectId: string }): Promise<ContractorProject> =>
    request({
        url: `${baseUri}/${projectId}`,
        method: 'GET',
    } as AxiosRequestConfig);

export interface CreateProjectRequest {
    contractor: string;
    name: string;
    responsible: string;
}

export const createProject = (payload: CreateProjectRequest): Promise<ContractorProject> =>
    request({
        url: baseUri,
        method: 'POST',
        data: payload,
    } as AxiosRequestConfig);

export interface UpdateProjectRequest {
    projectId: string;
    name?: string;
    responsible?: string;
}

export const updateProject = (payload: UpdateProjectRequest): Promise<ContractorProject> =>
    request({
        url: `${baseUri}/${payload.projectId}`,
        method: 'PATCH',
        data: omit(payload, ['projectId']),
    } as AxiosRequestConfig);

export const deleteProject = ({ projectId }: { projectId: string }): Promise<null> =>
    request({
        url: `${baseUri}/${projectId}`,
        method: 'DELETE',
    } as AxiosRequestConfig);
