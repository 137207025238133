import Vue from 'vue';

const createState = () => {
    let state = {
        navDrawerCollapsed: false,
    };
    try {
        if (localStorage.getItem('ui_state')) {
            const persistState = JSON.parse(localStorage.getItem('ui_state'));
            if (typeof persistState === 'object') {
                state = { ...state, ...persistState };
            }
        }
    } catch {
        // empty
    }

    return state;
};

export default {
    namespaced: true,
    state: createState(),
    getters: {
        isNavDrawerCollapsed: state => state.navDrawerCollapsed,
    },
    mutations: {
        SET_NAV_DRAWER_COLLAPSE_STATE (state, value) {
            state.navDrawerCollapsed = value;
        },
    },
    actions: {
        showNavDrawer ({ commit, dispatch }) {
            commit('SET_NAV_DRAWER_COLLAPSE_STATE', false);
            dispatch('persist');
        },
        hideNavDrawer ({ commit, dispatch }) {
            commit('SET_NAV_DRAWER_COLLAPSE_STATE', true);
            dispatch('persist');
        },
        toggleNavDrawer ({ getters, dispatch }) {
            if (getters.isNavDrawerCollapsed) {
                dispatch('showNavDrawer');
            } else {
                dispatch('hideNavDrawer');
            }
        },
        persist ({ state }) {
            try {
                localStorage.setItem('ui_state', JSON.stringify({
                    navDrawerCollapsed: state.navDrawerCollapsed,
                }));
            } catch {
                // empty
            }
        },
    },
    modules: {
        routeDrawer: {
            namespaced: true,
            state: {
                routes: [],
                options: [],
            },
            getters: {
                items: state => state.routes.map((route, index) => ({ route, options: state.options[index] })),
                routeIndex: state => route =>
                    state.routes.findIndex(({ path, name }) => {
                        if (route.path && route.path === path) {
                            return true;
                        } else if (route.name && route.name === name) {
                            return true;
                        }
                        return false;
                    }),
            },
            mutations: {
                PUSH (state, { route, options }) {
                    state.routes.push(route);
                    state.options.push(options ?? {});
                },
                POP (state) {
                    state.routes.pop();
                    state.options.pop();
                },
                REPLACE (state, { index, route, options }) {
                    Vue.set(state.routes, index, route);
                    Vue.set(state.options, index, options ?? {});
                },
                UPDATE_OPTIONS (state, { index, options, extend }) {
                    Vue.set(state.options, index, extend ? { ...state.options[index], ...options } : options);
                },
            },
            actions: {
                push ({ commit }, { route, options }) {
                    commit('PUSH', { route, options });
                },
                pop ({ commit }) {
                    commit('POP');
                },
                replace ({ commit, getters }, { from, to, options }) {
                    if (getters.items.length === 0) {
                        return;
                    }
                    const fromIndex = from ? getters.routeIndex(from) : getters.items.length - 1;
                    if (fromIndex >= 0) {
                        commit('REPLACE', { index: fromIndex, route: to, options });
                    }
                },
                updateOptions ({ commit, getters }, { options, route, extend = false }) {
                    if (getters.items.length === 0) {
                        return;
                    }
                    const index = route ? getters.routeIndex(route) : getters.items.length - 1;
                    if (index >= 0) {
                        commit('UPDATE_OPTIONS', { index, options, extend });
                    }
                },
            },
        },
    },
};
