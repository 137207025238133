<template>
    <el-alert
        v-if="message"
        type="error"
        :closable="false"
        show-icon
        class="u-mb-sm"
    >
        <template #title>
            <div v-html="message" />
        </template>
    </el-alert>
</template>

<script>
import { getMessageFromError } from '@/utils';

export default {
    name: 'UiError',

    props: {
        error: {
            type: [Array, Error, String],
            default: null,
            validator: v => {
                return ['object', 'string'].includes(typeof v) || v === null;
            },
        },

        scrollOnSet: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        message () {
            const errors = Array.isArray(this.error) ? this.error : [this.error];

            const messages = errors
                .filter(error => error !== null)
                .map(error => {
                    return getMessageFromError(error);
                });

            return messages.join('<br/>');
        },
    },

    watch: {
        error (value) {
            if (value !== null && this.scrollOnSet) {
                this.$nextTick(() => {
                    this.$el.scrollIntoView({ behavior: 'smooth' });
                });
            }
        },
    },
};
</script>
