<template>
    <div
        v-loading="isLoading"
        class="stage-list"
        :style="{ height: height, maxHeight: maxHeight }"
    >
        <div
            ref="itemsWrapper"
            class="stage-list__main"
        >
            <div
                v-for="(item, index) in data"
                :key="item.id"
                :class="['stage-list__item-wrapper', { '--disabled': item[itemProps.disabled] || !canEdit }]"
            >
                <ui-stage-list-item
                    :name="item[itemProps.name]"
                    :value="item[itemProps.value]"
                    :disabled="item[itemProps.disabled] || !canEdit"
                    :color="item[itemProps.color]"
                    :icon="item[itemProps.icon]"
                    :disabled-icon="item[itemProps.disabledIcon]"
                    :min-width="item[itemProps.minWidth]"
                    :no-icon="item[itemProps.noIcon]"
                    :rules="item[itemProps.rules]"
                    @edit="handleItemEdit"
                    @drag-end="$emit('rule-drag-end', { ...$event, stage: item })"
                >
                    <template #default>
                        <span :title="item[itemProps.name]">
                            {{ item[itemProps.name] }}
                        </span>
                    </template>
                    <template #list>
                        <slot :item="item" />
                    </template>
                    <template #append>
                        <slot
                            name="append"
                            :stage="item"
                        />
                    </template>
                </ui-stage-list-item>
                <el-button
                    v-if="!creationDisabled(index) && index < data.length - 1"
                    :disabled="!canCreate"
                    type="flat"
                    size="mini"
                    class="stage-list__button"
                    circle
                    @click="handleAddStage(index)"
                >
                    <iconify-icon icon="fa6-solid:plus" />
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Sortable from 'sortablejs';

export default {
    name: 'UiStageList',

    props: {
        data: {
            type: Array,
            required: true,
        },

        props: {
            type: Object,
            default: null,
        },

        isLoading: {
            type: Boolean,
            default: false,
        },

        canCreate: {
            type: Boolean,
            default: true,
        },

        canEdit: {
            type: Boolean,
            default: true,
        },

        maxHeight: {
            type: String,
            default: 'auto',
        },

        height: {
            type: String,
            default: 'auto',
        },

        creationDisabled: {
            type: Function,
            default: () => false,
        },
    },

    data () {
        return {
            defaultProps: {
                name: 'name',
                value: 'value',
                disabled: 'disabled',
                icon: 'icon',
                disabledIcon: 'disabledIcon',
                color: 'color',
                minWidth: 'minWidth',
                sort: 'sort',
                noIcon: 'noIcon',
                rules: 'rules'
            },
            sortable: null,
            dragInfo: {
                startIndex: undefined
            }
        };
    },

    computed: {
        itemProps () {
            return Object.assign({}, this.defaultProps, this.props);
        },
    },

    mounted () {
        const wrapper = this.$refs.itemsWrapper;
        this.sortable = new Sortable(wrapper, {
            animation: 300,
            handle: '.stage-list-item__header',
            filter: '.--disabled',
            onStart: ({ oldIndex }) => {
                this.dragInfo.startIndex = oldIndex;
            },
            onMove: ({ related, to }) => {
                const newIndex = [...to.children].indexOf(related);
                const { prevItem, nextItem } = this.getSiblings(newIndex);

                return Boolean((nextItem && !nextItem[this.itemProps.disabled]) || (prevItem && !prevItem[this.itemProps.disabled]));
            },
            onEnd: ({ newIndex }) => {
                const { nextItem, prevItem } = this.getSiblings(newIndex);
                this.$emit('drag-end', {
                    nextItem,
                    prevItem,
                    dragged: this.data[this.dragInfo.startIndex]
                });
            }
        });
    },

    beforeDestroy() {
        if (this.sortable) {
            this.sortable.destroy();
        }
    },

    methods: {
        getSiblings (newIndex) {
            const isSameIndexOrAfter = newIndex >= this.dragInfo.startIndex;
            let nextItemIndex = newIndex;
            if (isSameIndexOrAfter) {
                nextItemIndex++;
            }
            const prevItemIndex = nextItemIndex - (newIndex === this.dragInfo.startIndex ? 2 : 1);
            const nextItem = this.data[nextItemIndex];
            const prevItem = this.data[prevItemIndex];

            return { nextItem, prevItem };
        },

        handleAddStage (index) {
            const item = this.data[index];
            this.$emit('add', item);
        },

        handleItemEdit (value) {
            this.$emit('edit', this.data.find(item => item[this.itemProps.value] === value));
        },
    },
};
</script>

<style lang="scss">
.stage-list {
    $b: &;
    display: flex;
    align-items: flex-start;
    overflow: auto;

    &__main {
        flex-grow: 1;
        min-height: 64px;
        display: flex;
    }

    &__item-wrapper {
        display: flex;
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }

        &:not(.--disabled) .stage-list-item__header {
            cursor: grab;
        }
    }

    &__prepend, &__append {
        padding: 6px;
        border: 1px solid $--disabled-color-base;
        color: $--disabled-color-base;
        white-space: nowrap;
        position: sticky;
        top: 0;
        z-index: 1;
        font-size: 12px;
    }

    &__prepend {
        border-left-width: 2px;
        border-right: none;
        border-top-left-radius: $--card-border-radius;
        border-bottom-left-radius: $--card-border-radius;
    }

    &__append {
        border-right-width: 2px;
        border-left: none;
        border-top-right-radius: $--card-border-radius;
        border-bottom-right-radius: $--card-border-radius;
    }

    &__button {
        padding: 3px !important;
        opacity: 0;
        margin: 0 -14px 0 -6px;
        align-self: flex-start;
        z-index: 2;
        position: sticky;
        top: 6px;

        &:hover {
            opacity: 1;
        }
    }
}
</style>
