import axios, { type CustomParamsSerializer } from 'axios';
import { parse, stringify } from 'qs';
import * as Cookies from 'js-cookie';
import store from '@/store';

const service = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    timeout: 30000,
    paramsSerializer: {
        encode: parse,
        serialize: (params) => stringify(params, { arrayFormat: 'brackets' }),
    } as CustomParamsSerializer,
});

const requestsMap = new Map();

service.interceptors.request.use(
    config => {
        if (store.getters['auth/token']) {
            config.headers.Authorization = `Bearer ${store.getters['auth/token']}`;
        }
        if (Cookies.get('XDEBUG_SESSION')) {
            config.params = config.params || {};
            config.params.XDEBUG_SESSION_START = Cookies.get('XDEBUG_SESSION');
        } else if (Cookies.get('XDEBUG_PROFILE')) {
            config.params = config.params || {};
            config.params.XDEBUG_PROFILE = Cookies.get('XDEBUG_PROFILE');
        }
        // Api Platform не позволяет передавать UUID при создании
        if (config.method?.toLowerCase() === 'post' && config.data && config.data.id) {
            config.headers['Content-Type'] = 'application/ld+json';
        }

        const controller = new AbortController();
        config.signal = controller.signal;
        requestsMap.set(config, controller);

        return config;
    },
    error => {
        console.error(error);
        return Promise.reject(error);
    },
);

service.interceptors.response.use(
    ({ data, config }) => {
        requestsMap.delete(config);
        return data;
    },
    async (error) => {
        requestsMap.delete(error.config);
        const isUnauthorized = error.response?.status === 401;

        if (isUnauthorized) {
            if (error.response?.data?.message === 'JWT Refresh Token Not Found') {
                return Promise.reject(error);
            }

            if (
                !(error.config?.url ?? '').startsWith('/auth') &&
                await store.dispatch('auth/refreshToken')
            ) {
                return service.request(error.config);
            }
        }

        return Promise.reject(error);
    },
);

export { requestsMap };
export default service;
