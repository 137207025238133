<template>
    <div class="fraction-input">
        <ui-masked-input
            v-model="numerator"
            :mask="Number"
            :scale="0"
            :signed="false"
            thousands-separator=" "
            unmask="typed"
            pad-fractional-zeros
            @keydown="handleNumeratorKeyDown"
        />
        <div class="fraction-input__fraction">
            /
        </div>
        <ui-masked-input
            ref="denominator"
            v-model="denominator"
            :mask="Number"
            :scale="0"
            :signed="false"
            thousands-separator=" "
            unmask="typed"
            pad-fractional-zeros
        />
    </div>
</template>

<script>
export default {
    name: 'UiFractionInput',
    props: {
        value: {
            type: Array,
            required: true,
        },
    },

    computed: {
        numerator: {
            get () {
                return this.value[0];
            },

            set (value) {
                this.$emit('input', [value, this.value[1]]);
            },
        },

        denominator: {
            get () {
                return this.value[1];
            },

            set (value) {
                this.$emit('input', [this.value[0], value]);
            },
        },
    },

    methods: {
        handleNumeratorKeyDown (e) {
            if (e.key === '/') {
                this.$refs?.denominator?.$refs?.input?.$el?.focus();
            }
        },
    },
};
</script>

<style lang="scss">
.fraction-input {
    display: flex;
    align-items: center;

    &__fraction {
        padding: 0 8px;
    }
}
</style>
