<template>
    <div
        :class="{ 'is-active-label': labelActive }"
        class="table-filter"
    >
        <div class="table-filter__slot">
            <div
                v-if="label"
                class="table-filter__label"
            >
                <iconify-icon
                    slot="reference"
                    icon="fa6-solid:filter"
                    class="table-filter__icon"
                />
                {{ label }}
            </div>
            <div
                v-show="!focus && controlValue"
                class="table-filter__tag-wrapper"
                @click="handleTagsClick"
            >
                <el-tag
                    closable
                    size="small"
                    disable-transitions
                    @close="handleTagClose"
                >
                    {{ controlValue }}
                </el-tag>
            </div>
            <el-input
                v-show="focus || !controlValue"
                ref="input"
                v-model="controlValue"
                v-bind="proxyProps"
                class="table-filter__control"
                @change="handleChange"
                @clear="handleClear"
                @focus="focus=true"
                @blur="focus=false"
            />
        </div>
    </div>
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';

export default {
    name: 'UiTableFilterInput',

    mixins: [WrapperMixin],

    props: {
        value: {
            type: String,
            default: '',
        },

        label: {
            type: String,
            default: '',
        },

        width: {
            type: Number,
            default: 300,
        },
    },

    data () {
        return {
            controlValue: this.value,
            focus: false,
        };
    },

    computed: {
        labelActive () {
            if (this.controlValue) {
                return true;
            }

            return this.focus;
        },
    },

    watch: {
        value () {
            this.controlValue = this.value;
            this.$refs.input.blur();
        },
    },

    methods: {
        apply () {
            this.$emit('input', this.controlValue);
        },

        handleChange () {
            this.apply();
        },

        handleClear () {
            this.apply();
        },

        handleTagsClick () {
            this.focus = true;
            this.$nextTick(() => {
                this.$refs.input.focus();
            });
        },

        handleTagClose () {
            this.controlValue = '';
            this.apply();
        },
    },
};
</script>
