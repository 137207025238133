import request from '@/utils/request';

const baseUri = '/integration/engines';

export default {
    find (id, params) {
        return request.get(`${baseUri}/${id}`, { params });
    },

    findByCode (code, params) {
        return request.get(`${baseUri}_by_code/${code}`, { params });
    },

    findAll (params) {
        return request.get(baseUri, { params });
    },

    setConfig (engineId, businessId, config) {
        return request.post(`${baseUri}/${engineId}/config`, {
            businessId,
            config,
        });
    },

    deleteConfig (engineId, businessId) {
        return request.delete(`${baseUri}/${engineId}/config`, {
            params: {
                businessId,
            },
        });
    },

    findDadata (business) {
        return request.get(`${baseUri}/da_data_api_key`, {
            params: {
                business,
            },
        });
    },
};
