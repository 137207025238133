import request from '@/utils/request';

const baseUri = '/search';

export const getContractorsWithRequisites = (params) =>
    request({
        url: `${baseUri}/contractor_with_requisites`,
        method: 'GET',
        params,
    });
