import * as LoanAPI from '@/api/contractor/loan';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return LoanAPI.getLoans(params);
    },
    doFetchSingle (id, params) {
        return LoanAPI.getLoan(id, params);
    },
    doCreate (data) {
        return LoanAPI.createLoan(data);
    },
    doUpdate (id, data) {
        return LoanAPI.updateLoan(id, data);
    },
    doDelete (id) {
        return LoanAPI.deleteLoan(id);
    },
});
