<template>
    <ui-masked-input
        v-model="currentValue"
        mask="0000 0000 0000 0000"
        :clearable="clearable"
    >
        <template #prepend>
            <ui-svg-icon
                v-if="!iconName.includes(':')"
                :icon="iconName"
            />
            <iconify-icon
                v-else
                :icon="iconName"
            />
        </template>
    </ui-masked-input>
</template>

<script>
import CreditCardMixin from '@/mixins/credit-card';

export default {
    name: 'UiCreditCardInput',

    mixins: [CreditCardMixin],

    props: {
        value: {
            type: String,
            required: true,
        },

        clearable: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (value) {
                this.$emit('input', value);
            },
        },

        iconName () {
            return this.getCreditCardIconName(this.currentValue);
        },
    },
};
</script>

<style scoped>
.el-input-group >>> .el-input-group__prepend {
    font-size: 32px;
    line-height: 1;
    padding: 0 8px;
}

.el-input-group .svg-icon {
    display: block;
}
</style>
