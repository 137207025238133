import { CanceledError } from 'axios';
import { Message as ElMessage } from 'element-ui';
import { getMessageFromError } from '@/utils';
import { MessageType } from 'element-ui/types/message';

export function useFlash () {
    const flash = (type: MessageType, message: string, duration = 5, isHtml = false) => {
        ElMessage({
            showClose: true,
            message,
            type,
            duration: duration * 1000,
            dangerouslyUseHTMLString: isHtml,
        });
    };

    const flashSuccess = (message: string, durationInSeconds = 5, isHtml = false) =>
        flash('success', message, durationInSeconds, isHtml);

    const flashError = (error: string | Error, durationIsSeconds = 5, isHtml = true) => {
        if (error instanceof CanceledError) {
            return;
        }

        flash('error', getMessageFromError(error) || 'Ошибка', durationIsSeconds, isHtml);
    };

    const flashWarning = (message: string, durationInSeconds = 5, isHtml = false) =>
        flash('warning', message, durationInSeconds, isHtml);

    return {
        flashSuccess,
        flashError,
        flashWarning,
    };
}
