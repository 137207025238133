import { getContract, createContract, deleteContract, getContracts, updateContract } from '@/api/contractInternal';
import { createCrudModule } from '@/utils/vuex-crud';

const module = createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return getContracts(params);
    },
    doFetchSingle (id: string) {
        return getContract(id);
    },
    doCreate (data: object) {
        return createContract(data);
    },
    doUpdate (id: string, data: object) {
        return updateContract(id, data);
    },
    doDelete (id: string) {
        return deleteContract(id);
    }
});

export default module;
