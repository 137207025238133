<template>
    <component
        :is="props.tag"
        class="card"
        :class="{ 'is-clickable': props.clickable }"
        @click="handleClick"
    >
        <div
            v-if="$slots.title || $slots.actions"
            class="card__header"
            :class="{ 'u-p-none': props.noHeaderPaddings }"
        >
            <div
                v-if="$slots.title"
                class="card__title"
            >
                <slot name="title" />
            </div>
            <div
                v-if="$slots.actions"
                class="card__actions"
            >
                <slot name="actions" />
            </div>
        </div>
        <div
            v-if="$slots.default"
            class="card__body"
            :class="{ 'u-p-none': props.noBodyPaddings }"
        >
            <slot />
        </div>
    </component>
</template>

<script lang="ts">
export default {
    name: 'UiCard',
};
</script>

<script setup lang="ts">
interface Props {
    clickable?: boolean
    noHeaderPaddings?: boolean,
    noBodyPaddings?: boolean,
    tag?: string
}

interface Emits {
    (e: 'click'): void;
}

const props = withDefaults(defineProps<Props>(), {
    clickable: false,
    noHeaderPaddings: false,
    noBodyPaddings: false,
    tag: 'div',
});

const emit = defineEmits<Emits>();

const handleClick = () => {
    if (!props.clickable) {
        return;
    }

    emit('click');
};
</script>
