import type { PagedCollection } from '@/types/hydra';
import type { ContractorType } from '@/types/ContractorType';
import request from '@/utils/request';

const baseUri = '/contractor_types';

export const getTypes = (params?: object): Promise<PagedCollection<ContractorType>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getType = (id: string, params?: object): Promise<ContractorType> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createType = (data: object, params?: object): Promise<ContractorType> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
        params,
    });

export const updateType = (id: string, data: object, params?: object): Promise<ContractorType> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
        params,
    });

export const deleteType = (id: string, data?: object, params?: object): Promise<null> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
        data,
        params,
    });
