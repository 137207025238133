<template>
    <el-table-column
        v-if="showColumn(propColumn)"
        ref="column"
        v-bind="proxyProps"
        v-on="proxyListeners"
    >
        <template
            v-for="(_, scopedSlotName) in $scopedSlots"
            #[scopedSlotName]="slotData"
        >
            <slot
                :name="scopedSlotName"
                v-bind="slotData"
            />
        </template>

        <template
            v-for="(_, slotName) in $slots"
            #[slotName]
        >
            <slot :name="slotName" />
        </template>
    </el-table-column>
</template>

<script lang="ts">
import Vue from 'vue';
import WrapperMixin from '@/mixins/wrapper';

export default Vue.extend({
    name: 'UiDataTableColumn',
    mixins: [WrapperMixin],

    computed: {
        tableSettings () {
            return this.$store.getters['tableSettings/list'].find(i => i.contextId === this.$route.name)?.settings;
        },

        propColumn (): string {
            return this.proxyProps.prop;
        },
    },

    methods: {
        showColumn (prop: string) {
            if (this.tableSettings) {
                return this.tableSettings?.columns.includes(prop);
            }

            if (this.proxyProps['default-visible']) {
                return false;
            }
            return true;
        },
    },
});
</script>
