import request from '@/utils/request';

const baseUri = '/contract_numbering_rules';

export const getContractNumberingRules = (params: object) =>
    request({
        url: baseUri,
        method: 'GET',
        params
    });

export const getContractNumberingRule = (id: string) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET'
    });

export const createContractNumberingRule = (data: object) =>
    request({
        url: baseUri,
        method: 'POST',
        data
    });

export const deleteContractNumberingRule = (id: string) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE'
    });

export const updateContractNumberingRule = (id: string, data: object) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data
    });
