import * as Product from '@/api/possessions/product/productCategory';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params?: object) {
        return Product.getProductCategories(params);
    },
    doFetchSingle (id: string, params?: object) {
        return Product.getProductCategory(id, params);
    },
    doCreate (data: object, params?: object) {
        return Product.createProductCategory(data, params);
    },
    doUpdate (id: string, data: object, params?: object) {
        return Product.editProductCategory(id, data, params);
    },
    doDelete (id: string, params?: object) {
        return Product.deleteProductCategory(id);
    },
});
