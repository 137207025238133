import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { TbankIntegration, TbankProfile } from '@/types/Tbank';

const baseUri = '/tbank_integrations';

export const getTbankIntegrations = (params: object): Promise<PagedCollection<TbankIntegration>> =>
    request({
        url: baseUri,
        method: 'GET',
        params
    });

export const createTbankProfile = (data: object): Promise<TbankProfile> =>
    request({
        url: `${baseUri}/profiles`,
        method: 'POST',
        data
    });

export const deleteTbankProfile = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/profiles/${id}`,
        method: 'DELETE'
    });

export const updateTbankProfile = (id: string, data: object): Promise<TbankProfile> =>
    request({
        url: `${baseUri}/profiles/${id}`,
        method: 'PATCH',
        data
    });

export const refreshTbankProfile = (id: string) =>
    request({
        url: `${baseUri}/profiles/${id}/refresh`,
        method: 'POST'
    });
