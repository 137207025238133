import { createCrudModule } from '@/utils/vuex-crud';
import { createDocument, deleteDocument, getDocument, getDocuments, updateDocument } from '@/api/staff/paymentDocument';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return getDocuments(params);
    },
    doFetchSingle (id, params) {
        return getDocument(id, params);
    },
    doCreate (data, params) {
        return createDocument(data, params);
    },
    doUpdate (id, data, params) {
        return updateDocument(id, data, params);
    },
    doDelete (id) {
        return deleteDocument(id);
    },
});
