<template>
    <el-dropdown-item
        :command="action"
        :divided="type === 'delete'"
        class="actions-menu__item"
        :class="{ [`actions-menu__item--${type}`]: type }"
    >
        <slot />
    </el-dropdown-item>
</template>

<script>
export default {
    name: 'UiActionsMenuItem',

    props: {
        action: {
            type: String,
            default: '',
        },

        type: {
            type: String,
            default: 'default',
        },
    },
};
</script>
