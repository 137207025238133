<template>
    <div>
        <div class="contract-version-row-table">
            <span
                v-if="!inTimeline"
                class="contract-version-row-table__info-secondary"
            >
                №{{ data.number }}
            </span>
            <div class="contract-version-row-table__info">
                <div class="contract-version-row-table__file-block">
                    <div
                        v-for="file in data.files"
                        :key="file.id"
                    >
                        <div @click="handleRowClick(file)">
                            <iconify-icon
                                :icon="getIconName(file)"
                                class="icon u-text-xl"
                            />
                            <span :class="{ 'line-through': data.archived }">
                                {{ file.originalName }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="contract-version-row-table__details">
                    <div class="contract-version-row-table__author-comment">
                        <span
                            v-if="!inTimeline"
                            class="contract-version-row-table__info-secondary"
                        >{{ data.author?.fullName || '' }}</span>
                        <el-tooltip v-if="data.comment">
                            <iconify-icon icon="fa6-solid:comment-dots" />
                            <template #content>
                                <div class="contract-version-row-table__tooltip-content">
                                    <div
                                        v-for="(part, index) in parts"
                                        :key="index"
                                    >
                                        <div>{{ part }}</div>
                                        <br v-if="index < parts.length - 1">
                                        <br v-if="index < parts.length - 1">
                                    </div>
                                </div>
                            </template>
                        </el-tooltip>
                    </div>
                    <span
                        v-if="!inTimeline"
                        class="contract-version-row-table__info-secondary"
                    >
                        {{ $dayjs(data?.createdAt).format('DD.MM.YYYY HH:mm') }}
                    </span>
                </div>
            </div>
        </div>
        <ui-doc-viewer
            v-if="fileData"
            :visible.sync="viewerVisible"
            :file="fileData"
        />
    </div>
</template>

<script lang="ts">
import type { MediaObject } from '@/types/MediaObject';
import Vue from 'vue';
import { MIMETypes } from '@/enums';
import { ContractVersion } from '@/types/Contract';

export default Vue.extend({
    props: {
        data: {
            type: Object as () => ContractVersion,
            required: true,
        },

        inTimeline: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            viewerVisible: false,
            fileData: null as MediaObject | null,
        };
    },

    computed: {
        parts () {
            return this.data?.comment?.split('|').map(part => part.trim()) ?? [];
        }
    },

    methods: {
        getIconName (file) {
            const icons = {
                [MIMETypes.DOC]: 'vscode-icons:file-type-word',
                [MIMETypes.DOCX]: 'vscode-icons:file-type-word2',
                [MIMETypes.PDF]: 'vscode-icons:file-type-pdf2',
                [MIMETypes.XLS]: 'vscode-icons:file-type-excel',
                [MIMETypes.XLSX]: 'vscode-icons:file-type-excel2',
            };
            return icons[file.mimeType] || 'vscode-icons:default-file';
        },

        handleRowClick (file: MediaObject) {
            this.fileData = file;
            this.viewerVisible = true;
        },
    },
});
</script>

<style lang="scss" scoped>
.contract-version-row-table {
    &__info-secondary {
        color: $--color-text-secondary;
    }

    &__file-block {
        display: flex;
        flex-direction: column;
    }

    .line-through {
        text-decoration: line-through;
    }

    &__info {
        display: flex;
        justify-content: space-between;
    }

    &__details {
        display: flex;
        flex-direction: column;
        gap: 5px;
        min-width: 150px;
        align-items: flex-end;
    }

    &__author-comment {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .icon {
        margin-right: 5px;
    }

    &__tooltip-content {
        max-width: 250px;
    }
}
</style>
