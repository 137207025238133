<template>
    <transition-group
        name="transition"
        tag="div"
        :duration="transitionDuration"
        class="route-drawer"
        :class="{ 'is-visible': visible }"
        :style="{ 'z-index': visible ? zIndex: -1 }"
    >
        <template v-for="(item, index) in items">
            <div
                :key="`overlay_${index}`"
                class="route-drawer__overlay"
                @click="handleItemClose"
            />
            <ui-route-drawer-item
                :key="`item_${index}`"
                :route="item.route"
                :index="index"
                :modal="Boolean(item.options.modal ?? item.route.meta?.drawerOptions?.modal)"
                :width="item.options.width ?? item.route.meta?.drawerOptions?.width ?? ''"
                @close="handleItemClose"
            />
        </template>
    </transition-group>
</template>

<script>
import { PopupManager } from 'element-ui/lib/utils/popup';

export default {
    name: 'UiRouteDrawer',

    data () {
        return {
            visible: false,
            visibleTimeout: null,
            transitionDuration: 300,
            zIndex: PopupManager.nextZIndex(),
        };
    },

    computed: {
        items () {
            return this.$store.getters['ui/routeDrawer/items'];
        },
    },

    watch: {
        items (value) {
            if (value.length > 0) {
                this.visible = true;
                if (this.visibleTimeout) {
                    clearTimeout(this.visibleTimeout);
                }
            } else {
                this.visibleTimeout = setTimeout(() => {
                    this.visible = false;
                }, this.transitionDuration);
            }
        },

        visible (value) {
            if (value) {
                this.zIndex = PopupManager.nextZIndex();
            }
        },
    },

    created () {
        window.addEventListener('keyup', this.keyupListener);
    },

    mounted () {
        document.body.appendChild(this.$el);
    },

    beforeDestroy () {
        window.removeEventListener('keyup', this.keyupListener);
    },

    methods: {
        keyupListener (event) {
            if (event.keyCode === 27) {
                this.$drawer.pop();
            }
        },

        handleItemClose () {
            this.$drawer.pop();
        },
    },
};
</script>

<style lang="scss">
.route-drawer {
    &.is-visible {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.route-drawer__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .3;
    background: #000;
    transition: opacity ease .3s;

    &.transition-enter,
    &.transition-leave-to {
        opacity: 0;
    }

    &.transition-enter-to {
        opacity: .3;
    }
}
</style>
