import request from '@/utils/request';
import { FundingClaimPaymentStageRule, FundingClaimPaymentStageRuleLogic } from '@/types/FundingClaimPaymentStageRule';

const baseUri = 'funding_claim_payment_stage_rules';

export const getStageRule = (id: string, params?: object): Promise<FundingClaimPaymentStageRule> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const getStageRuleLogic = (params?: object): Promise<FundingClaimPaymentStageRuleLogic> =>
    request({
        url: `${baseUri}/logic`,
        method: 'GET',
        params,
    });

export const createStageRule = (data?: object): Promise<FundingClaimPaymentStageRule> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });

export const updateStageRule = (id: string, data?: object): Promise<FundingClaimPaymentStageRule> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });

export const deleteStageRule = (id: string): Promise<null> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });
