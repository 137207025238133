<template>
    <ui-table-filter-select
        v-model="currentValue"
        v-bind="proxyProps"
        :fetch-items="fetchItems"
        :init-method="fetchInitItems"
        :multiple="multiple"
        label-key="fullName"
        :fetch-on-create="multiple ? currentValue.length > 0 : currentValue"
        v-on="$listeners"
    >
        <template #option="{ item }">
            {{ item.name.fullName }}
        </template>
    </ui-table-filter-select>
</template>
<script>
import { pickBy } from 'lodash';
import EmployeeAPI from '@/api/staff/employee';
import WrapperMixin from '@/mixins/wrapper';

export default {
    name: 'UiTableFilterEmployee',

    mixins: [WrapperMixin],

    props: {
        value: {
            type: [String, Array],
            default: '',
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        fetchParams: {
            type: Object,
            default: null,
        },
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (value) {
                this.$emit('input', value);
            },
        },

        business () {
            return this.$store.getters['business/current'];
        },
    },

    methods: {
        async fetchItems (query, cb) {
            const params = {
                ...this.fetchParams,
                business: this.business?.id,
                order: {
                    'name.lastName': 'asc',
                    'name.firstName': 'asc',
                },
            };

            if (query) {
                params.fullName = query;
            }

            try {
                const data = await EmployeeAPI.findAll(pickBy(params));
                const items = data['hydra:member'] || [];

                cb(items);
            } catch (e) {
                this.$flashError(e);
            }
        },

        async fetchInitItems (items) {
            const params = {
                business: this.business?.id,
                id: items,
            };

            try {
                const data = await EmployeeAPI.findAll(pickBy(params));
                return data['hydra:member'] || [];
            } catch (e) {
                this.$flashError(e);
                return [];
            }
        },
    },
};
</script>
