<template>
    <el-tooltip
        v-model="tooltipVisible"
        :content="successMessage"
        placement="top"
        manual
        title="Скопировать"
    >
        <el-button
            type="text"
            circle
            class="u-p-xxs"
            @click="handleCopyLink"
        >
            <iconify-icon icon="akar-icons:copy" />
        </el-button>
    </el-tooltip>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'UiButtonCopyToClipboard',

    props: {
        valueToCopy: {
            type: String,
            default: ''
        },

        successMessage: {
            type: String,
            default: 'Скопировано!'
        },

        timeout: {
            type: Number,
            default: 1000
        }
    },

    data () {
        return {
            tooltipVisible: false
        };
    },

    methods: {
        handleCopyLink () {
            navigator.clipboard.writeText(this.valueToCopy);
            this.tooltipVisible = true;

            setTimeout(() => {
                this.tooltipVisible = false;
            }, this.timeout);
        }
    }
});
</script>
