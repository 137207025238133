<template>
    <div
        class="list-item"
        :style="{ alignItems: verticalAlign }"
    >
        <div
            class="list-item__label"
            :style="`width: ${currentLabelWidth}`"
        >
            <div class="list-item__label-text">
                <slot
                    v-if="$slots.label"
                    name="label"
                />
                <template v-else>
                    {{ label }}
                </template>
            </div>
        </div>
        <div class="list-item__content">
            <div :class="valueClasses">
                <span
                    v-if="dashForEmpty && !hasValue"
                    class="u-color-secondary"
                >
                    –
                </span>
                <slot v-else>
                    {{ value }}
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UiListItem',
    inject: ['uiList'],
    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        labelWidth: {
            type: String,
            default: undefined
        },
        valueAlign: {
            type: String,
            default: 'left'
        },
        verticalAlign: {
            type: String,
            default: 'center'
        }
    },
    computed: {
        hasValue() {
            return (this.value ?? '').trim() !== '' || this.$slots.default?.[0].text.trim().length > 0;
        },
        valueClasses () {
            const classes = ['list-item__value'];

            if (this.valueAlign === 'right') {
                classes.push('u-text-right');
            }

            return classes.join(' ');
        },
        currentLabelWidth () {
            return this.labelWidth ?? this.uiList?.labelWidth;
        },
        dashForEmpty () {
            return this.uiList?.dashForEmpty ?? false;
        }
    }
};
</script>
