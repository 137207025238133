import { createClaim, deleteClaim, getClaim, getClaims, updateClaim } from '@/api/planning/fundingClaim';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return getClaims(params);
    },
    doFetchSingle (id) {
        return getClaim(id);
    },
    doCreate (data) {
        return createClaim(data);
    },
    doUpdate (id, data) {
        return updateClaim(id, data);
    },
    doDelete (id) {
        return deleteClaim(id);
    },
});
