<template>
    <el-dialog
        ref="dialog"
        v-bind="proxyProps"
        v-on="$listeners"
        @mousedown.native="handleDialogMousedown"
    >
        <slot />
        <template #title>
            <slot name="title" />
        </template>
        <template #footer>
            <slot name="footer" />
        </template>
    </el-dialog>
</template>

<script>
import { getEventPath } from '@/utils';
import WrapperMixin from '@/mixins/wrapper';

export default {
    name: 'UiDialog',

    mixins: [WrapperMixin],

    props: {
        beforeClose: {
            type: Function,
            default: undefined
        }
    },

    methods: {
        getDefaultProps () {
            return {
                appendToBody: true,
                destroyOnClose: true,
                closeOnClickModal: false,
            };
        },

        handleDialogMousedown (event) {
            const path = getEventPath(event);
            if (
                this.$refs.dialog &&
                !path.some(el => el.classList && el.classList.contains('el-dialog'))
            ) {
                if (typeof this.beforeClose === 'function') {
                    this.beforeClose(this.$refs.dialog.hide);
                } else {
                    this.$refs.dialog.hide();
                }
            }
        },
    },
};
</script>
