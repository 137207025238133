import * as AccessRoleAPI from '@/api/accessRole';
import DepartmentAPI from '@/api/staff/department';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList ({ departmentId, ...params }) {
        if (departmentId) {
            return DepartmentAPI.getAccessRoles(departmentId, params);
        } else {
            return AccessRoleAPI.getAccessRoles(params);
        }
    },
    doFetchSingle (id, { departmentId, ...params }) {
        if (departmentId) {
            return DepartmentAPI.getAccessRole(departmentId, id);
        } else {
            return AccessRoleAPI.getAccessRole(id);
        }
    },
    doCreate (data) {
        return AccessRoleAPI.createAccessRole(data);
    },
    doDelete (id) {
        return AccessRoleAPI.deleteAccessRole(id);
    },
    doUpdate (id, data) {
        return AccessRoleAPI.updateAccessRole(id, data);
    },
});
