<template>
    <el-popover
        class="alert-with-icon"
        trigger="hover"
    >
        <div
            class="alert-with-icon__content"
            :style="{ 'max-width': `${width}px` }"
        >
            <slot />
        </div>

        <span
            slot="reference"
            class="u-ml-xs u-mr-xs"
        >
            <iconify-icon
                :icon="icon"
                class="u-color-secondary"
            />
        </span>
    </el-popover>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'UiAlertWithIcon'
});
</script>

<script setup lang="ts">
interface Props {
    icon: string,
    width?: string
}

defineProps<Props>();
</script>

<style lang="scss">
.alert-with-icon {
    &__content {
        word-break: normal;
        text-align: left;

        a.el-link:after {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 0;
            content: "";
            border-bottom: 1px solid currentColor;
        }
    }
}
</style>
