export default {
    props: {
        currentLabel: {
            type: String,
            default: '',
        },

        sortByField: {
            type: String,
            default: 'name',
        },

        beforeItems: {
            type: Array,
            default: () => [],
        },

        setOptionStyle: {
            type: Function,
            default: () => ({}),
        },
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (value) {
                this.$emit('input', value);
            },
        },
    },

    methods: {
        setStyle (item) {
            return {
                'padding-left': `${(item.level + 1) * 20}px`,
                ...this.setOptionStyle(item),
            };
        },

        isParent (item, items, searchQuery) {
            const searchItems = items.filter(i => i.name.toLowerCase().includes(searchQuery.toLowerCase()));
            const parents = [];

            const searchParent = (item) => {
                if (item?.parent) {
                    const parent = items.find(i => i.id === item.parent?.id);
                    parents.push(parent);
                    searchParent(parent);
                } else {
                    return false;
                }
            };

            searchItems.forEach(i => {
                searchParent(i);
            });

            return parents.find(i => item.id === i.id);
        },
    },
};
