import { TimelineChangeTypes } from '@/enums';
import { isEqual } from 'lodash';
import classNames from 'classnames';
import Vue from 'vue';

export default Vue.extend({
    props: {
        entityType: {
            type: String,
            required: true,
        },

        changeset: {
            type: Object,
            required: true,
        },

        fieldsMeta: {
            type: Object,
            required: true,
        },
    },

    computed: {
        displayField (): string {
            const { field } = this.changeset;
            const meta = this.getFieldMeta(field);

            return (meta && meta.title) ?? '';
        },
    },

    methods: {
        getFieldMeta (field: string) {
            return this.fieldsMeta[this.entityType]?.[field] ?? null;
        },

        getAccountingCategoryName (row: any) {
            return row.transactionCategory?.name || row.category?.name || '';
        },

        getChangeDescription (change: any) {
            if (change.changeType === TimelineChangeTypes.COLLECTION_RECORD_ADD) {
                return this.displayCollectionAction('add');
            }

            if (change.changeType === TimelineChangeTypes.COLLECTION_RECORD_DELETE) {
                return this.displayCollectionAction('delete');
            }

            if (change.changeType === TimelineChangeTypes.COLLECTION_RECORD_EDIT) {
                return this.displayCollectionAction('edit');
            }
        },

        displayCollectionAction (action: string) {
            const { field } = this.changeset;
            const meta = this.getFieldMeta(field);

            return meta?.[`${action}ActionTitle`] ?? action;
        },

        getColspan (row: any) {
            if (row.isAction) {
                return 99;
            }

            return 1;
        },

        isFieldChanged (row: any, compareRow: any, field: string) {
            return !isEqual(row[field], compareRow?.[field]);
        },

        cellClassName (row: any, colProp: string) {
            return classNames({
                '-value-changed': 'compareValue' in row &&
                    this.isFieldChanged(row, row.compareValue, colProp),
                '-is-before': row.isBefore,
                '-is-action': row.isAction,
                '-is-secondary': row.isSecondary,
            });
        },
    },
});
