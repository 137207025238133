import { createSeparationLeave, getSeparationLeave, updateSeparationLeave } from '@/api/staff/personnelDocument';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchSingle (id) {
        return getSeparationLeave(id);
    },
    doCreate (data) {
        return createSeparationLeave(data);
    },
    doUpdate (id, data) {
        return updateSeparationLeave(id, data);
    },
});
