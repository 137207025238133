<template>
    <ui-table-filter-select
        v-model="currentValue"
        v-bind="proxyProps"
        :fetch-items="fetchReorderedItems"
        :init-method="fetchInitActivities"
        :set-style="itemStyleMethod"
        :disabled-item-method="disabledSubDirection"
        multiple
        v-on="$listeners"
    />
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';
import SelectNestedMixin from '@/mixins/select-nested';
import { getBusinessActivities } from '@/api/businessActivity';
import { uniqBy } from 'lodash';
import { reorderNested } from '@/utils';

export default {
    name: 'UiTableFilterActivitySelect',

    mixins: [WrapperMixin, SelectNestedMixin],

    props: {
        multiple: {
            type: Boolean,
            default: true,
        },

        fetchOnCreate: {
            type: Boolean,
            default: false,
        },

        value: {
            type: [String, Array],
            default: '',
        },

        nested: {
            type: Boolean,
            default: true,
        },

        fetchParams: {
            type: Object,
            default: null,
        },
    },

    computed: {
        businessId () {
            return this.$store.getters['business/currentId'];
        },
    },

    methods: {
        reorderData (items) {
            return [...this.beforeItems, ...reorderNested(items, this.sortByField)];
        },

        fetchReorderedItems(query, cb) {
            this.fetchActivities(query, items => {
                const data = items.flatMap(item => {
                    const parentItem = item?.parent;
                    const level = parentItem ? 1 : 0;
                    return parentItem ? [parentItem, { ...item, level }] : [{ ...item, level }];
                });

                this.items = uniqBy(data, 'id');
                cb(this.reorderData(this.items));
            });
        },

        async fetchActivities (query, cb) {
            const params = {
                business: this.businessId,
            };

            if (query !== '') {
                params.name = query;
            }

            const data = await getBusinessActivities(params);
            const items = data['hydra:member'] || [];
            cb(items);
        },

        async fetchInitActivities () {
            const params = {
                business: this.businessId,
            };

            try {
                const data = await getBusinessActivities(params);
                const items = data['hydra:member'];
                return items || [];
            } catch (e) {
                this.$flashError(e);
            }
        },

        itemStyleMethod (item) {
            return {
                paddingLeft: `${(item.level + 1) * 20}px`,
            };
        },

        disabledSubDirection (item) {
            return !item.parent && item.hasChildren;
        },
    },
};
</script>
