import { changeTableSettings, getTableSetting, getTableSettings } from '@/api/tableSettings';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    identifier: 'contextId',
    doFetchList (params?: object) {
        return getTableSettings(params);
    },
    doUpdate (contextId: string, data: object) {
        return changeTableSettings(contextId, data);
    },
    doFetchSingle (id: string) {
        return getTableSetting(id);
    },
});
