<template>
    <div
        v-if="total > pageSize"
        class="pagination"
    >
        <el-pagination
            :total="total"
            :page-size="pageSize"
            v-bind="proxyProps"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';
import { DEFAULT_PAGE_SIZE } from '@/config';

export default {
    name: 'UiPagination',

    mixins: [WrapperMixin],

    props: {
        total: {
            type: Number,
            required: true,
        },

        pageSize: {
            type: Number,
            default: DEFAULT_PAGE_SIZE,
        },
    },

    methods: {
        getDefaultProps () {
            return {
                layout: 'prev, pager, next',
                hideOnSinglePage: true,
            };
        },
    },
};
</script>
