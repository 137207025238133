import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { ContractVersion } from '@/types/Contract';

const baseUri = '/amendment_versions';

export const getContractAmendmentVersions = (params: object): Promise<PagedCollection<ContractVersion>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getContractAmendmentVersion = (id: string): Promise<ContractVersion> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    });

export const createContractAmendmentVersion = (data: any): Promise<ContractVersion> => {
    const formData = new FormData();

    Object.keys(data).forEach(key => {
        if (Array.isArray(data[key])) {
            data[key].forEach((item: any) => formData.append(`${key}[]`, item));
        } else {
            formData.append(key, data[key]);
        }
    });

    return request({
        url: baseUri,
        method: 'POST',
        data: formData,
    });
};
