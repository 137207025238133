import * as Product from '@/api/possessions/product/productLocation';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params?: object) {
        return Product.getProductLocations(params);
    },
    doFetchSingle (id: string, params?: object) {
        return Product.getProductLocation(id, params);
    },
    doCreate (data: object, params?: object) {
        return Product.createProductLocation(data, params);
    },
    doUpdate (id: string, data: object, params?: object) {
        return Product.editProductLocation(id, data, params);
    },
    doDelete (id: string, params?: object) {
        return Product.deleteProductLocation(id);
    },
});
