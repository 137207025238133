import { createCrudModule } from '@/utils/vuex-crud';
import { createContractAgreement, deleteContractAgreement, getContractAgreement, getContractAgreements, updateContractAgreement } from '@/api/contractAgreement';


export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return getContractAgreements(params);
    },
    doFetchSingle (id: string) {
        return getContractAgreement(id);
    },
    doCreate (data: object) {
        return createContractAgreement(data);
    },
    doUpdate (id: string, data: object) {
        return updateContractAgreement(id, data);
    },
    doDelete (id: string) {
        return deleteContractAgreement(id);
    }
});
