import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { MoneyTransaction } from '@/types/MoneyTransaction';
import { MIMETypes } from '@/enums';

export const baseUri = '/transactions';

export const getTransactions = (params: object): Promise<PagedCollection<MoneyTransaction>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getTransaction = (id: string, params: object): Promise<MoneyTransaction> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const getOurAccounts = (params: object) =>
    request({
        url: `${baseUri}/our_accounts`,
        method: 'GET',
        params,
    });

export const createTransaction = (type: string, data: object, params: object): Promise<MoneyTransaction> =>
    request({
        url: `/${type}_transactions`,
        method: 'POST',
        data,
        params,
    });

export const updateTransaction = (type: string, id: string, data: object, params: object): Promise<MoneyTransaction> =>
    request({
        url: `/${type}_transactions/${id}`,
        method: 'PATCH',
        data,
        params,
    });

export const reverseTransaction = (type: string, id: string, data: object, params: object): Promise<MoneyTransaction> =>
    request({
        url: `/${type}_transactions/${id}/reverse`,
        method: 'PUT',
        data,
        params,
    });

export const deleteTransaction = (type: string, id: string): Promise<void> =>
    request({
        url: `/${type}_transactions/${id}`,
        method: 'DELETE',
    });

export const getPresets = (params: object) =>
    request({
        url: `${baseUri}/presets`,
        method: 'GET',
        params,
    });

export const getDocumentLink = (id: string) =>
    request({
        url: `${baseUri}/${id}/document`,
        method: 'GET',
    });

export const exportTransactionsToXlsx = (params: object) =>
    request({
        url: `${baseUri}/export`,
        method: 'GET',
        params,
        responseType: 'arraybuffer',
        headers: {
            accept: MIMETypes.XLSX,
        },
    });
