<template>
    <el-tree
        :data="data"
        v-bind="proxyProps"
        :node-key="nodeKey"
        :props="props"
        :default-checked-keys="value"
        :default-expanded-keys="defaultExpandedKeys()"
        show-checkbox
        v-on="$listeners"
        @check="handleCheckChange"
    />
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';

export default {
    name: 'UiTree',

    mixins: [WrapperMixin],

    props: {
        data: {
            type: Array,
            required: true,
        },

        value: {
            type: Array,
            default: () => [],
        },

        nodeKey: {
            type: String,
            default: 'id',
        },

        props: {
            type: Object,
            default: () => ({
                label: 'name',
                children: 'children',
            }),
        },

        isDefaultExpandedKeys: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        updateValue (value) {
            this.$emit('input', value);
        },

        handleCheckChange (checkedNode, treeCheckedStatus) {
            const chekedKeys = [
                treeCheckedStatus.checkedKeys,
                ...treeCheckedStatus.halfCheckedKeys,
            ];
            this.updateValue(...chekedKeys);
        },

        defaultExpandedKeys () {
            if (this.isDefaultExpandedKeys) {
                return this.value;
            } else {
                return [];
            }
        },
    },
};
</script>
