import * as API from '@/api/contractor/contractorProject';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: any) {
        return API.getProjects(params);
    },
    doFetchSingle (id: string) {
        return API.getProject({ projectId: id });
    },
    doCreate (payload: any) {
        return API.createProject(payload);
    },
    doUpdate (id: string, payload: any) {
        return API.updateProject({ ...payload, projectId: id });
    },
    doDelete (id: string) {
        return API.deleteProject({ projectId: id });
    },
});
