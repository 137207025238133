import * as api from '@/api/contractCustomField';
import { createCrudModule } from '@/utils/vuex-crud';

const module = createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return api.getContractCustomFields(params);
    },
    doFetchSingle (id: string, params: object) {
        return api.getContractCustomField(id, params);
    },
    doCreate (data: object) {
        return api.createContractCustomField(data);
    },
    doUpdate (id: string, data: object) {
        return api.updateContractCustomField(id, data);
    },
    doDelete (id: string) {
        return api.deleteContractCustomField(id);
    },
});

Object.assign(module.getters, {
    byContractType: (state, getters) => (contractType) => {
        return getters.list.filter(i =>
            i.active &&
            (!i.contractTypes ||
                (Array.isArray(i.contractTypes) ?
                    i.contractTypes.some(ct => ct.id === contractType) :
                    i.contractTypes.id === contractType) ||
                i.contractTypes.length === 0
            )
        );
    }
});


export default module;
