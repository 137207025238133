import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { TableSetting } from '@/types/TableSetting';

const baseUri = '/table_user_settings';

export const getTableSettings = (params?: object): Promise<PagedCollection<TableSetting>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const changeTableSettings = (contextId: string, data: object): Promise<TableSetting> =>
    request({
        url: `${baseUri}/${contextId}`,
        method: 'PUT',
        data,
    });

export const getTableSetting = (id: string): Promise<TableSetting> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    });
