<template>
    <ui-table-filter-select
        v-model="currentValue"
        v-bind="proxyProps"
        :fetch-items="fetchItems"
        :init-method="fetchInitItems"
        :multiple="multiple"
        label-key="fullName"
        :fetch-on-create="multiple ? currentValue.length > 0 : currentValue"
        v-on="$listeners"
    >
        <template #option="{ item }">
            {{ item.fullName }}
        </template>
    </ui-table-filter-select>
</template>
<script>
import WrapperMixin from '@/mixins/wrapper';
import { getMembers } from '@/api/business';

export default {
    name: 'UiTableFilterResponsible',

    mixins: [WrapperMixin],

    props: {
        value: {
            type: [String, Array],
            default: '',
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        fetchParams: {
            type: Object,
            default: null,
        },
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (value) {
                this.$emit('input', value);
            },
        },

        business () {
            return this.$store.getters['business/current'];
        },
    },

    methods: {
        async fetchItems (query, cb) {
            const params = {
                businessId: this.business?.id,
            };

            if (query) {
                params.search = query;
            }

            try {
                const data = await getMembers(params);
                const items = data['hydra:member'] || [];
                cb(items);
            } catch (e) {
                this.$flashError(e);
            }
        },

        async fetchInitItems (items) {
            const params = {
                businessId: this.business?.id,
                ids: items,
            };

            try {
                const data = await getMembers(params);
                return data['hydra:member'] || [];
            } catch (e) {
                this.$flashError(e);
            }
        },
    },
};
</script>
