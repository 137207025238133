<template>
    <div class="select-tree-group">
        <div
            v-if="enableSearch"
            class="select-tree-group__input"
            :style="{ 'padding-left': `${(level - 1) * 16 + 20}px` }"
        >
            <el-input
                v-model="queryString"
                clearable
                size="mini"
                placeholder="Поиск"
                @input="handleSearchInput"
            />
        </div>

        <ui-loading
            v-if="loading"
            height="48px"
        />

        <el-collapse
            v-else
            v-model="visibleNodes"
            @change="emit('collapse-change')"
        >
            <div
                v-for="node in currentItems"
                :key="node.id"
            >
                <div
                    v-if="!node.enableChildren"
                    class="select-tree-group__option"
                    :class="{ '--selected': isItemSelected(node), '--disabled': isItemDisabled(node) }"
                    :style="{ 'padding-left': `${level * 16 + 20}px` }"
                    @click="handleItemClick(node)"
                >
                    <template v-if="!$slots.default">
                        {{ node.name }}
                    </template>
                    <slot
                        v-else
                        :item="node"
                    />

                    <iconify-icon
                        v-if="isItemSelected(node)"
                        icon="ep:check"
                        class="select-tree-group__option-check"
                    />
                </div>

                <el-collapse-item
                    v-else
                    :name="node.id"
                >
                    <template #title>
                        <div
                            class="select-tree-group__group-option"
                            :style="{ 'padding-left': `${level * 16 + 20}px` }"
                        >
                            <template v-if="!$slots.default">
                                {{ node.name }}
                            </template>
                            <slot
                                v-else
                                :item="node"
                            />
                        </div>
                    </template>

                    <ui-select-tree-group
                        v-model="currentValue"
                        :visible="visibleNodes.includes(node.id)"
                        :parent="node"
                        :enable-search="node.enableSearch"
                        :fetch-items="fetchItems"
                        :level="level + 1"
                        :multiple="node.multipleChildren"
                        :local-items.sync="selectedItems"
                        :persistent-items="persistentItems"
                        :disabled="disabled"
                        @collapse-change="emit('collapse-change')"
                    >
                        <template #default="{ item }">
                            <slot :item="item" />
                        </template>
                    </ui-select-tree-group>
                </el-collapse-item>
            </div>
        </el-collapse>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { debounce } from 'lodash';

interface Item {
    id: string,
    name: string,
    data: any,
    parentId: string | null,
    multipleChildren: boolean,
    enableChildren: boolean,
    enableSearch: boolean,
    children: Item[]
}

interface Props {
    fetchItems: (query: string, node: Item | null, cb: (items: Item[]) => void) => void,
    enableSearch?: boolean,
    parent?: Item | null,
    visible?: boolean,
    level?: number,
    value: Item[],
    multiple?: boolean,
    disabled?: boolean,
    persistentItems?: any[],
    localItems?: Item[]
}

const emit = defineEmits<{
    (e: 'collapse-change'): void,
    (e: 'input', value: string[]): void,
    (e: 'update:localItems', value: Item[]): void
}>();

const currentValue = computed({
    get () {
        return props.value;
    },
    set (val: string[]) {
        emit('input', val);
    }
});

const props = withDefaults(defineProps<Props>(), {
    enableSearch: false,
    visible: false,
    parent: null,
    level: 0,
    multiple: false,
    localItems: () => [],
    disabled: false,
    persistentItems: () => []
});

const visibleNodes = ref([]);
const loading = ref(false);
const queryString = ref('');
const currentItems = ref<Item[]>([]);
const selectedItems = computed({
    get () {
        return props.localItems;
    },
    set (val: Item[]) {
        emit('update:localItems', val);
    }
});

watch(() => props.visible, (val) => {
    if (val && currentItems.value.length === 0) {
        remoteMethod(queryString.value);
    }
});

function isItemSelected (item: Item) {
    return props.value.includes(item.id);
}

function isItemDisabled (item: Item) {
    return props.disabled;
}

function remoteMethod (query: string) {
    loading.value = true;
    queryString.value = query;
    props.fetchItems(query, props.parent, items => {
        currentItems.value = items ?? [];
        loading.value = false;
    });
}

function handleItemClick (item: Item) {
    if (isItemDisabled(item)) {
        return;
    }

    const isAnotherParent = selectedItems.value.some(i =>
        i.parentId !== item.parentId ||
        (!props.multiple && i.id !== item.id)
    );

    const val = isAnotherParent ? [] : selectedItems.value.slice();

    if (!isAnotherParent) {
        const optionIndex = val.findIndex(i => i.id === item.id);

        if (optionIndex === -1) {
            val.push(item);
        } else if (val.length > 1) {
            val.splice(optionIndex, 1);
        }
    } else {
        val.push(item);
    }

    selectedItems.value = val;

    emit('input', val.map(i => i.id));
    selectedItems.value = val;
}

const handleSearchInput = debounce(() => {
    remoteMethod(queryString.value);
}, 300);
</script>

<style lang="scss">
.select-tree-group {
    min-width: 250px;

    .el-collapse {
        border: none;
    }

    .el-collapse-item {
        &__header {
            border: none;
        }

        &__content {
            padding-bottom: 0;
        }

        &__header {
            font-size: 14px;
            line-height: 24px;
            height: auto;
            padding-top: 6px;
            padding-bottom: 6px;

            &:hover {
                background-color: $--background-color-base;
            }
        }

        &__arrow {
            margin-right: 20px;
        }
    }

    &__option {
        position: relative;
        cursor: pointer;
        line-height: 24px;
        height: auto;
        max-height: 96px;
        padding-top: 6px;
        padding-bottom: 6px;
        white-space: normal;
        padding-right: 40px;

        &:hover {
            background-color: $--background-color-base;
        }

        &.--selected {
            color: $--color-primary;
            font-weight: 700;
        }

        &.--disabled {
            color: $--color-text-gray;
        }
    }

    &__option-check {
        position: absolute;
        right: 20px;
        font-size: 12px;
        top: 50%;
        transform: translateY(-50%);
    }

    &__input {
        padding-right: 20px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
}
</style>
