import { changePurchase, createPurchase, deletePurchase, getPurchase, getPurchasesAll } from '@/api/contractor/purchases';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return getPurchasesAll(params);
    },
    doFetchSingle (id, params) {
        return getPurchase(id, params);
    },
    doCreate (data, params) {
        return createPurchase(data, params);
    },
    doUpdate (id, data, params) {
        return changePurchase(id, data, params);
    },
    doDelete (id) {
        return deletePurchase(id);
    },
});
