<template>
    <ui-loading
        v-if="isBalanceLoading"
        class="u-mr-xl"
    />
    <el-popover
        v-else
        ref="popover"
        trigger="click"
        placement="bottom-end"
        :popper-options="{ placement: 'right' }"
    >
        <div class="business-balance">
            <ui-loading v-if="isBalanceLoading" />
            <template v-else>
                <el-row
                    type="flex"
                    align="middle"
                >
                    <el-radio-group
                        v-model="activeRadio"
                        class="u-ml-auto"
                        @change="handleRadioChange"
                    >
                        <el-radio-button
                            v-for="item in radioList"
                            :key="item.id"
                            :label="item.label"
                        >
                            {{ item.name }}
                        </el-radio-button>
                    </el-radio-group>
                </el-row>
                <el-divider class="u-mv-sm" />
                <div
                    class="business-balance__grid"
                >
                    <div
                        v-for="item in formattedAccounts"
                        :key="item.id"
                        class="business-balance__grid-item"
                    >
                        <business-balance-item
                            :data="item"
                        />
                    </div>
                </div>
            </template>
        </div>
        <el-button
            slot="reference"
            type="text"
            class="default-layout__balance u-mr-md"
        >
            <ui-money-value
                :value="balance"
                :colorized="false"
                :hide-zero="false"
                :hide-plus="true"
                without-decimals
                class="u-mr-xs"
                style="display:inline;"
            />
            <iconify-icon icon="akar-icons:chevron-down" />
        </el-button>
    </el-popover>
</template>

<script>
import { get } from 'lodash';
import AccountAPI from '@/api/account';
import BusinessBalanceItem from '@/components/BusinessBalanceItem';

export default {
    components: {
        BusinessBalanceItem
    },

    data () {
        return {
            accounts: [],
            activeRadio: 'group',
            radioList: [
                {
                    label: 'group',
                    name: 'По группам'
                },
                {
                    label: 'entity',
                    name: 'По юр. лицам'
                }
            ],
            isBalanceLoading: false
        };
    },

    computed: {
        businessId () {
            return this.$store.getters['business/currentId'];
        },

        balance () {
            if (this.businessId) {
                const balance = this.accounts.reduce((accumulator, current) => {
                    return accumulator + parseFloat(current.balance);
                }, 0);
                return parseFloat(balance.toFixed(2));
            } else {
                return 0;
            }
        },

        businessRequisites () {
            return this.$store.getters['businessRequisite/list'];
        },

        formattedAccounts () {
            let accounts = [];
            if (this.activeRadio === 'group') {
                accounts = this.formatAccounts('group.id', 'group.name');
            } else {
                accounts = this.formatAccounts('businessRequisite.id', 'businessRequisite.name');
            }
            accounts.sort((a, b) => a.sort - b.sort);
            return accounts;
        }
    },

    watch: {
        businessId: {
            async handler (value) {
                if (value) {
                    await this.fetchData();
                }
            },
            immediate: true
        }
    },

    created () {
        this.$bus.on('account:create', this.handleAccountChanged);
        this.$bus.on('account:update', this.handleAccountChanged);
        this.$bus.on('account:delete', this.handleAccountDeleted);
    },

    beforeDestroy () {
        this.$bus.off('account:create', this.handleAccountChanged);
        this.$bus.off('account:update', this.handleAccountChanged);
        this.$bus.off('account:delete', this.handleAccountDeleted);
    },

    methods: {
        formatAccounts (formatBy, title) {
            return this.accounts
                .filter(i => i.active || Math.abs(Number(i.balance)) > Number.EPSILON)
                .reduce((accumulator, current) => {
                    const item = accumulator.find(item => item.id === get(current, formatBy));
                    if (item) {
                        item.children.push({
                            name: current.name,
                            balance: current.balance
                        });
                    } else {
                        const group = {
                            id: get(current, formatBy),
                            name: get(current, title) ?? 'Нераспределенные',
                            sort: this.activeRadio === 'group' ? current?.group?.sort || 0 : 0,
                            children: [
                                {
                                    name: current.name,
                                    balance: current.balance
                                }
                            ]
                        };

                        if (this.activeRadio === 'group') {
                            if (!get(current, title)) {
                                group.sort = -1;
                            } else {
                                group.sort = current.group.sort;
                            }
                        } else {
                            group.sort = 0;
                        }

                        accumulator.push(group);
                    }
                    return accumulator;
                }, []);
        },

        async fetchData () {
            this.isBalanceLoading = true;

            try {
                const data = await AccountAPI.findAll({
                    business: this.businessId,
                    hideBalance: false,
                    pagination: false,
                    with: ['balance']
                });

                this.accounts = data['hydra:member'] || [];
            } catch (e) {
                this.accounts = [];
                this.$flashError(e);
            }

            this.isBalanceLoading = false;
        },

        handleRadioChange () {
            this.$refs.popover.updatePopper();
        },

        handleAccountChanged (data) {
            if (data.business?.id === this.businessId) {
                this.fetchData();
            }
        },

        handleAccountDeleted () {
            this.fetchData();
        }
    }
};
</script>
