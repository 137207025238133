<template>
    <ui-table-filter-select
        v-model="currentValue"
        :label="label"
        :fetch-items="fetchRequisites"
        :init-method="fetchInitRequisites"
        :fetch-on-create="currentValue.length > 0"
        :disabled-item-method="disabledItemMethod"
        :set-style="nested ? setStyle : () => {}"
        remote
        multiple
        enable-select-group
    />
</template>

<script>
import BusinessRequisiteAPI from '@/api/businessRequisite';
import { flatMap, get, groupBy } from 'lodash';

export default {
    name: 'UiTableFilterBusinessRequisite',

    props: {
        value: {
            type: Array,
            required: true,
        },

        label: {
            type: String,
            default: '',
        },

        nested: {
            type: Boolean,
            default: true,
        },

        setOptionStyle: {
            type: Function,
            default: () => ({}),
        },
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },
            set (value) {
                this.$emit('input', value);
            },
        },
    },

    methods: {
        async fetchRequisites (query, cb) {
            const params = {
                business: this.$store.getters['business/currentId'],
                active: true,
                order: {
                    name: 'asc',
                },
            };

            if (query !== '') {
                params.search = { q: query };
            }

            try {
                const data = await BusinessRequisiteAPI.findAll(params);
                const items = data['hydra:member'].map(item => ({ ...item })) || [];

                const groupedByBusiness = groupBy(items, item => item.business.id);

                const flattenedData = flatMap(groupedByBusiness, (children, businessId) => {
                    const business = get(children, '[0].business');
                    const businessEntry = {
                        name: business.name,
                        id: business.id,
                        level: 0,
                        isGroup: true,
                    };
                    const childrenEntries = children.map(child => ({
                        name: child.name,
                        id: child.id,
                        level: 1,
                        parentId: businessId,
                    }));

                    return [businessEntry, ...childrenEntries];
                });

                flattenedData.push({
                    name: "Без реквизитов",
                    id: 'without_requisite',
                    active: true
                });

                cb(flattenedData);
            } catch (e) {
                this.$flashError(e);
            }
        },

        async fetchInitRequisites (items) {
            const params = {
                business: this.$store.getters['business/currentId'],
                id: items.filter(item => item !== 'without_requisite'),
                order: {
                    name: 'asc',
                },
            };
            try {
                const data = await BusinessRequisiteAPI.findAll(params);
                const items = (data['hydra:member'] || [])?.map(item => ({ id: item.id, name: item?.name })) || [];
                items.push({
                    name: "Без реквизитов",
                    id: 'without_requisite',
                    active: true
                });
                return items;
            } catch (e) {
                this.$flashError(e);
            }
        },

        disabledItemMethod (item) {
            return item.isGroup;
        },

        setStyle (item) {
            return {
                'padding-left': `${(item.level + 1) * 20}px`,
                ...this.setOptionStyle(item),
            };
        },
    },
};
</script>
