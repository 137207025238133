<template>
    <el-form
        :model="form"
        label-position="top"
        class="login-form"
        @keypress.enter.native="handleSubmit"
    >
        <div class="login-form__title">
            Войдите, чтобы продолжить
        </div>

        <ui-error :error="error" />

        <el-form-item
            prop="email"
            label="E-mail"
        >
            <el-input v-model="form.email" />
        </el-form-item>
        <el-form-item
            prop="password"
            label="Пароль"
        >
            <el-input
                v-model="form.password"
                type="password"
            />
        </el-form-item>
        <el-form-item>
            <el-button
                type="primary"
                class="login-form__submit"
                @click="handleSubmit"
            >
                Войти
            </el-button>
        </el-form-item>
        <el-form-item>
            <router-link :to="{ name: 'ForgotPassword' }">
                Забыли пароль?
            </router-link>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    data () {
        return {
            form: {
                email: '',
                password: '',
            },
        };
    },

    computed: {
        isLoading () {
            return this.$store.getters['auth/isLoading'];
        },

        hasError () {
            return this.$store.getters['auth/hasError'];
        },

        error () {
            return this.$store.getters['auth/error'];
        },
    },

    created () {
        const redirect = this.$route.query.redirect;
        if (this.$store.getters['auth/isAuthenticated']) {
            if (typeof redirect !== 'undefined') {
                this.$router.push({ path: redirect });
            } else {
                this.$router.push({ path: '/' });
            }
        }
    },

    methods: {
        async handleSubmit () {
            const payload = {
                email: this.form.email,
                password: this.form.password,
            };
            const redirect = this.$route.query.redirect;

            await this.$store.dispatch('auth/login', payload);
            if (!this.hasError) {
                await this.$store.dispatch('security/resetPermissions');
                if (typeof redirect !== 'undefined') {
                    await this.$router.push({ path: redirect });
                } else {
                    await this.$router.push({ path: '/' });
                }
            }
        },
    },
};
</script>
