import Vue from 'vue';
import Dayjs from './dayjs';
import Dirty from './dirty';
import Drawer from './drawer';
import ElementUI from './element-ui';
import EventBus from './event-bus';
import Flash from './flash';
import Iconify from './iconify';
import IframeResizer from './iframe-resizer';
import Permission from './permission';
import Plural from './plural';
import PortalVue from './portal-vue';
import ResizeObserver from './resize-observer';
import ScrollTo from './scroll-to';
import Sentry from './sentry';
import TinyColor from './tinycolor';
import Unleash from '@/plugins/unleash';
import './sortable';
import MentionablePlugin from 'vue-mention';
import Pusher from './pusher';

export function installPlugins (): void {
    Vue.use(Dayjs);
    Vue.use(Dirty);
    Vue.use(Drawer);
    Vue.use(ElementUI);
    Vue.use(EventBus);
    Vue.use(Flash);
    Vue.use(Iconify);
    Vue.use(IframeResizer);
    Vue.use(Permission);
    Vue.use(Plural);
    Vue.use(PortalVue);
    Vue.use(ResizeObserver);
    Vue.use(ScrollTo);
    Vue.use(Sentry);
    Vue.use(TinyColor);
    Vue.use(Unleash);
    Vue.use(MentionablePlugin);
    Vue.use(Pusher);
}
