<template>
    <div
        v-loading="true"
        element-loading-background="transparent"
        :style="{ 'flexShrink': 0, 'height': height }"
    />
</template>

<script>
export default {
    name: 'UiLoading',

    props: {
        height: {
            type: String,
            default: '100px',
        },
    },
};
</script>
