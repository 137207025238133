export default {
    props: {
        showTitle: {
            type: Boolean,
            default: false,
        },
    },

    data () {
        return {
            title: '',
        };
    },

    mounted () {
        this.$watch(
            () => this.$refs.select?.selectedLabel,
            value => {
                if (this.showTitle) {
                    this.title = value ?? '';
                }
            },
            { immediate: true },
        );
    },
};
