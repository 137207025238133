import request from '@/utils/request';
import { MediaObject } from '@/types/MediaObject';

const baseUri = '/requisites';

export const getRequisites = (params: any) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getRequisite = (id: string) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    });

export const getCards = (params: any) =>
    request({
        url: `${baseUri}/cards`,
        method: 'GET',
        params,
    });

export const generateCard = (id: string): Promise<MediaObject> =>
    request({
        url: `${baseUri}/${id}/generate_card`,
        method: 'POST',
    });
