<template>
    <div class="timeline-item-changeset-table">
        <div class="timeline-item-changeset-table__field">
            {{ displayField }}
        </div>

        <div class="timeline-item-changeset-table__wrapper">
            <table class="timeline-item-changeset-table__inner">
                <tr>
                    <th
                        v-for="column in columns"
                        :key="column.key"
                    >
                        <div class="cell">
                            {{ column.title }}
                        </div>
                    </th>
                </tr>
                <tr
                    v-for="(row, index) in tableData"
                    :key="`${index}_${row.id}`"
                >
                    <td
                        v-if="row.isAction"
                        :key="index"
                        :colspan="getColspan(row)"
                        :class="cellClassName(row, 'title')"
                    >
                        <div
                            class="cell"
                            :title="row.title"
                        >
                            {{ row.title }}
                        </div>
                    </td>
                    <template v-for="(value, key) in row">
                        <td
                            v-if="!['compareValue', 'isBefore'].includes(key) && !row.isAction"
                            :key="key"
                            :colspan="getColspan(row)"
                            :class="cellClassName(row, key)"
                        >
                            <div
                                class="cell"
                                :title="value"
                            >
                                {{ value }}
                            </div>
                        </td>
                    </template>
                </tr>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import FormatMixin from '@/mixins/format';
import TimelineChangeMixin from '@/mixins/timeline-changeset-items';
import { TimelineChangeTypes } from '@/enums';
import Vue from 'vue';

export default Vue.extend({
    name: 'UiTimelineItemChangesetCommodityStocks',
    mixins: [FormatMixin, TimelineChangeMixin],

    data () {
        return {
            columns: [
                { title: 'Категория', key: 'category' },
                { title: 'Наименование товара', key: 'productName' },
                { title: 'Номенклуатурный №', key: 'nomenclatureNumber' },
                { title: 'Цена', key: 'price' },
                { title: 'Количество', key: 'quantity' },
                { title: 'Сумма закупки', key: 'purchaseAmount' },
                { title: 'Склад', key: 'warehouse' },
                { title: 'Доп информация', key: 'additionalInfo' },
            ],
        };
    },

    computed: {
        tableData () {
            const rows: any[] = [];
            const groups = [
                this.changeset.changes.filter(i => i.changeType === TimelineChangeTypes.COLLECTION_RECORD_ADD),
                this.changeset.changes.filter(i => i.changeType === TimelineChangeTypes.COLLECTION_RECORD_DELETE),
                this.changeset.changes.filter(i => i.changeType === TimelineChangeTypes.COLLECTION_RECORD_EDIT),
            ];

            this.changeset.changes.forEach(change => {
                if (change.changeType === TimelineChangeTypes.FIELD_EDIT) {
                    rows.push(
                        {
                            title: 'Было',
                            isAction: true,
                            isSecondary: true,
                        },
                        ...change.from.map((i, index) => ({ ...i, compareValue: change.to[index], isBefore: true })),
                        {
                            title: 'Стало',
                            isAction: true,
                            isSecondary: true,
                        },
                        ...change.to.map((i, index) => ({ ...i, compareValue: change.from[index] })),
                    );
                } else {
                    // Для поддержки старого формата таймлайна
                    groups.forEach(group => {
                        if (group.length) {
                            rows.push({
                                title: this.getChangeDescription(group[0]),
                                isAction: true,
                            });

                            if (group[0].changeType === TimelineChangeTypes.COLLECTION_RECORD_EDIT) {
                                rows.push(
                                    {
                                        title: 'Было',
                                        isAction: true,
                                        isSecondary: true,
                                    },
                                    ...group.map(i => ({ ...i.from, compareValue: i.to, isBefore: true })),
                                    {
                                        title: 'Стало',
                                        isAction: true,
                                        isSecondary: true,
                                    },
                                    ...group.map(i => ({ ...i.to, compareValue: i.from })),
                                );
                            } else {
                                rows.push(...group.map(i => ({ ...i.value })));
                            }
                        }
                    });
                }
            });

            return rows.map(i => {
                if (!i.isAction) {
                    return {
                        category: i.category?.name,
                        productName: i.possessionProduct?.name,
                        nomenclatureNumber: i.possessionProduct?.nomenclatureNumber,
                        price: i?.price,
                        quantity: i.quantity,
                        purchaseAmount: i?.price * i.quantity,
                        location: i.productLocation?.name,
                        description: i.description,
                        compareValue: {
                            category: i.compareValue?.category?.name,
                            productName: i.compareValue?.possessionProduct?.name,
                            nomenclatureNumber: i.compareValue?.possessionProduct?.nomenclatureNumber,
                            price: i.compareValue?.price,
                            quantity: i.compareValue?.quantity,
                            purchaseAmount: i.compareValue?.price * i.compareValue?.quantity,
                            location: i.compareValue?.productLocation?.name,
                            description: i.compareValue?.description,
                        },
                        isBefore: i.isBefore,
                    };
                }
                return i;
            });
        },
    },
});
</script>

<style lang="scss">
.timeline-item-changeset-table {
    font-size: 11px;
    line-height: normal;

    &:not(:first-child), & + .timeline-item-changeset {
        margin-top: 8px;
    }

    &__field {
        font-weight: bold;
        white-space: nowrap;
        color: $--color-text-gray;
        margin-bottom: 2px;
    }

    &__wrapper {
        overflow: auto;
    }

    &__inner {
        border-collapse: collapse;
        min-width: 100%;

        .cell {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &, th, td {
            border: 1px solid $--table-border-color;
        }

        td, th {
            padding: 4px;
            font-size: 12px;
            max-width: 150px;
        }

        th {
            color: $--color-text-secondary;
        }

        td.-value-changed {
            color: $--color-primary-light-3;

            &.-is-before {
                color: $--color-text-secondary;
            }
        }

        .-value {
            text-align: right;
        }

        .-is-action {
            font-weight: bold;
        }

        .-is-secondary {
            color: $--color-text-secondary;
        }
    }
}
</style>
