import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { ContractCustomField } from '@/types/DocumentCustomField';

const baseUri = '/contract_custom_fields';

export const getContractCustomFields = (params?: object): Promise<PagedCollection<ContractCustomField>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getContractCustomField = (id: string, params?: object): Promise<ContractCustomField> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createContractCustomField = (data: object, params?: object): Promise<ContractCustomField> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
        params,
    });

export const updateContractCustomField = (id: string, data: object, params?: object): Promise<ContractCustomField> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
        params,
    });

export const deleteContractCustomField = (id: string, params?: object): Promise<null> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
        params,
    });
