import { createDismissal, getDismissal, updateDismissal } from '@/api/staff/personnelDocument';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchSingle (id, params) {
        return getDismissal(id, params);
    },
    doCreate (data) {
        return createDismissal(data);
    },
    doUpdate (id, data) {
        return updateDismissal(id, data);
    },
});
