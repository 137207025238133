import * as api from '@/api/possessions/possessions/receiptProduct';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return api.getReceiptProducts(params);
    },
    doFetchSingle (id, params) {
        return api.getReceiptProduct(id, params);
    },
    doCreate (data) {
        return api.createReceiptProducts(data);
    },
    doUpdate (id, data) {
        return api.editReceiptProducts(id, data);
    },
});
