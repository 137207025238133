import request from '@/utils/request';

const baseUri = '/staff/personnel_documents';

export const getDocuments = (params) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getDocument = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    });

export const deleteDocument = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });

export const getDocumentChange = (id, params) =>
    request({
        url: `${baseUri}/changes/${id}`,
        method: 'GET',
        params,
    });

export const createDocumentChange = (data) =>
    request({
        url: `${baseUri}/changes`,
        method: 'POST',
        data,
    });

export const updateDocumentChange = (id, data) =>
    request({
        url: `${baseUri}/changes/${id}`,
        method: 'PATCH',
        data,
    });

export const getDismissal = (id, params) =>
    request({
        url: `${baseUri}/dismissals/${id}`,
        method: 'GET',
        params,
    });

export const createDismissal = (data) =>
    request({
        url: `${baseUri}/dismissals`,
        method: 'POST',
        data,
    });

export const updateDismissal = (id, data) =>
    request({
        url: `${baseUri}/dismissals/${id}`,
        method: 'PATCH',
        data,
    });

export const getRecruitment = (id, params) =>
    request({
        url: `${baseUri}/recruitments/${id}`,
        method: 'GET',
        params,
    });

export const createRecruitment = (data) =>
    request({
        url: `${baseUri}/recruitments`,
        method: 'POST',
        data,
    });

export const updateRecruitment = (id, data) =>
    request({
        url: `${baseUri}/recruitments/${id}`,
        method: 'PATCH',
        data,
    });

export const getVacationCompensation = (id, params) =>
    request({
        url: `${baseUri}/vacation_compensations/${id}`,
        method: 'GET',
        params,
    });

export const createVacationCompensation = (data) =>
    request({
        url: `${baseUri}/vacation_compensations`,
        method: 'POST',
        data,
    });

export const updateVacationCompensation = (id, data) =>
    request({
        url: `${baseUri}/vacation_compensations/${id}`,
        method: 'PATCH',
        data,
    });

export const getVacation = (id, params) =>
    request({
        url: `${baseUri}/vacations/${id}`,
        method: 'GET',
        params,
    });

export const createVacation = (data) =>
    request({
        url: `${baseUri}/vacations`,
        method: 'POST',
        data,
    });

export const updateVacation = (id, data) =>
    request({
        url: `${baseUri}/vacations/${id}`,
        method: 'PATCH',
        data,
    });

export const approveDocument = (id, data) =>
    request({
        url: `${baseUri}/${id}/approve`,
        method: 'POST',
        data,
    });

export const disapproveDocument = (id, data) =>
    request({
        url: `${baseUri}/${id}/disapprove`,
        method: 'POST',
        data,
    });

export const getSickList = (id, params) =>
    request({
        url: `${baseUri}/sick_lists/${id}`,
        method: 'GET',
        params,
    });

export const createSickList = (data) =>
    request({
        url: `${baseUri}/sick_lists`,
        method: 'POST',
        data,
    });

export const updateSickList = (id, data) =>
    request({
        url: `${baseUri}/sick_lists/${id}`,
        method: 'PATCH',
        data,
    });

export const createParentalLeave = (data) =>
    request({
        url: `${baseUri}/parental_leaves`,
        method: 'POST',
        data,
    });

export const getParentalLeave = (id, params) =>
    request({
        url: `${baseUri}/parental_leaves/${id}`,
        method: 'GET',
        params,
    });

export const updateParentalLeave = (id, data) =>
    request({
        url: `${baseUri}/parental_leaves/${id}`,
        method: 'PATCH',
        data,
    });

export const getSeparationLeave = (id, params) =>
    request({
        url: `${baseUri}/separation_leaves/${id}`,
        method: 'GET',
        params,
    });

export const createSeparationLeave = (data) =>
    request({
        url: `${baseUri}/separation_leaves`,
        method: 'POST',
        data,
    });

export const updateSeparationLeave = (id, data) =>
    request({
        url: `${baseUri}/separation_leaves/${id}`,
        method: 'PATCH',
        data,
    });
