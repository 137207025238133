import request from '@/utils/request';

const baseUri = '/loan_analytics';

export const getLoanAnalytics = (params) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getLoanAnalytic = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    });

export const createLoanAnalytic = (data) =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });

export const deleteLoanAnalytic = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });

export const updateLoanAnalytic = (id, data) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });
