import request from '@/utils/request';

const baseUri = '/possessions/receipt_documents';

export const getReceiptDocuments = (params: object) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });
export const createReceiptDocument = (data: object) =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });
export const getReceiptDocument = (id: string, params: object) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });
export const deleteReceiptDocument = (id: string) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });
export const editReceiptDocument = (id: string, data: object) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });
