<template>
    <ui-card class="footer-menu">
        <div class="footer-menu__inner">
            <slot />
        </div>
    </ui-card>
</template>

<script>
export default {
    name: 'UiFooterMenu',
};
</script>

<style lang="scss">
.footer-menu {
    &__inner {
        display: flex;
        align-items: center;
    }
}
</style>
