<template>
    <div class="image-viewer">
        <img
            ref="image"
            :src="src"
            alt=""
            class="image-viewer__image"
            @load="handleImageLoaded"
        >
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'UiImageViewer'
});
</script>

<script setup lang="ts">
import createPanZoom, { PanZoom, PanZoomOptions } from 'panzoom';
import { ref } from 'vue';

interface Props {
    src: string,
    viewerOptions?: PanZoomOptions
}

const props = withDefaults(defineProps<Props>(), {
    viewerOptions: () => ({
        autocenter: true,
        bounds: true,
        maxZoom: 2,
        minZoom: 0.2
    })
});

const image = ref<HTMLElement | null>(null);
const viewer = ref<PanZoom | null>(null);
function handleImageLoaded () {
    if (image.value) {
        viewer.value = createPanZoom(image.value, props.viewerOptions);
    }
}
</script>

<style lang="scss">
.image-viewer {
    overflow: hidden;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}
</style>
