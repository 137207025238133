import request from '@/utils/request';

const baseUri = '/possessions/movement_product_documents';

export const createMovementProduct = (data) =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });
export const getMovementProduct = (id, params) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const editMovementProduct = (id, data) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });
