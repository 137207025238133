import request from '@/utils/request';
import { ProductLocation } from '@/types/ProductLocation';
import { PagedCollection } from '@/types/hydra';

const baseUri = '/possessions/product_locations';

export const getProductLocations = (params?: object): Promise<PagedCollection<ProductLocation>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });
export const getProductLocation = (id: string, params?: object): Promise<ProductLocation> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    });
export const createProductLocation = (data: object, params?: object): Promise<ProductLocation> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });
export const deleteProductLocation = (id: string, params?: object): Promise<void> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });
export const editProductLocation = (id: string, data: object, params?: object): Promise<ProductLocation> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });
