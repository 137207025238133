import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { TochkaIntegration, TochkaProfile } from '@/types/TochkaBank';

const baseUri = '/tochka_bank_integrations';

export const getIntegrations = (params: object): Promise<PagedCollection<TochkaIntegration>> =>
    request({
        url: `${baseUri}/integrations`,
        method: 'GET',
        params
    });

export const getIntegration = (id: string): Promise<TochkaIntegration> =>
    request({
        url: `${baseUri}/integrations/${id}`,
        method: 'GET'
    });

export const updateIntegration = (id: string, data: object): Promise<TochkaIntegration> =>
    request({
        url: `${baseUri}/integrations/${id}`,
        method: 'PATCH',
        data
    });

export const createProfile = (data: object): Promise<TochkaProfile> =>
    request({
        url: `${baseUri}/profiles`,
        method: 'POST',
        data
    });

export const getProfile = (id: string): Promise<TochkaProfile> =>
    request({
        url: `${baseUri}/profiles/${id}`,
        method: 'GET'
    });

export const deleteProfile = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/profiles/${id}`,
        method: 'DELETE'
    });

export const updateProfile = (id: string, data: object): Promise<TochkaProfile> =>
    request({
        url: `${baseUri}/profiles/${id}`,
        method: 'PATCH',
        data
    });

export const refreshProfile = (id: string, data: object) =>
    request({
        url: `${baseUri}/profiles/${id}/refresh`,
        method: 'POST',
        data
    });
