import store from '@/store';
import { default as Pusher } from 'pusher-js';
import { usePusherHelper } from '@/composable/usePusherHelper';

export const createStoreChannel = (pusher: Pusher) => {
    const { subscribe, unsubscribe } = usePusherHelper(pusher, {
        channelName: () => `private-encrypted-user.${store.getters['auth/userId']}`,
        events: [
            ['NotificationEntry:created', (data) => {
                store.dispatch('notification/receiveMessage', { data });
            }]
        ]
    });

    if (store.getters['auth/userId']) {
        subscribe();
    }

    store.subscribe(mutation => {
        if (mutation.type === 'auth/INVALIDATE_TOKEN') {
            unsubscribe();
        } else if (mutation.type === 'auth/AUTHENTICATING_SUCCESS') {
            subscribe();
        }
    });
};
