<template>
    <ui-table-filter-select
        v-model="currentValue"
        v-bind="proxyProps"
        :fetch-items="fetchItems"
        :init-method="fetchInitItems"
        v-on="$listeners"
    />
</template>

<script lang="ts">
import Vue from 'vue';
import WrapperMixin from '@/mixins/wrapper';
import { ContractStage } from '@/types/Contract';
import { getContractStages } from '@/api/contractStage';

export default Vue.extend({
    name: 'UiTableFilterContractStages',
    mixins: [WrapperMixin],
    props: {
        value: {
            type: [String, Array],
            default: ''
        },

        fetchParams: {
            type: Object,
            default: () => ({})
        }
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (value) {
                this.$emit('input', value);
            }
        },

        businessId () {
            return this.$store.getters['business/currentId'];
        }
    },

    methods: {
        async fetchItems (query: string, cb: (items: ContractStage[]) => void) {
            const params = {
                ...this.fetchParams,
                business: this.businessId,
                pagination: 0,
            };

            if (query) {
                params.name = query;
            }

            try {
                const data = await getContractStages(params);
                const items = data['hydra:member'] || [];

                cb(items);
            } catch (e) {
                this.$flashError(e as Error);
            }
        },

        async fetchInitItems (items: string[]) {
            const params = {
                business: this.businessId,
                id: items
            };

            try {
                const data = await getContractStages(params);
                return data['hydra:member'] || [];
            } catch (e) {
                this.$flashError(e as Error);
                return [];
            }
        }
    }
});
</script>

<style scoped>

</style>
