import request from '@/utils/request';

export const baseUri = '/invoices';

export const getInvoices = (params) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getInvoice = (id, params) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createInvoice = (data, params) =>
    request({
        url: baseUri,
        method: 'POST',
        params,
        data,
    });

export const updateInvoice = (id, data, params) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        params,
        data,
    });

export const deleteInvoice = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });

export const changeStatus = (id, data) =>
    request({
        url: `${baseUri}/${id}/change_status`,
        method: 'PATCH',
        data,
    });
