import * as api from '@/api/contractor/account';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return api.getAccounts(params);
    },
    doFetchSingle (id, params) {
        return api.getAccount(id, params);
    },
    doCreate (data, params) {
        return api.createAccount({ data, params });
    },
    doUpdate (id, data, params) {
        return api.updateAccount({ id, data, params });
    },
    doDelete (id) {
        return api.deleteAccount({ id });
    },
});
