<template>
    <div
        :class="{ 'is-active-label': labelActive }"
        class="table-filter"
    >
        <div class="table-filter__slot">
            <div
                v-if="label"
                class="table-filter__label"
            >
                <iconify-icon
                    slot="reference"
                    icon="fa6-solid:filter"
                    class="table-filter__icon"
                />
                {{ label }}
            </div>
            <el-select
                ref="select"
                v-model="currentValue"
                v-bind="proxyProps"
                class="table-filter__control"
                multiple
                :multiple-limit="multiple ? 5 : 1"
                placeholder=""
                @visible-change="handleControlVisibleChange"
                @change="handleChange"
                @clear="handleClear"
            >
                <el-option
                    v-for="enumValue in enumValues"
                    :key="enumValue.id"
                    :value="enumValue.id"
                    :label="enumValue.name"
                />
            </el-select>
        </div>
    </div>
</template>

<script>
// TODO: Вытащить кучу общего в миксины

import { sortBy } from 'lodash';
import * as enums from '@/enums';
import WrapperMixin from '@/mixins/wrapper';

export default {
    name: 'UiTableFilterEnumSelect',

    mixins: [WrapperMixin],

    props: {
        value: {
            type: [String, Array],
            default: '',
        },
        enum: {
            type: String,
            required: true,
            validator: v => v in enums,
        },
        i18nKey: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        width: {
            type: Number,
            default: 300,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        only: {
            type: Array,
            default: () => [],
        },
    },

    data () {
        return {
            focus: false,
        };
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },
            set (value) {
                this.$emit('input', value);
            },
        },

        enumValues () {
            return sortBy(
                Object.values(enums[this.enum])
                    .filter(item => this.only.length ? this.only.includes(item) : item)
                    .map(value => ({
                        id: value,
                        name: this.$t(`enum.${this.i18nKey}.${value}`),
                    })),
                'name',
            );
        },

        labelActive () {
            if (
                (Array.isArray(this.currentValue) && this.currentValue.length > 0) ||
                (typeof this.currentValue === 'string' && this.currentValue !== '')
            ) {
                return true;
            }

            return this.focus;
        },
    },

    watch: {
        value () {
            this.$refs.select.blur();
        },
        $props: {
            immediate: true,
            handler () {
                this.validatePropOnly();
            },
        },
    },

    methods: {
        getDefaultProps () {
            return {
                filterable: true,
                noDataText: this.$t('message.enterText'),
                noMatchText: ' ',
            };
        },

        handleChange () {
            this.$emit('select', this.value);
            this.$emit('change', this.value);
        },

        handleClear () {
            this.$emit('select', this.value);
            this.$emit('change', this.value);
        },

        handleControlVisibleChange (value) {
            this.focus = value;
        },

        validatePropOnly () {
            if (this.only.some(i => !Object.values(enums[this.enum]).includes(i))) {
                console.error('Invalid props in ENUM select');
            }
        },
    },
};
</script>
