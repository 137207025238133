<template>
    <ui-table-filter-select
        v-model="currentValue"
        v-bind="proxyProps"
        :fetch-items="fetchReorderedItems"
        :init-method="fetchInitAccounts"
        :disabled-item-method="disabledItemMethod"
        :set-style="nested ? setStyle : () => {}"
        remote
        v-on="$listeners"
    />
</template>
<script>
import { reorderNested } from '@/utils';
import WrapperMixin from '@/mixins/wrapper';
import SelectNestedMixin from '@/mixins/select-nested';
import AccountAPI from '@/api/account';
import { pickBy } from 'lodash';

export default {
    name: 'UiTableFilterOurAccountSelect',

    mixins: [WrapperMixin, SelectNestedMixin],

    props: {
        multiple: {
            type: Boolean,
            default: true,
        },

        fetchOnCreate: {
            type: Boolean,
            default: false,
        },

        value: {
            type: [String, Array],
            default: '',
        },

        nested: {
            type: Boolean,
            default: true,
        },

        fetchParams: {
            type: Object,
            default: null,
        },
    },

    computed: {
        business () {
            return this.$store.getters['business/current'];
        },
    },

    methods: {
        reorderData (items) {
            return [...reorderNested(items, this.sortByField)].sort((a, b) => a.sort - b.sort);
        },

        fetchReorderedItems (query, cb) {
            this.fetchAccounts(query, items => {
                if (!this.nested) {
                    cb(items);
                } else {
                    const groupItems = [];
                    items.forEach(item => {
                        const groupSort = item.group?.sort ?? Number.MAX_SAFE_INTEGER;
                        if (item.active) {
                            item.sort = groupSort;
                        } else {
                            item.sort = groupSort + 0.5;
                        }

                        if (item.businessGroupBusinessId) {
                            item.parent = {
                                id: item.businessGroupBusinessId,
                                name: item.businessGroupBusinessName,
                            };
                        } else {
                            item.parent = {
                                id: item.group?.id ?? '_',
                                name: item.group?.name ?? 'Без группы',
                                sort: groupSort,
                            };
                        }
                        if (groupItems.every(i => i.id !== item?.parent?.id)) {
                            groupItems.push({
                                ...item.parent,
                                isGroup: true,
                            });
                        }
                    });
                    cb(this.reorderData([...items, ...groupItems]));
                }
            });
        },

        async fetchAccounts (query, cb) {
            const params = {
                ...this.fetchParams,
                business: this.business?.id,
            };

            if (query) {
                params.name = query;
            }

            try {
                const data = await AccountAPI.findAll(pickBy(params));
                const items = data['hydra:member'] || [];

                cb(items);
            } catch (e) {
                this.$flashError(e);
            }
        },

        async fetchInitAccounts (items) {
            const params = {
                business: this.business?.id,
                id: items,
            };

            try {
                const data = await AccountAPI.findAll(pickBy(params));
                return data['hydra:member'] || [];
            } catch (e) {
                this.$flashError(e);
                return [];
            }
        },

        disabledItemMethod (item) {
            return item.isGroup || false;
        },
    },
};
</script>
