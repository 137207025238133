import * as accountGroupAPI from '@/api/accountGroup';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return accountGroupAPI.getAccountGroups(params);
    },
    doFetchSingle (id) {
        return accountGroupAPI.getAccountGroup(id);
    },
    doCreate (data) {
        return accountGroupAPI.createAccountGroup(data);
    },
    doUpdate (id, data) {
        return accountGroupAPI.updateAccountGroup(id, data);
    },
    doDelete (id) {
        return accountGroupAPI.deleteAccountGroup(id);
    },
});
