<template>
    <svg
        class="svg-icon"
        aria-hidden="true"
        :style="iconStyle"
        v-on="$listeners"
    >
        <use :xlink:href="iconName" />
    </svg>
</template>

<script>
export default {
    name: 'UiSvgIcon',

    props: {
        icon: {
            type: String,
            required: true,
        },

        color: {
            type: String,
            default: '',
        },
    },

    computed: {
        iconName () {
            return `#icon-${this.icon}`;
        },

        iconStyle () {
            if (this.color) {
                return `color: ${this.color}`;
            }

            return '';
        },
    },
};
</script>

<style scoped>
.svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
</style>
