<template>
    <div
        ref="progress"
        class="progress"
    >
        <el-tooltip placement="top">
            <template #content>
                <slot name="inner" />
            </template>

            <div
                class="progress__outer"
                :style="{ height: `${strokeWidth}px` }"
            >
                <div
                    ref="inner"
                    class="progress__inner"
                    :style="innerStyle"
                >
                    <div
                        ref="innerText"
                        :class="[
                            'progress__inner-text',
                            {
                                '-overflow': isInnerTextOverflown
                            }
                        ]"
                        :style="{ color: isInnerTextOverflown ? color: '' }"
                    >
                        <slot name="inner" />
                    </div>
                </div>
                <div
                    ref="append"
                    class="progress__append-text"
                >
                    <slot name="append" />
                </div>
            </div>
        </el-tooltip>
    </div>
</template>

<script>
export default {
    name: 'UiProgress',
    props: {
        color: {
            type: String,
            default: '#55249b',
        },

        percentage: {
            type: Number,
            required: true,
        },

        strokeWidth: {
            type: Number,
            default: 8,
        },
    },

    data () {
        return {
            isInnerTextOverflown: false,
            observer: null,
        };
    },

    computed: {
        innerStyle () {
            return {
                width: `${this.percentage > 100 ? 100 : this.percentage}%`,
                backgroundColor: this.color,
            };
        },
    },

    mounted () {
        this.observeInnerText();
    },

    beforeDestroy () {
        this.observer?.unobserve(this.$refs.inner);
    },

    methods: {
        observeInnerText () {
            this.observer = new ResizeObserver(() => {
                this.isInnerTextOverflown = this.$refs.innerText.clientWidth > this.$refs.inner.clientWidth;
            });

            this.observer.observe(this.$refs.inner);
        },
    },
};
</script>

<style lang="scss">
.progress {
    position: relative;

    &__outer {
        background-color: $--border-color-lighter;
        border-radius: 0 100px 100px 0;
        display: flex;
        align-items: center;
    }

    @keyframes progress {
        from {
            width: 0;
        }
    }

    &__inner {
        height: 100%;
        border-radius: 0 100px 100px 0;
        position: relative;
        transition: 1.5s;
        width: 100%;
        display: flex;
        align-items: center;
        animation: progress;
        animation-duration: 1.5s;
        justify-content: flex-end;
    }

    &__inner-text {
        margin-left: auto;
        margin-right: 8px;
        color: $--color-white;
        font-size: 12px;
        white-space: nowrap;
        transition: 0.5s;

        &.-overflow {
            margin-right: -8px;
            transform: translateX(100%);
        }
    }

    &__append-text {
        position: absolute;
        right: -88px;
        font-size: 12px;
    }
}
</style>
