import type { PluginFunction } from 'vue';
import Vue from 'vue';
import { throttle } from 'lodash';

export default ((app: typeof Vue): void => {
    app.directive('resize', {
        bind (el, bindings) {
            if (typeof bindings.value !== 'function') {
                return;
            }

            const observer = new ResizeObserver(throttle(entries => {
                bindings.value(entries[0].contentRect);
            }, 200));
            observer.observe(el);
        },
    });
}) as PluginFunction<any>;
