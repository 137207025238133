import request from '@/utils/request';

const baseUri = '/staff/timesheets';

export default {
    findAll (params) {
        return request.get(baseUri, { params });
    },

    setValue (payload) {
        return request.post(`${baseUri}/set`, payload);
    },
};
