import type { Route, RouteConfig } from 'vue-router/types/router';
import { findRouteRecursively, getFirstChildRoute } from '@/utils/route';
import { isFeatureEnabled } from '@/plugins/unleash';
import { useRouter } from 'vue-router/composables';
import { useStore } from '@/composable/useStore';

export const useRouterPermission = () => {
    const router = useRouter();
    const store = useStore();

    const hasRolesInBusiness = (businessSlug: string) => {
        return store.state.security.businessMemberships.some(bm => bm.businessSlug === businessSlug);
    };

    const hasRouteAccess = (route: Route | RouteConfig) => {
        // TODO
        if (typeof route.meta?.hasRouteAccess === 'function') {
            return route.meta?.hasRouteAccess();
        }

        // TODO как-то костыльно, мб переделать
        if (
            'params' in route &&
            route.params?.businessSlug &&
            !hasRolesInBusiness()
        ) {
            return false;
        }

        if (
            route.name === 'ProfileUsers' &&
            !store.getters.me?.adminRoleInBusinesses?.length
        ) {
            return false;
        }

        // Когда нужно проверить по свойству из /users/me (вне бизнеса)
        let permissionAttributes = [];
        if (route.meta?.perms && Array.isArray(route.meta.perms)) {
            permissionAttributes = route.meta.perms;
        }

        const businessId = store.getters['business/currentId'];

        let isDenied = false;
        isDenied = permissionAttributes.length > 0;
        for (const attribute of permissionAttributes) {
            if (businessId) {
                if (store.getters['security/hasAccess'](attribute, businessId)) {
                    isDenied = false;
                    break;
                }
            } else {
                for (const key of Object.keys(store.getters['security/permissions']())) {
                    if (store.getters['security/hasAccess'](attribute, key)) {
                        isDenied = false;
                        break;
                    }
                }
            }
        }

        if (router.currentRoute.name === 'BusinessDetail') {
            const routeWithChildren = findRouteRecursively(router.currentRoute, router.options.routes);
            const redirectRoute = getFirstChildRoute(routeWithChildren);
            if (!redirectRoute) {
                isDenied = true;
            }
        }

        return !isDenied;
    };

    const filterRoutes = (routes: RouteConfig[] = []): RouteConfig[] => {
        const result: RouteConfig[] = [];

        routes.forEach(route => {
            if (route?.meta?.feature && !isFeatureEnabled(route.meta.feature)) {
                return true;
            }

            const r = { ...route };
            const businessSettings = store.getters['business/current']?.settings ?? {};

            if (r.meta?.businessFlag && !businessSettings[r.meta.businessFlag]) {
                return true;
            }

            if (hasRouteAccess(r)) {
                if (r.children) {
                    r.children = filterRoutes(r.children);
                }
                result.push(r);
            }
        });

        return result;
    };

    return {
        hasRouteAccess,
        filterRoutes,
        hasRolesInBusiness,
    };
};
