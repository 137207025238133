<template>
    <div
        class="upload"
        @dragover="handleDragOver"
        @dragleave="handleDragLeave"
    >
        <div class="upload__block">
            <span v-if="title">{{ title }}</span>
            <el-upload
                ref="upload"
                action=""
                :file-list="value"
                :auto-upload="false"
                :show-file-list="false"
                :before-upload="handleBeforeUpload"
                :on-change="handleChange"
                :disabled="disabled"
                :drag="isDrag"
                v-bind="proxyProps"
                v-on="$listeners"
            >
                <el-button
                    v-if="!isDrag"
                    slot="trigger"
                    type="text"
                    size="mini"
                    :disabled="disabled"
                >
                    Добавить
                </el-button>
                <template v-else>
                    <iconify-icon
                        icon="akar-icons:cloud-upload"
                        class="upload__icon"
                    />
                    <div class="el-upload__text">
                        Добавить
                    </div>
                </template>
                <div
                    v-if="$slots['tip']"
                    slot="tip"
                    class="el-upload__tip"
                >
                    <slot name="tip" />
                </div>
            </el-upload>
        </div>
        <ul class="el-upload-list el-upload-list--text">
            <li
                v-for="(file, index) in value"
                :key="file.id"
                tabindex="0"
                class="el-upload-list__item is-success"
                @mousedown.prevent
            >
                <a
                    class="el-upload-list__item-name"
                    @mousedown.prevent="$emit('preview', file)"
                >
                    <iconify-icon icon="akar-icons:file" />
                    {{ file.name }}
                </a>
                <label class="el-upload-list__item-status-label">
                    <iconify-icon
                        v-if="file.status === 'success'"
                        icon="akar-icons:circle-check"
                        class="el-icon-upload-success"
                    />
                </label>
                <iconify-icon
                    v-if="!disabled"
                    icon="akar-icons:cross"
                    class="upload__remove"
                    @click.native="handleRemove(index)"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';

export default {
    name: 'UiUpload',
    mixins: [WrapperMixin],
    props: {
        value: {
            type: Array,
            required: true,
        },

        maxFileSize: {
            type: Number,
            default: 20 * 1024,
        },

        validFormats: {
            type: Array,
            default: () => [],
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        title: {
            type: String,
            default: '',
        },
    },

    data () {
        return {
            isDrag: false,
            canceled: false,
        };
    },

    methods: {
        handleBeforeUpload (file) {
            const isValidFormat = this.validFormats.length === 0 || this.validFormats.includes(file.type);
            const isValidFileSize = file.size / 1024 < this.maxFileSize;

            if (!isValidFormat) {
                this.$flashError(this.$t('message.invalidFileType'));
            } else if (!isValidFileSize) {
                this.$flashError(this.$t('message.maximumFileSizeExceeded'));
            }

            return isValidFormat && isValidFileSize;
        },

        handleRemove (index) {
            this.value.splice(index, 1);
        },

        handleChange (file, fileList) {
            this.isDrag = false;
            this.canceled = false;
            this.$emit('input', fileList);
        },

        handleDragOver () {
            this.isDrag = true;
            this.canceled = true;
        },

        handleDragLeave () {
            this.canceled = false;

            // Костыль, так как в стандартном компоненте при ховере иногда
            // проскакивает dragleave и тогда окошко драг-н-дропа резко закрывается
            setTimeout(() => {
                if (!this.canceled) {
                    this.isDrag = false;
                }
            }, 500);
        },
    },
};
</script>

<style lang="scss">
.upload {
    $b: &;

    .el-upload-dragger {
        #{$b}__icon {
            font-size: 67px;
            color: $--color-text-secondary;
            margin: 40px 0 16px;
            line-height: 50px;
        }
    }

    .el-upload {
        &__tip {
            line-height: 100%;
        }
    }

    .el-upload-list {
        &__item {
            transition: none;

            .upload__remove {
                display: none;
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: pointer;
                opacity: .75;
                color: #24292e;

                &:hover {
                    opacity: 1;
                }
            }

            &:hover .upload__remove {
                display: block;
            }
        }
    }

    &__block {
        display: inline-flex;
        align-items: baseline;

        > span {
            width: 210px;
        }
    }
}
</style>
