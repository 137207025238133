export default {
    inheritAttrs: false,

    computed: {
        /**
         * @returns {Object}
         */
        proxyProps () {
            return {
                // Для обратной совместимости
                ...this.getDefaultProps() ?? {},
                ...this.defaultProxyProps,
                ...this.$props ?? {},
                ...this.$attrs ?? {},
            };
        },

        /**
         * @returns {Object}
         */
        defaultProxyProps () {
            return {};
        },

        /**
         * @returns {Object}
         */
        proxyListeners () {
            return {
                ...this.defaultProxyListeners,
                ...this.$listeners,
            };
        },

        /**
         * @returns {Object}
         */
        defaultProxyListeners () {
            return {};
        },
    },

    methods: {
        /**
         * @deprecated use computed property: "defaultProxyProps"
         */
        getDefaultProps () {
            return {};
        },
    },
};
