import axios from 'axios';
import store from '@/store';

const service = axios.create({
    baseURL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs',
    timeout: 15000,
});

service.interceptors.request.use(
    config => {
        if (store.getters['dadata/hasToken']) {
            config.headers.Authorization = `Token ${store.state.dadata.token}`;
        }
        return config;
    },
    error => {
        console.error(error);
        return Promise.reject(error);
    },
);

export const getAddressByIp = (ip) => {
    const config = {};
    if (ip) {
        config.params = {
            ip,
        };
    }
    return service.get('/iplocate/address', config);
};

export const suggestAddress = (query, data = {}) => {
    const defaults = {
        query,
        count: 10,
    };

    return service.post('/suggest/address', { ...defaults, ...data });
};

export const suggestOrganization = (query, data = {}) => {
    const defaults = {
        query,
        count: 10,
    };

    return service.post('/suggest/party', { ...defaults, ...data });
};

export const suggestBank = (query, data = {}) => {
    const defaults = {
        query,
        count: 10,
    };

    return service.post('/suggest/bank', { ...defaults, ...data });
};

export const findById = (query, data = {}) => {
    const defaults = {
        query,
        count: 10,
    };


    return service.post('/findById/party', { ...defaults, ...data });
};

export const findBankById = (query) => {
    const defaults = {
        query,
    };

    return service.post('/findById/bank', defaults);
};

export default {
    getAddressByIp,
    suggestAddress,
    suggestOrganization,
    suggestBank,
    findById,
    findBankById,
};
