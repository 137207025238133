import request from '@/utils/request';

const baseUri = '/staff/payment_documents';

export const getDocuments = (params) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getDocument = (id, params) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createDocument = (data, params) =>
    request({
        url: baseUri,
        method: 'POST',
        data,
        params,
    });

export const updateDocument = (id, data, params) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
        params,
    });

export const deleteDocument = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });

export const calculateItems = (params) =>
    request({
        url: `${baseUri}/calculate_items`,
        method: 'GET',
        params,
    });

export const resyncByZup = (id) =>
    request({
        url: `${baseUri}/${id}/resync_by_zup`,
        method: 'POST',
    });
