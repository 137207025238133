<template>
    <div class="timeline-item-group">
        <div class="timeline-item-group__date">
            {{ formattedDate }}
        </div>

        <div class="timeline-item-group__items">
            <ui-timeline-item
                v-for="item in items"
                :key="item.id"
                :item="item"
            />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'UiTimelineItemGroup'
});
</script>

<script setup lang="ts">
import { computed } from 'vue';
import { useFormat } from '@/composable/useFormat';
import { useDayjs } from '@/composable/useDayjs';

const { formatDate } = useFormat();
const dayjs = useDayjs();

interface Props {
    date: string,
    items: object[]
}

const props = defineProps<Props>();

const formattedDate = computed(() => formatDate(props.date, dayjs().isSame(props.date, 'year')
    ? 'D MMMM'
    : 'D MMMM YYYY'
));
</script>

<style lang="scss">
.timeline-item-group {
    &__date {
        position: sticky;
        top: 0;
        border-radius: 16px;
        background-color: $--background-color-base;
        text-align: center;
        font-size: 12px;
        line-height: 12px;
        padding: 4px 8px;
        color: $--color-text-secondary;
        margin-bottom: 20px + 4px;
        margin-top: 4px;
        justify-self: center;
    }

    &__items {
        margin-top: -20px;
        display: flex;
        flex-direction: column-reverse;
    }
}
</style>
