import AssessmentCategoryAPI from '@/api/staff/assessmentCategory';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return AssessmentCategoryAPI.findAll(params);
    },
    doFetchSingle (id) {
        return AssessmentCategoryAPI.find(id);
    },
    doCreate (data) {
        return AssessmentCategoryAPI.create(data);
    },
    doUpdate (id, data) {
        return AssessmentCategoryAPI.update(id, data);
    },
    doDelete (id) {
        return AssessmentCategoryAPI.delete(id);
    },
});
