<template>
    <ui-timeline-item-changeset-table
        :display-field="displayField"
        :data-from="itemsFrom"
        :data-to="itemsTo"
        :headers="headers"
    />
</template>

<script lang="ts">
import FormatMixin from '@/mixins/format';
import Vue from 'vue';
import { AnalyticTypeProperties, EntityTypes } from '@/enums';
import TimelineChangeMixin from '@/mixins/timeline-changeset-items';
import { Header } from '@/components/ui/TimelineItemChangesetTable.vue';

export default Vue.extend({
    name: 'UiTimelineItemChangesetItems',
    mixins: [FormatMixin, TimelineChangeMixin],

    computed: {
        headers (): Partial<Header>[] {
            return [
                { title: 'Описание', key: 'description', forceShow: true, },
                { title: 'Статья учёта', key: this.entityType === EntityTypes.TRANSACTION ? 'transactionCategory.name' : 'category.name', forceShow: true, },
                ...this.analyticColumns,
                { title: 'Сумма', key: 'value', value: (v: any) => this.formatPrice(v.value ?? v.amount), forceShow: true, align: 'right' },
                { title: 'Период учёта', key: 'accountingPeriod', value: this.accountingTypeLabel, forceShow: true, },
            ];
        },

        itemsFrom () {
            return this.changeset.changes.flatMap(change => change.from);
        },

        itemsTo () {
            return this.changeset.changes.flatMap(change => change.to);
        },

        analyticColumns () {
            const columns: Header[] = [];

            this.changeset.changes.forEach((change: any) => {
                this.pushAnalyticColumns(change.from ?? [], columns);
                this.pushAnalyticColumns(change.to ?? [], columns);
            });

            return columns;
        },
    },

    methods: {
        pushAnalyticColumns (changes: any[], columns: Partial<Header>[]) {
            changes.forEach(row => {
                Object.keys(row)
                    .filter(key => row[key] && Object.values(AnalyticTypeProperties).includes(key as AnalyticTypeProperties))
                    .forEach(key => {
                        if (!columns.some(i => i.key === key)) {
                            columns.push({
                                key: key,
                                title: this.$t(`enum.analyticTypesProperties.${key}`) as string,
                                value: (v: any) => this.getAnalyticName(v, key as AnalyticTypeProperties),
                            });
                        }
                    });
            });
        },

        getAnalyticName (row: any, key: AnalyticTypeProperties) {
            const entity = row[key];
            const fieldConfig: { [key in AnalyticTypeProperties]: string } = {
                [AnalyticTypeProperties.ACTIVITY]: entity?.name,
                [AnalyticTypeProperties.BUSINESS_GROUP_EMPLOYEE]: entity?.name?.fullName,
                [AnalyticTypeProperties.CONTRACTOR]: entity?.name,
                [AnalyticTypeProperties.DEAL]: entity?.name,
                [AnalyticTypeProperties.DIVIDEND_ALLOCATION]: entity?.owner,
                [AnalyticTypeProperties.EMPLOYEE]: entity?.name?.fullName,
                [AnalyticTypeProperties.LOAN]: entity?.name,
            };

            return fieldConfig[key];
        },

        accountingTypeLabel (row: any) {
            if (row.accountingPeriod) {
                return this.formatDate(row.accountingPeriod, 'DD.MM.YYYY');
            }

            if (row.accountingType === null) {
                return this.$t('enum.accountingPeriodTypes.none');
            }

            if (!row.accountingType) {
                return '';
            }

            return this.$t(`enum.accountingPeriodTypes.${row.accountingType}`);
        },
    },
});
</script>
