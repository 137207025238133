<template>
    <div>
        <login-form />
    </div>
</template>

<script>
import LoginForm from '@/components/LoginForm';

export default {
    components: { LoginForm },
};
</script>
