import { createAnalyticEntityItem, deleteAnalyticEntityItem, getAnalyticEntityItem, getAnalyticEntityItems, updateAnalyticEntityItem } from '@/api/analyticEntityItem';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return getAnalyticEntityItems(params);
    },
    doFetchSingle (id: string) {
        return getAnalyticEntityItem(id);
    },
    doCreate (data: object) {
        return createAnalyticEntityItem(data);
    },
    doUpdate (id: string, data: object) {
        return updateAnalyticEntityItem(id, data);
    },
    doDelete (id: string) {
        return deleteAnalyticEntityItem(id);
    },
});
