import { createContractModification, deleteContractModification, getContractModification, getContractModifications, updateContractModification } from '@/api/contractAmendment';
import { createCrudModule } from '@/utils/vuex-crud';

const module = createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return getContractModifications(params);
    },
    doFetchSingle (id: string) {
        return getContractModification(id);
    },
    doCreate (data: object) {
        return createContractModification(data);
    },
    doUpdate (id: string, data: object) {
        return updateContractModification(id, data);
    },
    doDelete (id: string) {
        return deleteContractModification(id);
    }
});

export default module;
