<template>
    <ui-table-filter-select
        v-model="currentValue"
        v-bind="proxyProps"
        :fetch-items="fetchItems"
        :init-method="fetchInitItems"
        :multiple="multiple"
        :fetch-on-create="multiple ? currentValue.length > 0 : currentValue"
        v-on="$listeners"
    />
</template>

<script lang="ts">
import Vue from 'vue';
import WrapperMixin from "@/mixins/wrapper";
import BusinessAPI from "@/api/business";
import { Business } from "@/types/Business";

export default Vue.extend({
    name: 'UiTableFilterBusiness',
    mixins: [WrapperMixin],
    props: {
        value: {
            type: [String, Array],
            default: '',
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        fetchParams: {
            type: Object,
            default: null,
        },
    },

    computed: {
        currentValue: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },
    },

    methods: {
        async fetchItems (query: string, cb: (items: Business[]) => void) {
            const params = {
                ...this.fetchParams,
                pagination: 0
            };

            if (query) {
                params.name = query;
            }

            try {
                const data = await BusinessAPI.findAll(params);
                const items = data['hydra:member'] || [];

                cb(items);
            } catch (e) {
                this.$flashError(e as Error);
            }
        },

        async fetchInitItems (items: string[]) {
            const params = {
                id: items,
            };

            try {
                const data = await BusinessAPI.findAll(params);
                return data['hydra:member'] || [];
            } catch (e) {
                this.$flashError(e as Error);
                return [];
            }
        },
    },
});
</script>

