<template>
    <ui-masked-input
        v-model="currentValue"
        :mask="Number"
        :scale="2"
        :signed="false"
        radix=","
        thousands-separator=" "
        unmask="typed"
        class="signed-price-input"
        :class="{ 'is-negative': colored && negative, 'is-positive': colored && !negative }"
        pad-fractional-zeros
        :disabled="disabled"
    >
        <iconify-icon
            v-if="!unsigned"
            slot="prepend"
            :icon="negative ? 'akar-icons:minus' : 'akar-icons:plus'"
        />
        <template slot="append">
            ₽
        </template>
    </ui-masked-input>
</template>

<script>
export default {
    name: 'UiSignedPriceInput',

    props: {
        value: {
            type: Number,
            required: true,
        },

        negative: {
            type: Boolean,
            default: false,
        },

        colored: {
            type: Boolean,
            default: true,
        },

        unsigned: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>
