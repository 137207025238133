import * as api from '@/api/contractor/contractorType';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return api.getTypes(params);
    },
    doFetchSingle (id: string, params: object) {
        return api.getType(id, params);
    },
    doCreate (data: object) {
        return api.createType(data);
    },
    doUpdate (id: string, data: object) {
        return api.updateType(id, data);
    },
    doDelete (id: string) {
        return api.deleteType(id);
    },
});
