import { createVacation, getVacation, updateVacation } from '@/api/staff/personnelDocument';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchSingle (id, params) {
        return getVacation(id, params);
    },
    doCreate (data) {
        return createVacation(data);
    },
    doUpdate (id, data) {
        return updateVacation(id, data);
    },
});
