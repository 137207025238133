import * as api from '@/api/contractor/requisite';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return api.getRequisites(params);
    },
    doFetchSingle (id, params) {
        return api.getRequisite(id, params);
    },
    doCreate (data) {
        return api.createRequisite(data);
    },
    doUpdate (id, data) {
        return api.updateRequisite(id, data);
    },
    doDelete (id) {
        return api.deleteRequisite(id);
    },
});
