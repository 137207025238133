import * as api from '@/api/possessions/possessions/possessionDocument';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return api.getPossessionDocuments(params);
    },
    doDelete (id) {
        return api.deletePossessionDocument(id);
    },
});
