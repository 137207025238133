<template>
    <div
        class="business-balance-item"
    >
        <div class="business-balance-item__header">
            <div class="business-balance-item__name">
                {{ data.name }}
            </div>
            <div class="business-balance-item__money">
                <ui-money-value
                    :value="totalBalance"
                    :colorized="false"
                    :hide-plus="true"
                    :hide-zero="false"
                />
            </div>
        </div>
        <template v-if="data.children">
            <div
                v-for="item in data.children"
                :key="item.id"
                class="business-balance-item__row"
            >
                <div class="business-balance-item__name">
                    {{ item.name }}
                </div>
                <div
                    class="business-balance-item__money"
                >
                    <ui-money-value
                        :value="item.balance"
                        :hide-plus="true"
                        :hide-zero="false"
                        :colorized="false"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    computed: {
        totalBalance () {
            return parseFloat(this.data.children.reduce((accumulator, current) => {
                return accumulator + parseFloat(current.balance);
            }, 0).toFixed(2));
        },
    },
};
</script>
