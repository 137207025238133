import * as AccessRightAPI from '@/api/accessRight';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return AccessRightAPI.getAccessRights(params);
    },
    doFetchSingle (id) {
        return AccessRightAPI.getAccessRight(id);
    },
});
