<template>
    <div class="sidebar-menu">
        <template
            v-for="(items, groupTitle) in groupedItems"
        >
            <div
                v-if="groupTitle !== ''"
                :key="groupTitle"
                class="sidebar-menu__group"
            >
                <div class="sidebar-menu__group-title">
                    {{ groupTitle }}
                </div>
            </div>
            <template v-for="item in items">
                <router-link
                    :key="`link_${item.name}`"
                    v-slot="{ href, navigate, isActive }"
                    :to="resolvePath(item.name)"
                    custom
                >
                    <div
                        class="sidebar-menu__item"
                        :class="{ 'is-active': isActive, 'sidebar-menu__item--indent': item.meta && item.meta.menuSeparator && item.path !== 'contracts-internal' }"
                        @click="navigate"
                    >
                        <a
                            :href="href"
                            class="sidebar-menu__link"
                        >
                            <span class="sidebar-menu__text">
                                {{ item.meta.menuTitle ?? item.meta.title }}
                            </span>
                        </a>
                    </div>
                </router-link>

                <portal-target
                    :key="`portal_${item.name}`"
                    :name="`contextMenuBelow${item.name}`"
                />
            </template>
        </template>
        <template v-if="logoutButton">
            <div
                class="sidebar-menu__item u-mt-lg"
                @click="handleLogout"
            >
                <span class="sidebar-menu__link">
                    <iconify-icon
                        icon="akar-icons:sign-out"
                        class="sidebar-menu__icon"
                    />
                    <span class="sidebar-menu__text">
                        Выйти
                    </span>
                </span>
            </div>
            <div
                v-if="$store.getters['auth/isImpersonated']"
                class="sidebar-menu__item sidebar-menu__item--impersonate"
                title="Отменить подмену пользователя"
                @click="handleStopImpersonation"
            >
                <span class="sidebar-menu__link">
                    <iconify-icon
                        icon="mdi:user-off-outline"
                        class="sidebar-menu__icon"
                    />
                    <span class="sidebar-menu__text">
                        Отменить авторизацию
                    </span>
                </span>
            </div>
        </template>
    </div>
</template>

<script>
import { groupBy } from 'lodash';
import RoutePermissionMixin from '@/mixins/route-permission';
import { isFeatureEnabled } from '@/plugins/unleash';

export default {
    mixins: [RoutePermissionMixin],

    props: {
        logoutButton: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        groupedItems () {
            const { matched } = this.$route;
            const matchedRoute = matched[matched.length - 1];
            const searchRouteName = matchedRoute.meta?.navigationParent
                ? matchedRoute.meta.navigationParent
                : matchedRoute.parent.name;

            if (searchRouteName) {
                const foundRoute = this.findRoute(searchRouteName, this.$router.options.routes);
                if (foundRoute && foundRoute.children) {
                    const items = this.filterRoutes(foundRoute.children
                        .filter(route => route.meta?.title && !route.meta?.hidden))
                        .filter(route => !route.meta?.feature || isFeatureEnabled(route.meta.feature));

                    return groupBy(items, item => {
                        return item.meta?.group ? item.meta.group : '';
                    });
                }
            }

            return [];
        },
    },

    methods: {
        findRoute (searchName, routes) {
            let result;

            for (const route of routes) {
                if (route.name === searchName) {
                    result = route;
                    break;
                }

                if (route.children) {
                    const tmp = this.findRoute(searchName, route.children);
                    if (tmp) {
                        result = tmp;
                        break;
                    }
                }
            }

            return result;
        },

        resolvePath (name) {
            const resolved = this.$router.resolve({ name, params: this.$route.params });

            return resolved.href;
        },

        handleLogout () {
            this.$store.dispatch('auth/logout');
        },

        handleStopImpersonation() {
            this.$store.dispatch('auth/stopImpersonation');
        }
    },
};
</script>
