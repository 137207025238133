<template>
    <div
        v-if="data"
        class="deal-option"
    >
        <div class="deal-option__title">
            {{ data?.name }}
        </div>
        <div
            v-if="isDeal"
            class="deal-option__info"
        >
            <div>Контрагент: {{ data?.contractor?.name }}</div>
            <div>Сумма: {{ data?.value }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UiDealOption',

    props: {
        data: {
            type: [Object, String],
            default: null,
        },
    },

    computed: {
        isDeal () {
            return this.data?.['@type'] === 'Deal';
        },
    },
};
</script>

<style lang="scss">
.deal-option {
    &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__info {
        color: $--color-text-secondary;
        font-size: 12px;
        font-weight: normal;
        line-height: normal;
    }
}
</style>
