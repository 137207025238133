<template>
    <div
        :class="{ 'is-active-label': labelActive }"
        class="table-filter"
    >
        <div class="table-filter__slot">
            <el-popover
                ref="popover"
                placement="bottom-start"
                popper-class="interval-selector__popover"
                trigger="click"
                @show="focus=true"
                @hide="focus=false"
            >
                <div slot="reference">
                    <div
                        v-if="label"
                        class="table-filter__label"
                    >
                        <iconify-icon
                            slot="reference"
                            icon="fa6-solid:filter"
                            class="table-filter__icon"
                        />
                        {{ label }}
                    </div>
                    <div class="table-filter__tag-wrapper">
                        <el-tag
                            v-if="formattedValue"
                            closable
                            size="small"
                            disable-transitions
                            @close="handleTagClose"
                        >
                            {{ formattedValue }}
                        </el-tag>
                    </div>
                </div>
                <ui-date-picker
                    ref="datePicker"
                    v-model="currentValue"
                    :clearable="false"
                    :type="type"
                    @change="handleDatePickerChange"
                />
            </el-popover>
        </div>
    </div>
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';

export default {
    name: 'UiTableFilterIntervalSelector',

    mixins: [WrapperMixin],

    props: {
        value: {
            type: [String, Array],
            default: '',
        },

        label: {
            type: String,
            default: '',
        },

        width: {
            type: Number,
            default: 300,
        },

        type: {
            type: String,
            default: 'date',
        },
    },

    data () {
        return {
            focus: false,
        };
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (value) {
                this.$emit('input', value);
            },
        },

        labelActive () {
            if (
                (Array.isArray(this.currentValue) && this.currentValue.length === 2) ||
                (typeof this.currentValue === 'string' && this.currentValue !== '')
            ) {
                return true;
            }

            return this.focus;
        },

        formattedValue () {
            if (
                (Array.isArray(this.currentValue) && this.currentValue.length === 0) ||
                (typeof this.currentValue === 'string' && this.currentValue === '')
            ) {
                return '';
            }

            if (this.type.endsWith('range')) {
                const startDate = this.$dayjs(this.currentValue[0]);
                const endDate = this.$dayjs(this.currentValue[1]);

                if (this.type === 'monthrange' && startDate.isSame(endDate, 'month')) {
                    return startDate.format('MMMM YYYY');
                }

                if (startDate.isSame(endDate, 'day')) {
                    return startDate.format('D MMMM YYYY');
                }

                if (startDate.year() === endDate.year()) {
                    return startDate.format('D MMMM') + ' - ' + endDate.format('D MMMM YYYY');
                }

                return startDate.format('D MMMM YYYY') + ' - ' + endDate.format('D MMMM YYYY');
            } else if (this.type === 'month') {
                const startDate = this.$dayjs(this.currentValue);
                const value = startDate.isSame(new Date(), 'year') ? startDate.format('MMMM') : startDate.format('MMMM YYYY');
                return value[0].toUpperCase() + value.substring(1);
            } else {
                const startDate = this.$dayjs(this.currentValue);
                return startDate.format('DD MMMM YYYY');
            }
        },
    },

    watch: {
        value () {
            this.$refs.datePicker.$refs.datePicker.blur();
        },
    },

    methods: {
        handleDatePickerChange () {
            this.$refs.popover.doClose();
            this.$emit('change', this.currentValue);
        },

        handleTagClose () {
            if (Array.isArray(this.currentValue)) {
                this.$emit('input', []);
                this.$emit('change', []);
            } else if (typeof this.currentValue === 'string') {
                this.$emit('input', '');
                this.$emit('change', '');
            }
        },
    },
};
</script>
