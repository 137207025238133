<template>
    <el-dropdown
        ref="dropdownSelect"
        v-bind="proxyProps"
        :disabled="disabled"
        class="dropdown-select"
        trigger="click"
        @command="handleCommand"
    >
        <slot>
            <el-button
                v-if="!multiple"
                type="text"
                :disabled="disabled"
                :size="buttonSize"
                class="dropdown-select__button"
            >
                {{ selectedItem ? selectedItem[labelKey] : value }}
            </el-button>
        </slot>
        <el-dropdown-menu
            slot="dropdown"
            class="dropdown-select__menu"
        >
            <el-dropdown-item
                v-for="item in items"
                :key="item.id"
                :command="item[valueKey]"
                :class="setItemClass(item)"
            >
                <iconify-icon
                    v-if="multiple && isItemSelected(item)"
                    icon="akar-icons:check"
                    class="dropdown-select__checkmark"
                />
                {{ item[labelKey] }}
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';

export default {
    name: 'UiDropdownSelect',
    mixins: [WrapperMixin],
    props: {
        value: {
            type: [String, Array],
            default: ''
        },

        labelKey: {
            type: String,
            default: 'name',
        },

        valueKey: {
            type: String,
            default: 'value',
        },

        items: {
            type: Array,
            default: () => [],
        },

        buttonSize: {
            type: String,
            default: 'small',
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        multiple: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        selectedItem () {
            if (this.multiple) {
                return this.items.filter(i => this.value.includes(i[this.valueKey]));
            }

            return this.items.find(item => item[this.valueKey] === this.value) || null;
        },
    },

    methods: {
        isItemSelected (item) {
            return !this.multiple && item[this.valueKey] === this.value ||
                this.multiple && this.value.includes(item[this.valueKey]);
        },

        handleCommand (command) {
            if (this.multiple) {
                const values = [...this.value];
                const index = values.indexOf(command);

                if (index >= 0) {
                    values.splice(index, 1);
                } else {
                    values.push(command);
                }

                this.$emit('input', values);
            } else {
                this.$emit('input', command);
            }
            this.$emit('change', this.selectedItem);
        },

        setItemClass (item) {
            const classes = [];

            if (this.isItemSelected(item)) {
                classes.push('selected');
            }

            if (this.multiple) {
                classes.push('with-checkmark');
            }

            return classes;
        },
    },
};
</script>

<style lang="scss">
.dropdown-select {

    &__button {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    &__menu.el-dropdown-menu {
        .el-dropdown-menu__item {
            position: relative;

            &.selected {
                color: $--color-primary;
                font-weight: bold;
            }

            &.with-checkmark {
                padding-left: 18px + 14px;
            }
        }
    }

    &__checkmark {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
    }
}
</style>
