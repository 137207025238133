import store from '@/store';
import router from '@/router';
import { isFeatureEnabled } from '@/plugins/unleash';

export const findFirstAvailableChildRoute = (parentName, node = router.options.routes) => {
    const parentRoute = node.find(route => route.name === parentName);

    if (!parentRoute || !parentRoute.children) {
        return null;
    }

    let permissionAttributes = [];
    const routeWithPerms = parentRoute.children.find(route => {
        if (route.meta?.perms && Array.isArray(route.meta.perms)) {
            permissionAttributes = route.meta.perms;
        }

        const businessId = store.getters['business/currentId'];

        return (
            permissionAttributes.length === 0 ||
            permissionAttributes.some(attribute => store.getters['security/hasAccess'](attribute, businessId))
        );
    });

    return routeWithPerms || null;
};


export const getFirstChildRoute = (route) => {
    return (route.children ?? [])
        .filter(r => !r.meta?.hidden)
        .filter(r => !r.meta?.feature || isFeatureEnabled(r.meta?.feature))
        .find(r => {
            let permissionAttributes = [];
            if (r.meta?.perms) {
                if (Array.isArray(r.meta.perms)) {
                    permissionAttributes = r.meta.perms;
                } else if (r.meta.perms.attrs) {
                    permissionAttributes = r.meta.perms.attrs;
                }
            }

            const businessId = store.getters['business/currentId'];

            return (
                permissionAttributes.length === 0 ||
                permissionAttributes.some(attribute => store.getters['security/hasAccess'](attribute, businessId))
            );
        });
};

export const getRedirectPath = (from, route) => {
    let path = from.path.replace(/[/?]$/, '') + '/' + route.path;

    if (Object.keys(from.query).length > 0) {
        path += '?' + new URLSearchParams(from.query).toString();
    }
    if (from.hash) {
        path += '#' + from;
    }

    return path;
};

export const findRouteRecursively = (to, children) => {
    for (const route of children) {
        if (route.name === to.name) {
            return route;
        } else if (route.children) {
            const result = findRouteRecursively(to, route.children);
            if (result) {
                return result;
            }
        }
    }
};

/**
 * @param {import("vue-router").Route} route
 * @returns {string|undefined}
 */
export const getRouteTitle = (route) => {
    for (const r of [...route.matched].reverse()) {
        if (r.meta?.title) {
            return r.meta.title;
        }
    }
};
