import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { CancellationDocument } from '@/types/CancellationDocument';

const baseUri = '/possessions/cancellation_documents';

export const getCancellationDocuments = (params: object):  Promise<PagedCollection<CancellationDocument>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getCancellationDocument = (id: string, params: object): Promise<CancellationDocument> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });
export const createCancellationDocument = (data: object): Promise<CancellationDocument> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });

export const deleteCancellationDocument = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });

