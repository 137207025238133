export default {
    methods: {
        formatDate (date, format = 'DD.MM.YYYY HH:mm:ss', utc = false) {
            if (!date) {
                return '';
            }
            let result = utc ? this.$dayjs.utc(date).format(format) : this.$dayjs(date).format(format);
            if (format.indexOf('MMMM') === 0) {
                result = result[0].toUpperCase() + result.substring(1);
            }
            return result;
        },

        formatUtcDate (date, format = 'DD.MM.YYYY HH:mm:ss') {
            return this.formatDate(date, format, true);
        },

        formatNumber (value, decimals = 2, fluent = false) {
            return Number(value ?? 0).toLocaleString('ru', {
                minimumFractionDigits: fluent ? 0 : decimals,
                maximumFractionDigits: decimals,
            });
        },

        formatPrice (value) {
            return Number(value ?? 0).toLocaleString('ru', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },

        formatPhoneNumber (value) {
            const cleaned = String(value).replace(/\D/g, '');
            const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
            if (match) {
                const intlCode = (match[1] ? '+7 ' : '');
                return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
            }

            return '';
        },

        formatFileSize (size) {
            const i = Math.floor(Math.log(size) / Math.log(1024));
            return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
        },
    },
};
