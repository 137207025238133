<template>
    <div class="lightweight-table">
        <table
            v-loading="loading"
            class="lightweight-table__main"
            :style="{ minWidth: `${minWidth}px` }"
        >
            <colgroup>
                <col
                    v-for="column in columns"
                    :key="column.property"
                    :width="column.width"
                    style="min-width: 80px"
                >
            </colgroup>
            <tr class="lightweight-table__header-row">
                <th
                    v-for="column in columns"
                    :key="column.property"
                    :class="['lightweight-table__cell', `is-${column.align ?? 'left'}`]"
                >
                    <div class="cell">
                        {{ column.label }}
                    </div>
                </th>
            </tr>
            <tr
                v-for="row in tableData"
                :key="row.id"
                :class="['lightweight-table__row', `lightweight-table__row--level-${row.level}`, rowClassName({ row })]"
            >
                <td
                    v-for="column in columns"
                    :key="`${column.property}_${row.id}`"
                    :class="['lightweight-table__cell', `is-${column.align ?? 'left'}`, cellClassName({ column })]"
                >
                    <div class="cell">
                        <slot
                            :name="`row.${column.property}`"
                            :row="row"
                        />
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'UiLightweightTable'
});
</script>

<script setup lang="ts">
import { computed } from 'vue';
import { sumBy } from 'lodash';

interface Column {
    property: string,
    label: string,
    width: number,
    align?: string
}

interface Row {
    id: string,
    [key: string]: any,
    children?: Row[]
}

interface Props {
    columns: Column[],
    data: Row[],
    rowClassName?: ({ row }: { row: Row }) => string,
    cellClassName?: ({ column }: { column: Column }) => string,
    loading: boolean
}

const props = withDefaults(defineProps<Props>(), {
    loading: false,
    columns: () => [],
    data: () => [],
    rowClassName: () => '',
    cellClassName: () => '',
});

const tableData = computed(() => {
    const flatTree = (item: Row, level: number) => {
        const items = [{ ...item, level }];
        if (item.children) {
            item.children.forEach(i => {
                items.push(...flatTree(i, level + 1));
            });
        }

        return items;
    };

    return props.data.flatMap(i => flatTree(i, 0));
});

const minWidth = computed(() => sumBy(props.columns, 'width'));
</script>

<style lang="scss">
.lightweight-table {
    $b: &;
    overflow: auto;

    &__main {
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;
    }

    tr {
        background-color: #fff;

        &:hover {
            #{$b}__cell {
                background-color: #f8f9fc;
            }
        }
    }

    &__header-row {
        position: sticky;
        top: -20px;
        z-index: 10;

        th {
            border-bottom: 1px solid #ebeef5;
            color: #9daab6;
            text-align: left;

            .cell {
                box-sizing: border-box;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                line-height: 23px;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }

    &__cell {
        transition: background-color 0.3s;
        min-width: 0;
        box-sizing: border-box;
        text-overflow: ellipsis;
        vertical-align: middle;
        position: relative;
    }

    .cell {
        text-overflow: ellipsis;
        white-space: normal;
    }

    td.is-right .cell,
    th.is-right .cell {
        padding-right: 20px;
        text-align: right;
    }

    &__row {
        &.is-group #{$b}__cell {
            padding-top: 7px;
            padding-bottom: 7px;
            cursor: default;
            color: $--color-primary;
            border-bottom: 2px solid $--color-primary-light-7 !important;
            background: $--color-primary-light-9 !important;
        }

        @for $i from 0 through 5 {
            &--level-#{$i} {
                #{$b}__cell:first-child .cell {
                    padding-left: 16px * $i + 28px !important;
                }

                @if $i > 0 {
                    .cell {
                        font-size: 12px;
                        position: relative;
                        overflow: visible;
                        padding: 7px 10px;
                    }
                }
            }
        }
    }
}
</style>
