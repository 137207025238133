<template>
    <div
        :class="{ 'is-active-label': labelActive }"
        class="table-filter"
    >
        <div class="table-filter__slot">
            <el-popover
                ref="popover"
                placement="bottom-start"
                trigger="click"
                :width="width"
                @show="focus=true"
                @hide="focus=false"
            >
                <div slot="reference">
                    <div
                        v-if="label"
                        class="table-filter__label"
                    >
                        <iconify-icon
                            slot="reference"
                            icon="fa6-solid:filter"
                            class="table-filter__icon"
                        />
                        {{ label }}
                    </div>
                    <div class="table-filter__tag-wrapper" />
                </div>

                <div class="table-filter__stub">
                    <slot />
                </div>
            </el-popover>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UiTableFilterStub',

    props: {
        label: {
            type: String,
            default: '',
        },

        width: {
            type: Number,
            default: 300,
        },
    },

    data () {
        return {
            focus: false,
        };
    },

    computed: {
        labelActive () {
            return this.focus;
        },
    },
};
</script>
