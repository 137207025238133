<template>
    <div class="birthday-input">
        <el-input-number
            v-model="valueParts.day"
            :min="1"
            :max="currentDate ? currentDate.daysInMonth() : 31"
            :controls="false"
            class="birthday-input__day"
        />
        <el-select
            v-model="valueParts.month"
            class="birthday-input__month"
        >
            <el-option
                v-for="(monthName, index) in months"
                :key="index"
                :value="index + 1"
                :label="monthName"
            />
        </el-select>
        <el-input-number
            v-model="valueParts.year"
            :min="1900"
            :max="new Date().getFullYear()"
            :controls="false"
            class="birthday-input__year"
        />
    </div>
</template>

<script>
export default {
    name: 'UiBirthdayInput',

    props: {
        value: {
            type: String,
            default: null,
        },
    },

    data () {
        return {
            valueParts: {
                day: undefined,
                month: undefined,
                year: undefined,
            },

            months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        };
    },

    computed: {
        currentDate () {
            const {
                day,
                month,
                year,
            } = this.valueParts;
            if (day && month && year) {
                return this.$dayjs(new Date(year, month - 1, day));
            }
            return null;
        },
    },

    watch: {
        value: {
            handler () {
                if (this.value) {
                    const date = this.$dayjs(this.value, 'YYYY-MM-DD');
                    if (date.isValid()) {
                        this.valueParts.year = date.year();
                        this.valueParts.month = date.month() + 1;
                        this.valueParts.day = date.date();
                    }
                }
            },

            immediate: true,
        },

        valueParts: {
            handler () {
                if (this.currentDate) {
                    this.$emit('input', this.currentDate.format('YYYY-MM-DD'));
                } else {
                    this.$emit('input', undefined);
                }
            },

            deep: true,
        },
    },
};
</script>
