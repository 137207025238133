import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { CompareTransactionsOutput, Loan } from '@/types/Loan';

const baseUri = '/loans';

export const getLoans = (params: object): Promise<PagedCollection<Loan>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getLoan = (id: string, params: object): Promise<Loan> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createLoan = (data: object): Promise<Loan> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });

export const deleteLoan = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });

export const updateLoan = (id: string, data: object): Promise<Loan> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });

export const changeStatusLoan = (id: string, params: object) =>
    request({
        url: `${baseUri}/${id}/change_status`,
        method: 'POST',
        params,
    });

export const compareTransactions = (id: string, compareLoanId: string): Promise<CompareTransactionsOutput> =>
    request({
        url: `${baseUri}/${id}/compare_transactions/${compareLoanId}`,
        method: 'GET',
    });
