import request from '@/utils/request';

const baseUri = '/possessions/movement_fixed_assets_documents';

export const createMovementFixedAsset = (data) =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });
export const getMovementFixedAsset = (id, params) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });
export const editMovementFixedAsset = (id, data) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });
