import request from '@/utils/request';

export const baseUri = '/business_requisites';

export default {
    find (id) {
        return request.get(`${baseUri}/${id}`);
    },

    findAll (params) {
        return request.get(baseUri, {
            params: {
                isThirdPerson: false,
                ...params,
            },
        });
    },

    create (payload) {
        return request.post(baseUri, payload);
    },

    update (id, payload) {
        return request.patch(`${baseUri}/${id}`, payload);
    },

    delete (id) {
        return request.delete(`${baseUri}/${id}`);
    },
};
