import type { Module } from 'vuex';

export default {
    namespaced: true,
    state: {
        locationEntity: null
    },
    mutations: {
        SET_LOCATION (state, data) {
            state.locationEntity = data;
        }
    },
    actions: {
        setLocationEntity ({ commit }, data) {
            commit('SET_LOCATION', data);
        }
    }
} as Module<any, any>;
