import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { FundingClaimPaymentStage } from '@/types/FundingClaimPaymentStage';

const baseUri = '/funding_claim_payment_stages';

export const getStages = (params?: object): Promise<PagedCollection<FundingClaimPaymentStage>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getStage = (id: string, params?: object): Promise<FundingClaimPaymentStage> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createStage = (data: object): Promise<FundingClaimPaymentStage> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });

export const updateStage = (id: string, data: object, params?: object): Promise<FundingClaimPaymentStage> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
        params
    });

export const deleteStage = (id: string): Promise<null> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });
