import { getBusinessGroups, getPermissions, GetPermissionsResponse } from '@/api/businessGroup';
import { createCrudModule } from '@/utils/vuex-crud';
import { BusinessGroupPermission } from '@/types/BusinessGroup';
import { Module } from 'vuex';

interface State {
    permissions: BusinessGroupPermission[];
}

const module: Module<State, any> = createCrudModule({
    businessDependent: false,
    only: ['FETCH_LIST'],
    doFetchList (params: any) {
        return getBusinessGroups(params);
    },
});

Object.assign(module.state, {
    permissions: [] as BusinessGroupPermission[],
});

Object.assign(module.mutations, {
    SET_PERMISSIONS (state: State, permissions: BusinessGroupPermission[]) {
        state.permissions = permissions;
    },
});

Object.assign(module.actions, {
    async fetchPermissions ({ commit }, { businessGroupId }: { businessGroupId: string }): GetPermissionsResponse | null {
        try {
            const response = await getPermissions({ businessGroupId });
            commit('SET_PERMISSIONS', response.permissions);
            return response;
        } catch (error) {
            commit('SET_PERMISSIONS', []);
            return null;
        }
    },
});

export default module;
