import * as AccessCategoryAPI from '@/api/accessCategory';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: false,
    doFetchList (params) {
        return AccessCategoryAPI.getAccessCategories(params);
    },
    doFetchSingle (id) {
        return AccessCategoryAPI.getAccessCategory(id);
    },
});
