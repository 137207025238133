import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { ContractorService } from '@/types/ContractorService';

const baseUri = '/contractor_services';

export const getServices = (params: object): Promise<PagedCollection<ContractorService>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getService = (id: string, params: object): Promise<ContractorService> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createService = (data: object, params: object): Promise<ContractorService> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
        params,
    });

export const updateService = (id: string, data: object, params: object): Promise<ContractorService> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
        params,
    });

export const deleteService = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });
