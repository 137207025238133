<template>
    <div class="timeline-item-changeset-table">
        <div class="timeline-item-changeset-table__field">
            {{ displayField }}
        </div>

        <div class="timeline-item-changeset-table__wrapper">
            <table class="timeline-item-changeset-table__inner">
                <tr>
                    <th
                        v-for="column in headersFormatted"
                        :key="column.key"
                    >
                        <div :class="['cell', `u-text-${column.align}`]">
                            {{ column.title }}
                        </div>
                    </th>
                </tr>
                <tr>
                    <td colspan="99">
                        <div class="cell -is-action -is-secondary">
                            Было
                        </div>
                    </td>
                </tr>
                <tr
                    v-for="row in dataFrom"
                    :key="`from_${row.id}`"
                >
                    <td
                        v-for="column in headersFormatted"
                        :key="column.key"
                        :class="[`u-text-${column.align}`, cellClassName(row, column, true)]"
                    >
                        <div
                            class="cell"
                            :title="column.value(row)"
                        >
                            {{ column.value(row) }}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="99">
                        <div class="cell -is-action -is-secondary">
                            Стало
                        </div>
                    </td>
                </tr>
                <tr
                    v-for="row in dataTo"
                    :key="`to_${row.id}`"
                >
                    <td
                        v-for="column in headersFormatted"
                        :key="column.key"
                        :class="[`u-text-${column.align}`, cellClassName(row, column)]"
                    >
                        <div
                            class="cell"
                            :title="column.value(row)"
                        >
                            {{ column.value(row) }}
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export interface Header {
    title: string,
    align: 'left' | 'center' | 'right',
    key: string,
    value: (v: any) => any,
    forceShow: boolean,
}

export default Vue.extend({
    name: 'UiTimelineItemChangesetTable',
});
</script>

<script setup lang="ts">
import { get, isEqual } from 'lodash';
import classNames from "classnames";
import { computed } from "vue";

interface Props {
    displayField: string,
    headers?: Header[],
    dataFrom: any[],
    dataTo: any[],
}

const props = withDefaults(defineProps<Props>(), {
    headers: () => [],
    displayField: '',
});

const headersFormatted = computed<Header[]>(() => {
    const cols = props.headers.map(column => Object.assign({
        title: '',
        align: 'left',
        value: v => get(v, column.key),
        key: '',
        forceShow: false,
    } as Header, column));

    return cols.filter(col =>
        col.forceShow ||
        props.dataFrom.some(i => props.dataTo.find(compareItem => compareItem.id === i.id) && isValueChanged(i, props.dataTo, col)) ||
        props.dataTo.some(i => props.dataFrom.find(compareItem => compareItem.id === i.id) && isValueChanged(i, props.dataFrom, col))
    );
});

function cellClassName (row: any, column: Header, isFrom = false) {
    return classNames({
        '-value-changed': isValueChanged(row, isFrom ? props.dataTo : props.dataFrom, column),
        '-is-before': isFrom,
    });
}

function isValueChanged (row: any, items: any[], column: Header) {
    const compareRow = items.find(i => i.id === row.id);

    if (!compareRow) {
        return true;
    }

    return !isEqual(column.value(row), column.value(compareRow));
}
</script>

<style lang="scss">
.timeline-item-changeset-table {
    font-size: 11px;
    line-height: normal;

    &:not(:first-child), & + .timeline-item-changeset {
        margin-top: 8px;
    }

    &__field {
        font-weight: bold;
        white-space: nowrap;
        color: $--color-text-gray;
        margin-bottom: 2px;
    }

    &__wrapper {
        overflow: auto;
    }

    &__inner {
        border-collapse: collapse;
        min-width: 100%;

        .cell {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &, th, td {
            border: 1px solid $--table-border-color;
        }

        td, th {
            padding: 4px;
            font-size: 12px;
            max-width: 150px;
        }

        th {
            color: $--color-text-secondary;
        }

        td.-value-changed {
            color: $--color-primary-light-3;

            &.-is-before {
                color: $--color-text-secondary;
            }
        }

        .-value {
            text-align: right;
        }

        .-is-action {
            font-weight: bold;
        }

        .-is-secondary {
            color: $--color-text-secondary;
        }
    }
}
</style>
