<template>
    <el-select
        ref="select"
        v-model="currentValue"
        remote
        v-bind="proxyProps"
        :filter-method="filterSelect"
        :title="title"
        @change="handleChange"
        @visible-change="handleVisibleChange"
        v-on="$listeners"
    >
        <el-option
            v-if="data.length === 0 && currentLabel"
            :value="value"
            :label="currentLabel"
        />
        <template v-for="item in reorderedData">
            <el-option
                v-if="hideCondition(item)"
                :key="item.id"
                :disabled="disableCondition(item)"
                :label="item.name"
                :value="item.id"
                :class="[optionClassMethod(item), { 'is-group': item?.[groupKey] }]"
                :style="setStyle(item)"
            />
        </template>
    </el-select>
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';
import { reorderNested } from '@/utils';
import SelectNestedMixin from '@/mixins/select-nested';
import SelectMixin from '@/mixins/select';

export default {
    name: 'UiSelectNested',

    mixins: [
        WrapperMixin,
        SelectNestedMixin,
        SelectMixin,
    ],

    props: {
        value: {
            type: [String, Array],
            default: '',
        },

        data: {
            type: Array,
            required: true,
        },

        disableParents: {
            type: Boolean,
            default: false,
        },

        hideImmutable: {
            type: Boolean,
            default: false,
        },

        isTransactionCategory: {
            type: Boolean,
            default: false,
        },

        showChildrenWhenFilter: {
            type: Boolean,
            default: false,
        },

        showParentsWhenFilter: {
            type: Boolean,
            default: false,
        },

        optionClassMethod: {
            type: Function,
            default: () => null,
        },

        disabledItemMethod: {
            type: Function,
            default: () => false,
        },

        filterMethod: {
            type: Function,
            default: null,
        },

        persistentItem: {
            type: Object,
            default: null,
        },

        groupKey: {
            type: String,
            default: 'isGroup',
        },
    },

    data () {
        return {
            searchQuery: '',
        };
    },

    computed: {
        reorderedData () {
            const data = [];
            data.push(...this.beforeItems, ...reorderNested(this.data, this.sortByField));

            if (this.persistentItem && !this.data.some(item => item?.id === this.persistentItem?.id)) {
                data.push(this.persistentItem);
            }

            if (this.filterMethod) {
                return this.filterMethod(this.searchQuery, data);
            }

            if (this.showChildrenWhenFilter && this.showParentsWhenFilter) {
                return data.filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()) || this.hasParentWithSameName(item, data) || this.isParent(item, data, this.searchQuery));
            }

            if (this.showChildrenWhenFilter) {
                return data.filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()) || this.hasParentWithSameName(item, data));
            }

            if (this.showParentsWhenFilter) {
                return data.filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()) || this.isParent(item, data, this.searchQuery));
            }

            return data.filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
        },

        parents () {
            return this.data.reduce((newArr, currentItem) => {
                if (currentItem.parent && !newArr.find(item => item.id === currentItem.parent?.id)) {
                    newArr.push(currentItem.parent);
                }
                return newArr;
            }, []);
        },
    },

    methods: {
        hideCondition (item) {
            if (this.hideImmutable) {
                return !item.immutable;
            }
            return true;
        },

        disableCondition (item) {
            let disabled = false;

            if (this.disableParents && !!this.parents.find(obj => obj.id === item.id)) {
                disabled = true;
            }
            if (this.isTransactionCategory && !(item.choosable && (item.outcomeChoosable || item.incomeChoosable))) {
                disabled = true;
            }
            if (item.disabled || this.disabledItemMethod(item)) {
                disabled = true;
            }

            return disabled;
        },

        handleChange (value) {
            const selectedItem = this.reorderedData.find(i => i.id === value);
            this.$emit('select', selectedItem);
            this.$emit('change', value);
        },

        hasParentWithSameName (item, items) {
            if (items.find(searchItem => item?.parent?.id === searchItem.id)?.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                return true;
            } else if (item?.parent?.id) {
                return this.hasParentWithSameName(items.find(searchItem => item.parent.id === searchItem.id), items);
            } else {
                return item.disabled;
            }
        },

        filterSelect (query) {
            this.searchQuery = query;
        },

        handleVisibleChange (isAppears) {
            if (!isAppears) {
                setTimeout(() => { // чтобы не дергалась выпадашка при блюре
                    this.searchQuery = '';
                }, 100);
            }
        },
    },
};
</script>
