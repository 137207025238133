<template>
    <div class="list">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'UiList',
    provide () {
        return {
            uiList: this,
        };
    },
    props: {
        labelWidth: {
            type: String,
            default: '180px',
        },
        dashForEmpty: {
            type: Boolean,
            default: false
        }
    },
};
</script>
