<template>
    <div class="chat-message-group">
        <div class="chat-message-group__date-wrapper">
            <div class="chat-message-group__date">
                {{ formattedDate }}
            </div>
        </div>

        <div
            v-for="message in messages"
            :key="message.id"
            class="chat-message-group__item"
            :class="{ '--is-new': isNew(message) }"
        >
            <ui-chat-message
                :date="message.createdAt"
                :message="message.message"
                @visibility-change="handleVisibilityChange($event, message)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'UiChatMessageGroup'
});
</script>

<script setup lang="ts">
import { useFormat } from '@/composable/useFormat';
import { computed } from 'vue';
import { useDayjs } from '@/composable/useDayjs';
import { Notification } from '@/types/Notification';

const { formatDate } = useFormat();
const dayjs = useDayjs();

const emit = defineEmits<{
    (e: 'visibility-change', { visible, message }: { visible: boolean, message: Notification }): void
}>();

interface Props {
    messages: Notification[],
    date: string
}

const props = defineProps<Props>();

const formattedDate = computed(() => formatDate(props.date, dayjs().isSame(props.date, 'year')
    ? 'D MMMM'
    : 'D MMMM YYYY'
));

function handleVisibilityChange (visible: boolean, message: Notification) {
    emit('visibility-change', { visible, message });
}

function isNew (message: Notification) {
    return !message.readAt;
}
</script>

<style lang="scss">
.chat-message-group {
    position: relative;
    display: flex;
    flex-direction: column;

    &__date-wrapper {
        position: sticky;
        top: 0;
        padding: 8px 0;
        margin: 0 auto;
        z-index: 2;
    }

    &__date {
        border-radius: 16px;
        background-color: $--color-primary-light-9;
        font-size: 11px;
        line-height: 12px;
        padding: 4px 8px;
        box-shadow: $--box-shadow-dark;
    }

    &__item {
        display: flex;
        padding: 4px 16px;
        transition: 0.2s;

        &.--is-new {
            background-color: $--background-color-base;
        }
    }
}
</style>
