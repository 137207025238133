import type { PluginFunction } from 'vue';
import Vue from 'vue';
import { useFlash } from '@/composable/useFlash';

const { flashSuccess, flashWarning, flashError } = useFlash();

declare module 'vue/types/vue' {
    interface Vue {
        readonly $flashSuccess: (message: string, durationIsSeconds?: number, isHtml?: boolean) => void;
        readonly $flashError: (error: string | Error, durationIsSeconds?: number, isHtml?: boolean) => void;
        readonly $flashWarning: (error: string, durationIsSeconds?: number, isHtml?: boolean) => void;
    }
}

export default ((app: typeof Vue): void => {
    if (app.prototype.$flashSuccess) {
        return;
    }

    Object.defineProperties(app.prototype, {
        $flashSuccess: {
            get: () => flashSuccess,
        },
        $flashError: {
            get: () => flashError,
        },
        $flashWarning: {
            get: () => flashWarning,
        },
    });
}) as PluginFunction<any>;
