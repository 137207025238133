<template>
    <div class="hashtag">
        <el-tag
            v-for="(item, i) in uniqueTags"
            :key="i"
            :size="size"
            class="hashtag__item"
        >
            <span
                :class="{ 'is-clipped': item !== '' }"
                :title="`#${item}`"
            >
                #{{ item }}
            </span>
        </el-tag>
    </div>
</template>

<script>
import { uniq } from 'lodash';

export default {
    name: 'UiHashTag',

    props: {
        tags: {
            type: Array,
            required: true,
        },
        size: {
            type: String,
            default: 'small',
        },
    },

    computed: {
        uniqueTags () {
            return uniq(this.tags.filter(t => t));
        },
    },
};
</script>

<style lang="scss">
.hashtag {
    &__item {
        display: inline-flex;
        align-items: center;
        border-radius: 14px;
        background-color: $--border-color-extra-light;
        border-color: $--border-color-light;
        color: $--color-text-primary;
        margin-right: 5px;

        .is-clipped {
            max-width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>
