import { createCrudModule } from '@/utils/vuex-crud';
import { getRow, getRows } from '@/api/staff/salaryReport';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params?: object) {
        return getRows(params);
    },
    doFetchSingle (id: string, params?: object) {
        return getRow(id, params);
    },
});
