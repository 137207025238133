import * as PaymentStageAPI from '@/api/planning/fundingClaimPaymentStage';
import { createCrudModule } from '@/utils/vuex-crud';
import Vue from 'vue';

const module = createCrudModule({
    businessDependent: true,
    doFetchList (params?: object) {
        return PaymentStageAPI.getStages(params);
    },
    doFetchSingle (id: string, params?: object) {
        return PaymentStageAPI.getStage(id, params);
    },
    doCreate (data: object) {
        return PaymentStageAPI.createStage(data);
    },
    doUpdate (id: string, data: object, params) {
        return PaymentStageAPI.updateStage(id, data, params);
    },
    doDelete (id: string) {
        return PaymentStageAPI.deleteStage(id);
    },
});

Object.assign(module.mutations, {
    RESET_STAGE_RULES (state, id) {
        Vue.set(state.entities[id], 'rules', []);
    }
});

Object.assign(module.actions, {
    resetStageRules ({ commit }, { id }) {
        commit('RESET_STAGE_RULES', id);
    }
});

export default module;
