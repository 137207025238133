<template>
    <el-dialog
        :visible.sync="currentVisible"
        append-to-body
        lock-scroll
        destroy-on-close
        width="calc(100% - 60px)"
        top="60px"
        custom-class="pdf-viewer"
    >
        <object
            v-if="url !== null"
            :data="url"
            type="application/pdf"
            class="pdf-viewer__viewer"
        >
            <embed
                :src="url"
                type="application/pdf"
            >
        </object>
    </el-dialog>
</template>

<script>
export default {
    name: 'UiPdfViewer',

    props: {
        url: {
            type: String,
            required: true,
        },

        visible: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        currentVisible: {
            get () {
                return this.visible;
            },

            set (value) {
                this.$emit('update:visible', value);
            },
        },
    },
};
</script>
