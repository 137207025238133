<template>
    <div class="account-option">
        <div class="account-option__title">
            {{ data.name }}
        </div>
        <div
            v-if="data.info && !isEveryFieldEmpty"
            class="account-option__info"
        >
            <template v-if="isBankCard">
                <div>Номер карты: {{ data.bankCardNumber }}</div>
                <div>Телефон: {{ data.phoneNumber }}</div>
                <div>р/с: {{ data.info.settlementAccount }}</div>
                <div>Банк: {{ data.info.bankName }}</div>
            </template>
            <template v-else>
                <div>р/с: {{ data.info.settlementAccount }}</div>
                <div>БИК: {{ data.info.bik }}</div>
                <div>Банк: {{ data.info.bankName }}</div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UiAccountOption',

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    computed: {
        isBankCard () {
            return this.data.accountType === 'bank_card';
        },

        isEveryFieldEmpty () {
            if (this.isBankCard) {
                return !this.data.bankCardNumber &&
                    !this.data.phoneNumber &&
                    !this.data.info.settlementAccount &&
                    !this.data.info.bankName;
            } else {
                return !this.data.info.settlementAccount &&
                    !this.data.info.bik &&
                    !this.data.info.bankName;
            }
        },
    },
};
</script>

<style lang="scss">
.account-option {
    &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__info {
        color: $--color-text-secondary;
        font-size: 11px;
        font-weight: normal;
        line-height: normal;
    }
}
</style>
