import * as api from '@/api/possessions/possessions/сancellationDocument';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return api.getCancellationDocuments(params);
    },
    doFetchSingle (id: string, params: object) {
        return api.getCancellationDocument(id, params);
    },
    doCreate (data: object) {
        return api.createCancellationDocument(data);
    },
    // doUpdate (id: string, data: object) {
    //     return api.(id, data);
    // },
    doDelete (id: string) {
        return api.deleteCancellationDocument(id);
    },
});
