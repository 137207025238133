import { getTopWindow } from '@/utils';

export default {
    data () {
        return {
            stopTransitions: false,
        };
    },

    computed: {
        hasNavDrawer () {
            return window.self === getTopWindow() &&
                this.$route.matched.some(route => route.meta?.navDrawer);
        },

        isNavDrawerShow () {
            return this.hasNavDrawer && !this.$store.getters['ui/isNavDrawerCollapsed'];
        },
    },

    watch: {
        '$route' () {
            this.stopTransitions = true;
            setTimeout(() => {
                this.stopTransitions = false;
            }, 300);
        },
    },
};
