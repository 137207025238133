import { config } from '@/store/modules/loanAnalytic';
import { createCrudModule } from '@/utils/vuex-crud';
import { LoanDirectionTypes } from '@/enums';

export default createCrudModule({
    ...config,
    doFetchList (params) {
        return config.doFetchList({
            ...params,
            direction: LoanDirectionTypes.OUT,
        });
    },
});
