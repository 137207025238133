import { getAccounts } from '@/api/integration/businessIntegration';
import { Module } from 'vuex';

export default {
    namespaced: true,
    state: {
        integrations: [],
        isLoading: false,
        fetchError: null,
    },
    getters: {
        integrations: state => state.integrations,
        isLoading: state => state.isLoading,
    },
    mutations: {
        FETCH_INTEGRATIONS_START (state) {
            state.isLoading = true;
        },
        FETCH_INTEGRATIONS_SUCCESS (state, { data }) {
            state.integrations = data.integrations;
            state.isLoading = false;
            state.fetchError = false;
        },
        FETCH_INTEGRATIONS_ERROR (state, error) {
            state.isLoading = false;
            state.fetchError = error;
        },
        RESET_INTEGRATIONS (state) {
            state.integrations = [];
            state.isLoading = false;
            state.fetchError = null;
        },
    },
    actions: {
        async fetch ({ commit, state }, { params }) {
            commit('FETCH_INTEGRATIONS_START');
            try {
                const data = await getAccounts(params);
                commit('FETCH_INTEGRATIONS_SUCCESS', { data });
                return data;
            } catch (e) {
                console.error(e);
                commit('FETCH_INTEGRATIONS_ERROR', e);
                return null;
            }
        },
        reset ({ commit }) {
            commit('RESET_INTEGRATIONS');
        },
    },
} as Module<any, any>;
