<template>
    <ui-dialog
        :visible.sync="currentVisible"
        class="site-notification-dialog"
        width="1000px"
    >
        <template #title>
            <div class="site-notification-dialog__header-wrapper">
                <div class="site-notification-dialog__side">
                    <div class="site-notification-dialog__header">
                        <el-input
                            v-model="searchQuery"
                            disabled
                        >
                            <template #prepend>
                                <iconify-icon icon="fa6-solid:magnifying-glass" />
                            </template>
                        </el-input>
                    </div>
                </div>
                <el-divider
                    direction="vertical"
                    class="u-h-auto u-m-none"
                />
                <div class="site-notification-dialog__main">
                    <div class="site-notification-dialog__chat-title">
                        {{ activeGroupTitle }}
                    </div>
                </div>
            </div>
            <el-divider class="u-m-none" />
        </template>

        <div class="site-notification-dialog__wrapper">
            <div class="site-notification-dialog__side">
                <div class="u-scrollable u-scrollable--y">
                    <el-skeleton
                        :loading="loading"
                        animated
                        :count="7"
                    >
                        <template #template>
                            <div class="u-pv-xs">
                                <el-skeleton-item
                                    variant="text"
                                    style="width: 128px;"
                                />
                                <div
                                    class="u-mt-xs"
                                    style="display: flex; justify-content: space-between;"
                                >
                                    <el-skeleton-item
                                        variant="text"
                                        style="width: 96px;"
                                    />
                                    <el-skeleton-item
                                        variant="text"
                                        style="width: 48px;"
                                    />
                                </div>
                            </div>
                            <el-divider class="u-mv-none" />
                        </template>

                        <template #default>
                            <ui-chat-list-item
                                v-for="item in lastMessages"
                                :key="item.id"
                                :title="item.group.name"
                                :subtitle="item.summary?.lastMessageText"
                                :date="item.summary?.lastMessageDate"
                                :unread-quantity="item.summary?.unreadCount"
                                :active="item.id === activeGroup"
                                @click="handleClick(item)"
                            />
                        </template>
                    </el-skeleton>
                </div>
            </div>
            <el-divider
                direction="vertical"
                class="u-h-auto u-m-none"
            />
            <div class="site-notification-dialog__main">
                <site-notification-chat-window
                    v-if="activeGroup"
                    :group="activeGroup"
                />
            </div>
        </div>
    </ui-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import DialogMixin from '@/mixins/dialog';
import SiteNotificationChatWindow from '@/components/profile/SiteNotificationChatWindow.vue';
import { Group, GroupSummary } from '@/types/Notification';
import { Business } from '@/types/Business';
import { NotificationGroups } from '@/enums';

interface LastMessage {
    id: NotificationGroups,
    group: Group,
    summary: GroupSummary | null
}

export default Vue.extend({
    components: { SiteNotificationChatWindow },
    mixins: [DialogMixin],
    data () {
        return {
            searchQuery: '',
            selectedBusinesses: [],
            loading: false
        };
    },

    computed: {
        businesses (): Business[] {
            return this.$store.getters['business/list'];
        },

        lastMessages (): LastMessage[] {
            return this.$store.getters['notification/lastMessages'];
        },

        groups () {
            return this.$store.getters['notification/lastMessages'];
        },

        activeGroupTitle () {
            return this.$store.state.notification.groups.find(i => i.id === this.activeGroup)?.name ?? '';
        },

        activeGroup: {
            get () {
                return this.$store.state.notification.currentGroup;
            },

            set (val) {
                this.$store.dispatch('notification/setActiveGroup', val);
            }
        }
    },

    watch: {
        currentVisible: {
            async handler (val) {
                if (val) {


                } else {
                    this.searchQuery = '';
                    this.activeGroup = '';
                }
            }
        }
    },

    async created () {
        this.loading = true;

        if (this.businesses.length === 0) {
            this.fetchBusinesses();
        }


        await Promise.all([
            this.fetchLastNotifications(),
            this.fetchNotificationGroups()
        ]);

        this.loading = false;
    },

    methods: {
        fetchBusinesses () {
            this.$store.dispatch('business/fetchList', {
                params: {
                    pagination: 0,
                    businessGroup: this.$store.getters.currentBusinessGroupId
                }
            });
        },

        fetchNotificationGroups () {
            return this.$store.dispatch('notification/fetchGroups');
        },

        fetchLastNotifications () {
            return this.$store.dispatch('notification/fetchSummary');
        },

        handleClick (item: LastMessage) {
            if (item.id !== this.activeGroup) {
                this.activeGroup = item.id;
            }
        }
    }
});
</script>

<style lang="scss">
.site-notification-dialog {

    .el-dialog {
        &__body {
            padding-top: 0;
            padding-bottom: 20px;
        }
    }

    &__wrapper, &__header-wrapper {
        display: flex;
    }

    &__wrapper {
        height: 600px;
    }

    &__side {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 300px;
    }

    &__main {
        flex-grow: 1;
    }

    &__chat-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 36px;
    }

    &__header, &__chat-title {
        height: 46px;
        flex-shrink: 0;
    }

    &__header {
        padding-right: 16px;
    }

    &__chat-title {
        padding: 0 16px;
    }
}
</style>
