import request from '@/utils/request';

const baseUri = '/access_groups';

export const getAccessGroups = (params) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getAccessGroup = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'POST',
    });

export const createAccessGroup = (data) =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });

export const deleteAccessGroup = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });

export const updateAccessGroup = (id, data) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });
