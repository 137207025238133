import request from '@/utils/request';

export const baseUri = '/funding_claim_payments';

export const getPayments = (params) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const updatePayment = (id, data, params) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
        params,
    });

export const createPayment = (data, params) =>
    request({
        url: baseUri,
        method: 'POST',
        data,
        params,
    });

export const getPayment = (id, params) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const deletePayment = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });

export const approvePayment = (id, data, params) =>
    request({
        url: `${baseUri}/${id}/approve`,
        method: 'POST',
        data,
        params,
    });

export const rejectPayment = (id, data, params) =>
    request({
        url: `${baseUri}/${id}/reject`,
        method: 'POST',
        data,
        params,
    });

export const startApprovingPayment = (id, data, params) =>
    request({
        url: `${baseUri}/${id}/start_approving`,
        method: 'POST',
        data,
        params,
    });

export const declinePayment = (id, data, params) =>
    request({
        url: `${baseUri}/${id}/decline`,
        method: 'POST',
        data,
        params,
    });

export const getPaymentApprovers = (id) =>
    request({
        url: `${baseUri}/${id}/approvers`,
        method: 'GET',
    });

export const getPresets = (params) =>
    request({
        url: `${baseUri}/presets`,
        method: 'GET',
        params,
    });

export const exportToBank = (data) =>
    request({
        url: `${baseUri}/export_to_bank`,
        method: 'POST',
        data,
    });

export const approveAll = (id, data, params) =>
    request({
        url: `${baseUri}/${id}/approve_all`,
        method: 'POST',
        data,
        params,
    });

export const getPaymentApprovingList = (id) =>
    request({
        url: `${baseUri}/${id}/approving_list`,
        method: 'GET'
    });

export const getPaymentRejectTransitions = (id) =>
    request({
        url: `${baseUri}/${id}/reject_transitions`,
        method: 'GET'
    });
