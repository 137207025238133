<template>
    <button
        class="button-link"
        :disabled="disabled"
        @click.prevent="handleClick"
    >
        <i
            v-if="loading"
            class="el-icon-loading"
        />
        <iconify-icon
            v-else
            icon="akar-icons:link-out"
        />

        <slot />
    </button>
</template>

<script>
export default {
    name: 'UiButtonLink',

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        handleClick () {
            this.$emit('click');
        },
    },
};
</script>
