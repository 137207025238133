import request from '@/utils/request';

const baseUri = '/transaction_email_imports';

export const getEmailImports = (params: object) =>
    request({
        url: baseUri,
        method: 'GET',
        params
    });

export const getEmailImport = (id: string) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET'
    });

export const createEmailImport = (data: object) =>
    request({
        url: baseUri,
        method: 'POST',
        data
    });

export const deleteEmailImport = (id: string) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE'
    });
