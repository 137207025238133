<template>
    <ui-table-filter-select
        v-model="currentValue"
        v-bind="proxyProps"
        :fetch-items="fetchOrganizations"
        :init-method="fetchInitOrganizations"
        multiple
        v-on="$listeners"
    />
</template>

<script>
import { getOneCOrganizations } from '@/api/integration/businessIntegration';
import WrapperMixin from '@/mixins/wrapper';
import SelectNestedMixin from '@/mixins/select-nested';

export default {
    name: 'UiTableFilterOnecOrganizationSelect',

    mixins: [WrapperMixin, SelectNestedMixin],

    props: {
        multiple: {
            type: Boolean,
            default: true,
        },

        fetchOnCreate: {
            type: Boolean,
            default: false,
        },

        value: {
            type: [String, Array],
            default: '',
        },

        nested: {
            type: Boolean,
            default: true,
        },

        fetchParams: {
            type: Object,
            default: null,
        },
    },

    computed: {
        businessId () {
            return this.$store.getters['business/currentId'];
        },
    },

    methods: {
        async fetchOrganizations (query, cb) {
            const params = {
                businessId: this.businessId,
                'order[shortName]': 'asc',
            };

            if (query) {
                params.shortName = query;
            }

            const data = await getOneCOrganizations(params);
            const items = data['hydra:member']
                .map(item => ({
                    id: item.id,
                    name: item.shortName,
                })) || [];
            cb(items);
        },

        async fetchInitOrganizations () {
            const params = {
                businessId: this.businessId,
            };

            try {
                const data = await getOneCOrganizations(params);
                const items = data['hydra:member']
                    .map(item => ({
                        id: item.id,
                        name: item.shortName,
                    })) || [];
                return items || [];
            } catch (e) {
                this.$flashError(e);
            }
        },
    },
};
</script>
