<template>
    <el-autocomplete
        v-model="currentValue"
        :fetch-suggestions="fetchSuggestions"
        value-key="_value"
        class="dadata-autocomplete"
        :popper-class="fullPopperClass"
        v-bind="proxyProps"
        v-on="$listeners"
    >
        <template #default="{ item }">
            <slot
                v-if="!item._disabled"
                :item="item"
                name="default"
            >
                <div
                    v-if="type === 'organization'"
                    class="dadata-autocomplete-item"
                    :class="{ 'is-strikethrough': (item.data?.state?.status) === 'LIQUIDATED' }"
                >
                    <div class="dadata-autocomplete-item__name">
                        {{ item.value }}
                    </div>
                    <div
                        v-if="item.data"
                        class="dadata-autocomplete-item__description"
                    >
                        {{ item.data?.inn ?? '' }}, {{ getFullAddress(item.data.address) }}
                    </div>
                </div>
                <div
                    v-else-if="type === 'bank'"
                    class="dadata-autocomplete-item"
                >
                    <div class="dadata-autocomplete-item__name">
                        {{ item.value }}
                    </div>
                    <div
                        v-if="item.data"
                        class="dadata-autocomplete-item__description"
                    >
                        {{ item.data?.bic ?? '' }}, {{ getFullAddress(item.data.address) }}
                    </div>
                </div>
                <template v-else>
                    {{ item.value }}
                </template>
            </slot>
            <div
                v-else
                class="dadata-autocomplete-item -no-data"
                @click.stop
            >
                {{ item._value }}
            </div>
        </template>
    </el-autocomplete>
</template>

<script>
import { get } from 'lodash';
import { suggestAddress, suggestBank, suggestOrganization } from '@/utils/dadata';
import WrapperMixin from '@/mixins/wrapper';

export default {
    name: 'UiDadataAutocomplete',

    mixins: [WrapperMixin],

    props: {
        value: {
            type: String,
            default: null,
        },

        type: {
            type: String,
            default: 'address',
            validator (value) {
                return ['address', 'organization', 'bank'].includes(value);
            },
        },

        minLength: {
            type: Number,
            default: 2,
        },

        searchParams: {
            type: Object,
            default: () => ({}),
        },

        valueKey: {
            type: String,
            default: 'value',
        },

        popperClass: {
            type: String,
            default: '',
        },
    },

    data () {
        return {};
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (value) {
                this.$emit('input', value);
            },
        },

        location () {
            return this.$store.state.dadata.location;
        },

        fullPopperClass () {
            return `dadata-autocomplete-suggestion ${this.popperClass ?? ''}`.trim();
        },
    },

    methods: {
        async fetchSuggestions (query, cb) {
            if (!query || query.length < this.minLength) {
                // eslint-disable-next-line
                cb([]);
                return;
            }

            const params = this.searchParams;
            if (this.location && this.location.data) {
                params.locations_boost = [{ kladr_id: this.location.data.kladr_id }];
            }

            let data = {};
            if (this.type === 'organization') {
                data = await suggestOrganization(query, params);
            } else if (this.type === 'bank') {
                data = await suggestBank(query, params);
            } else {
                data = await suggestAddress(query, params);
                data.data.suggestions = data.data.suggestions.map(item => {
                    return {
                        ...item,
                        value: this.getFullAddress(item),
                    };
                });
            }

            const items = data.data.suggestions.map(item => ({
                ...item,
                _value: get(item, this.valueKey),
            }));

            // У autocomplete нет подписи "Нет данных",
            // поэтому сделал костыль с заблокированным итемом, а select не подходит по логике
            cb(items.length > 0 ? items : [{ _value: 'Нет данных', _disabled: true }]);
        },

        getFullAddress (address) {
            if (!address) {
                return '';
            }
            const postalCode = address.data?.postal_code || '';
            return (postalCode ? postalCode + ', ' : '') + address.value;
        },
    },
};
</script>

<style lang="scss">
.dadata-autocomplete-item {
    line-height: normal;
    padding: 5px 0;

    &__name {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__description {
        color: $--color-text-secondary;
        white-space: normal;
        line-height: 1.1;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 2.2em;
    }

    &.is-strikethrough &__name {
        text-decoration: line-through;
    }

    &.-no-data {
        padding: 10px 0;
        text-align: center;
        color: $--select-dropdown-empty-color;
        margin: -10px -20px;
        cursor: default;
        background-color: $--color-white;
    }
}

.dadata-autocomplete-suggestion {
    min-width: 522px;
}
</style>
