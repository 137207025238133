<template>
    <div
        class="stage-list-item"
        :style="{ maxWidth: maxWidth }"
    >
        <div
            class="stage-list-item__header"
            :style="headerStyle"
        >
            <div
                class="stage-list-item__header-main"
                :style="headerMainStyle"
            >
                <slot />
            </div>
            <div
                v-if="!noIcon"
                class="stage-list-item__header-append"
            >
                <el-button
                    type="text"
                    size="mini"
                    class="stage-list-item__button u-mt-auto u-mb-auto"
                    @click="$emit('edit', value)"
                >
                    <iconify-icon
                        :icon="currentIcon"
                    />
                </el-button>
            </div>
        </div>
        <div class="stage-list-item__content">
            <div
                ref="rulesWrapper"
                class="stage-list-item__list"
            >
                <slot name="list" />
            </div>
            <slot name="append" />
        </div>
    </div>
</template>

<script>
import Sortable from 'sortablejs';

export default {
    name: 'UiStageListItem',

    props: {
        value: {
            type: String,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        icon: {
            type: String,
            default: 'fa6-solid:pencil',
        },

        disabledIcon: {
            type: String,
            default: 'fa6-solid:lock',
        },

        color: {
            type: String,
            default: '#999',
        },

        minWidth: {
            type: String,
            default: '220px',
        },

        maxWidth: {
            type: String,
            default: '220px',
        },

        noIcon: {
            type: Boolean,
            default: false,
        },

        rules: {
            type: Array,
            default: () => []
        }
    },

    data () {
        return {
            sortable: null,
            dragInfo: {
                startIndex: undefined
            }
        };
    },

    computed: {
        currentIcon () {
            return this.disabled ? this.disabledIcon : this.icon;
        },

        headerStyle () {
            return {
                backgroundColor: this.color,
                minWidth: this.minWidth,
                borderLeftColor: this.color,
            };
        },

        headerMainStyle () {
            return {
                color: this.$tinycolor(this.color).getBrightness() < 160 ? '#fff' : '#000',
            };
        },
    },

    mounted() {
        const wrapper = this.$refs.rulesWrapper;
        this.sortable = new Sortable(wrapper, {
            animation: 300,
            onStart: ({ oldIndex }) => {
                this.dragInfo.startIndex = oldIndex;
            },
            onEnd: ({ newIndex }) => {
                const { nextItem, prevItem } = this.getSiblings(newIndex);
                this.$emit('drag-end', {
                    nextItem,
                    prevItem,
                    dragged: this.rules[this.dragInfo.startIndex]
                });
            }
        });
    },

    beforeDestroy() {
        if (this.sortable) {
            this.sortable.destroy();
        }
    },

    methods: {
        getSiblings (newIndex) {
            const isSameIndexOrAfter = newIndex >= this.dragInfo.startIndex;
            let nextItemIndex = newIndex;
            if (isSameIndexOrAfter) {
                nextItemIndex++;
            }
            const prevItemIndex = nextItemIndex - (newIndex === this.dragInfo.startIndex ? 2 : 1);
            const nextItem = this.rules[nextItemIndex];
            const prevItem = this.rules[prevItemIndex];

            return { nextItem, prevItem };
        }
    }
};
</script>

<style lang="scss">
.stage-list-item {
    &__header {
        display: flex;
        align-items: stretch;
        position: sticky;
        top: 0;
        z-index: 1;
        border-radius: $--card-border-radius;
    }

    &__header-main {
        flex-grow: 1;
        padding: 7px 12px;
        text-align: center;
        white-space: nowrap;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__header-append {
        width: 36px;
        flex-shrink: 0;
        border-left: 1px solid $--color-white;
        display: flex;
        justify-content: center;
    }

    &__button {
        padding: 7px;
        color: $--color-black;
    }

    &__content {
        position: sticky;
        top: 48px;
    }
}
</style>
