import * as api from '@/api/possessions/possessions/movementProduct';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchSingle (id, params) {
        return api.getMovementProduct(id, params);
    },
    doCreate (data) {
        return api.createMovementProduct(data);
    },
    doUpdate (id, data) {
        return api.editMovementProduct(id, data);
    },
});
