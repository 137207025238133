import { getEmailImport, createEmailImport, deleteEmailImport, getEmailImports } from '@/api/transactionEmailImport';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return getEmailImports(params);
    },
    doFetchSingle (id: string) {
        return getEmailImport(id);
    },
    doCreate (data: object) {
        return createEmailImport(data);
    },
    doDelete (id: string) {
        return deleteEmailImport(id);
    }
});
