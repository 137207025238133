import { approveDocument, deleteDocument, disapproveDocument, getDocument, getDocuments } from '@/api/staff/personnelDocument';
import { createCrudModule } from '@/utils/vuex-crud';
import { CanceledError } from 'axios';

const module = createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return getDocuments(params);
    },
    doFetchSingle (id) {
        return getDocument(id);
    },
    doDelete (id) {
        return deleteDocument(id);
    },
});

Object.assign(module.state, {
    isApproving: false,
    approveError: null,
    isDisapproving: false,
    disapproveError: null,
});

Object.assign(module.mutations, {
    APPROVE_START (state) {
        state.isApproving = true;
    },
    APPROVE_SUCCESS (state) {
        state.isApproving = false;
        state.approveError = null;
    },
    APPROVE_ERROR (state, error) {
        state.isApproving = false;
        state.approveError = error;
    },
    DISAPPROVE_START (state) {
        state.isDisapproving = true;
    },
    DISAPPROVE_SUCCESS (state) {
        state.isDisapproving = false;
        state.disapproveError = null;
    },
    DISAPPROVE_ERROR (state, error) {
        state.isDisapproving = false;
        state.disapproveError = error;
    },
});

Object.assign(module.actions, {
    async approve ({ commit }, { id, data }) {
        commit('APPROVE_START');
        try {
            const response = await approveDocument(id, data);
            commit('APPROVE_SUCCESS', response);
            return response;
        } catch (error) {
            if (!(error instanceof CanceledError)) {
                console.error(error);
            }
            commit('APPROVE_ERROR', error);
            return false;
        }
    },
    async disapprove ({ commit }, { id, data }) {
        commit('DISAPPROVE_START');
        try {
            const response = await disapproveDocument(id, data);
            commit('DISAPPROVE_SUCCESS', response);
            return response;
        } catch (error) {
            if (!(error instanceof CanceledError)) {
                console.error(error);
            }
            commit('DISAPPROVE_ERROR', error);
            return false;
        }
    },
});

export default module;
