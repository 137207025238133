import request from '@/utils/request';

const baseUri = '/access_categories';

export const getAccessCategories = (params) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getAccessCategory = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    });
