import { createCrudModule } from '@/utils/vuex-crud';
import { getCards } from '@/api/requisite';

export default createCrudModule({
    businessDependent: true,
    only: ['FETCH_LIST'],
    doFetchList (params) {
        return getCards(params);
    }
});
