<template>
    <div class="timeline-item-changeset">
        <div class="timeline-item-changeset__field">
            {{ displayField }}
        </div>
        <div
            v-for="(change, index) in changeset.changes"
            :key="index"
            class="timeline-item-changeset__info"
        >
            <template v-if="isFieldEdit(change)">
                <span
                    v-if="change.locationName"
                    class="timeline-item-changeset__additional-header"
                >
                    {{ change.locationName }}
                </span>
                <span class="timeline-item-changeset__from">{{ displayValue(change.from) }}</span>
                <span class="timeline-item-changeset__separator">→</span>
                <span class="timeline-item-changeset__to">{{ displayValue(change.to) }}</span>
            </template>
            <template v-else-if="isCollectionRecordAdd(change)">
                <span class="timeline-item-changeset__from">{{ displayCollectionAction('add') }}</span>
                <span class="timeline-item-changeset__separator" />
                <span class="timeline-item-changeset__to">{{ displayValue(change.value) }}</span>
            </template>
            <template v-else-if="isCollectionRecordDelete(change)">
                <span class="timeline-item-changeset__from">{{ displayCollectionAction('delete') }}</span>
                <span class="timeline-item-changeset__separator" />
                <span class="timeline-item-changeset__to">{{ displayValue(change.value) }}</span>
            </template>
        </div>
    </div>
</template>

<script>
import { TimelineChangeTypes } from '@/config';
import FormatMixin from '@/mixins/format';

export default {
    name: 'UiTimelineItemChangeset',

    mixins: [FormatMixin],

    props: {
        entityType: {
            type: String,
            required: true,
        },

        changeset: {
            type: Object,
            required: true,
        },

        fieldsMeta: {
            type: Object,
            required: true,
        },
    },

    computed: {
        displayField () {
            const { field } = this.changeset;
            const meta = this.getFieldMeta(field);

            return (meta && meta.title) ?? '';
        },
    },

    methods: {
        getFieldMeta (field) {
            return this.fieldsMeta[this.entityType]?.[field] ?? null;
        },

        displayValue (value) {
            const { field } = this.changeset;
            const meta = this.getFieldMeta(field);

            if (meta && value !== null && typeof meta.value === 'function') {
                value = meta.value(value);
            }

            if (value === null) {
                return 'пусто';
            }
            return value;
        },
        displayCollectionAction (action) {
            const { field } = this.changeset;
            const meta = this.getFieldMeta(field);

            return meta?.[`${action}ActionTitle`] ?? action;
        },

        isFieldEdit (change) {
            return change.changeType === TimelineChangeTypes.FIELD_EDIT;
        },

        isCollectionRecordAdd (change) {
            return change.changeType === TimelineChangeTypes.COLLECTION_RECORD_ADD;
        },

        isCollectionRecordDelete (change) {
            return change.changeType === TimelineChangeTypes.COLLECTION_RECORD_DELETE;
        },
    },
};
</script>

<style lang="scss">
.timeline-item-changeset {
    font-size: 11px;
    line-height: normal;

    &:not(:first-child), & + .timeline-item-changeset-table {
        margin-top: 8px;
    }

    &__field {
        font-weight: bold;
        white-space: nowrap;
        color: $--color-text-gray;
        margin-bottom: 2px;
    }

    &__info {
        display: flex;
        line-height: 1.1;
    }
    &__title {
        max-width: calc(50% - 12px);
        text-align: left;
        word-break: break-word;
        margin-right: 20px;
    }
    &__additional-header {
        color: $--color-text-gray;
        margin-right: 10px;
    }

    &__from {
        max-width: calc(50% - 12px);
        text-align: left;
        word-break: break-word;
        color: $--color-text-secondary;
    }

    &__separator {
        width: 24px;
        text-align: center;
    }

    &__to {
        max-width: calc(50% - 12px);
        text-align: left;
        word-break: break-word;
    }
}
</style>
