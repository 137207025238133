<template>
    <div
        class="el-input upload-input"
        :class="{
            [`el-input--${size}`]: size,
            'el-input--suffix': showClear,
            'el-input-group': $slots.prepend || $slots.append,
            'el-input-group--prepend': $slots.prepend,
            'el-input-group--append': $slots.append,
            'is-disabled': inputDisabled,
        }"
        @mouseover="hover = true"
        @mouseleave="hover = false"
    >
        <div
            v-if="$slots.prepend"
            class="el-input-group__prepend"
        >
            <slot name="prepend" />
        </div>

        <input
            ref="input"
            :value="fileName"
            class="el-input__inner"
            readonly
            :placeholder="placeholder"
            @blur="handleInputBlur"
        >

        <label
            class="upload-input__overlay"
            @click="handleClick"
        >
            <input
                ref="uploadInput"
                class="upload-input__input"
                type="file"
                :disabled="inputDisabled"
                :accept="accept"
                @change="handleUploadFile"
            >
        </label>

        <span
            v-if="showClear"
            class="el-input__suffix"
        >
            <span class="el-input__suffix-inner">
                <iconify-icon
                    icon="akar-icons:circle-x"
                    class="el-input__icon el-input__clear"
                    style="width: 25px;"
                    @click.native="clear"
                />
            </span>
        </span>

        <div
            v-if="$slots.append"
            class="el-input-group__append"
        >
            <slot name="append" />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'UiUploadInput',

    inject: {
        elForm: {
            default: '',
        },
    },

    props: {
        value: {
            type: [Object, File],
            default: undefined
        },

        size: {
            type: String,
            default: 'medium'
        },

        disabled: {
            type: Boolean,
            default: false
        },

        clearable: {
            type: Boolean,
            default: false
        },

        validateEvent: {
            type: Boolean,
            default: true
        },

        placeholder: {
            type: String,
            default: 'Выбрать'
        },

        accept: {
            type: String,
            default: '',
        }
    },

    data () {
        return {
            hover: false,
            focused: false,
            fileWindowOpened: false,
        };
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (val) {
                this.$emit('input', val);
            },
        },

        fileName (): string {
            return this.value?.name || '';
        },

        showClear (): boolean {
            return this.clearable &&
                (this.focused || this.hover) &&
                !this.inputDisabled;
        },

        inputDisabled (): boolean {
            return this.disabled || (this.elForm || {}).disabled;
        },
    },

    watch: {
        value (val) {
            if (this.validateEvent) {
                this.dispatch('ElFormItem', 'el.form.change', [val]);
            }
        },
    },

    methods: {
        clear () {
            this.$emit('input', null);
            this.$emit('clear');
            this.$emit('blur');
        },

        dispatch (componentName: string, eventName: string, params: any[]) {
            let parent = this.$parent || this.$root;
            let name = parent.$options.componentName;

            while (parent && (!name || name !== componentName)) {
                parent = parent.$parent;

                if (parent) {
                    name = parent.$options.componentName;
                }
            }
            if (parent) {
                parent.$emit(eventName, ...params);
            }
        },

        handleClick () {
            if (this.$refs.input) {
                (this.$refs.input as HTMLElement).focus();
            }
            this.fileWindowOpened = true;
        },

        handleUploadFile (event: Event) {
            const files = (event.target as HTMLInputElement).files;

            if (files?.[0]) {
                this.currentValue = files[0];
            }

            // Костыль, чтобы при выборе -> очистке -> выборе того же файла триггерился инпут
            (event.target as HTMLInputElement).value = '';
            this.fileWindowOpened = false;
        },

        handleInputBlur () {
            if (!this.fileWindowOpened) {
                this.focused = false;
                this.$emit('blur');
                if (this.validateEvent) {
                    this.dispatch('ElFormItem', 'el.form.blur', [this.value]);
                }
            }
        }
    },
});
</script>

<style lang="scss">
.upload-input {
  $b: &;

  &:not(.is-disabled):hover {
    .el-input {
      &__inner:not(:focus) {
        border-color: $--border-color-hover;
      }
    }
  }

  &__input {
    display: none;
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
  }

  .el-input {
    &__suffix {
      cursor: pointer;
    }

    &--suffix {
        #{$b}__overlay {
          right: 30px;
        }
    }
  }

  &.is-disabled {
    #{$b} {
      &__overlay {
        cursor: not-allowed;
      }
    }
  }
}
</style>
