import { createDocument, getDocument, getDocuments } from '@/api/staff/assessmentDocument';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    only: ['FETCH_LIST', 'FETCH_SINGLE', 'CREATE'],
    doFetchList (params) {
        return getDocuments(params);
    },
    doFetchSingle (id, params) {
        return getDocument(id, params);
    },
    doCreate (data, params) {
        return createDocument(data, params);
    },
});
