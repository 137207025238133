import request from '@/utils/request';
import { PagedCollection } from "@/types/hydra";
import { AnalyticEntityItem } from "@/types/AnalyticEntity";

const baseUri = '/analytic_entity_items';

export const getAnalyticEntityItems = (params: object): Promise<PagedCollection<AnalyticEntityItem>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getAnalyticEntityItem = (id: string): Promise<AnalyticEntityItem> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    });

export const createAnalyticEntityItem = (data: object): Promise<AnalyticEntityItem> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });

export const deleteAnalyticEntityItem = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });

export const updateAnalyticEntityItem = (id: string, data: object): Promise<AnalyticEntityItem> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });
