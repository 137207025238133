<template>
    <div class="data-table-info">
        <div
            v-if="primary || $slots.default"
            :class="`data-table-info__primary ${primaryClass}`"
        >
            <slot v-if="$slots.default" />
            <template v-else>
                {{ primary }}
            </template>
        </div>
        <div
            v-if="secondary || $slots.secondary"
            :class="`data-table-info__secondary ${secondaryClass}`"
        >
            <slot
                v-if="$slots.secondary"
                name="secondary"
            />
            <template v-else>
                {{ secondary }}
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UiDataTableInfo',

    props: {
        primary: {
            type: [String, Number],
            default: '',
        },

        secondary: {
            type: [String, Number],
            default: '',
        },

        primaryClass: {
            type: String,
            default: '',
        },

        secondaryClass: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss">
.data-table-info {
    line-height: normal;

    &__primary {
        min-height: 19px;
        color: $--color-text-regular;
    }

    &__secondary {
        font-size: 12px;
        min-height: 17px;
        color: $--color-text-secondary;
    }
}
</style>
