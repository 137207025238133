<template>
    <div
        :class="{ 'is-active-label': labelActive }"
        class="table-filter"
    >
        <div class="table-filter__slot">
            <div
                v-if="label"
                class="table-filter__label"
            >
                <iconify-icon
                    slot="reference"
                    icon="fa6-solid:filter"
                    class="table-filter__icon"
                />
                {{ label }}
            </div>
            <ui-remote-select
                v-if="remote"
                ref="select"
                v-model="currentValue"
                v-bind="proxyProps"
                class="table-filter__control"
                :multiple="multiple"
                :multiple-limit="multiple ? 5 : 1"
                :label-key="labelKey"
                placeholder=""
                @visible-change="handleControlVisibleChange"
                @select="handleRemoteSelect"
            >
                <template #option="{ item }">
                    <slot
                        :item="item"
                        name="option"
                    />
                </template>
            </ui-remote-select>
            <el-select
                v-else
                ref="select"
                v-model="currentValue"
                v-bind="proxyProps"
                class="table-filter__control"
                :multiple="multiple"
                :multiple-limit="multiple ? 5 : 1"
                placeholder=""
                @visible-change="handleControlVisibleChange"
                @change="handleChange"
                @clear="handleClear"
            >
                <template #option="{ item }">
                    <slot
                        :item="item"
                        name="option"
                    />
                </template>
            </el-select>
        </div>
    </div>
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';

export default {
    name: 'UiTableFilterSelect',
    mixins: [WrapperMixin],
    props: {
        value: {
            type: [String, Array],
            default: '',
        },

        label: {
            type: String,
            default: '',
        },

        width: {
            type: Number,
            default: 300,
        },

        multiple: {
            type: Boolean,
            default: false,
        },

        remote: {
            type: Boolean,
            default: true,
        },

        labelKey: {
            type: String,
            default: 'name'
        }
    },

    data () {
        return {
            focus: false,
        };
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (value) {
                this.$emit('input', value);
            },
        },

        labelActive () {
            if (
                (Array.isArray(this.currentValue) && this.currentValue.length > 0) ||
                (typeof this.currentValue === 'string' && this.currentValue !== '')
            ) {
                return true;
            }

            return this.focus;
        },
    },

    watch: {
        value () {
            this.$refs.select.blur();
        },
    },

    methods: {
        getDefaultProps () {
            if (this.remote) {
                return {};
            }

            return {
                allowCreate: true,
                filterable: true,
                noDataText: this.$t('message.enterText'),
                noMatchText: ' ',
            };
        },

        handleRemoteSelect (item) {
            this.$emit('select', item?.id ?? item);
            this.$emit('change', item?.id ?? item);
        },

        handleChange () {
            this.$emit('select', this.value);
            this.$emit('change', this.value);
        },

        handleClear () {
            this.$emit('select', this.value);
            this.$emit('change', this.value);
        },

        handleControlVisibleChange (value) {
            this.focus = value;
        },
    },
};
</script>
