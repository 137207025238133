<template>
    <ui-loading v-if="isLoading" />
    <router-view
        v-else-if="businessId"
        :key="businessId"
    />
</template>

<script>
export default {
    data () {
        return {
            isLoading: false,
        };
    },

    computed: {
        businessId () {
            return this.$store.getters['business/currentId'];
        },
    },
};
</script>
