<template>
    <ui-card
        clickable
        class="create-card"
        @click="handleClick"
    >
        <div class="create-card__icon">
            <div class="create-card__icon-bg" />
            <div class="create-card__icon-plus">
                <iconify-icon icon="fa6-solid:plus" />
            </div>
        </div>
        <div class="create-card__caption">
            {{ caption }}
        </div>
    </ui-card>
</template>

<script>
export default {
    name: 'UiCreateCard',

    props: {
        caption: {
            type: String,
            required: true,
        },
    },

    methods: {
        handleClick () {
            this.$emit('click');
        },
    },
};
</script>
