import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { EsModule } from 'vue/types/options';
import { createI18n } from '@/composable/useI18n';

function loadLocaleMessages () {
    const messages = {} as any;

    const locales: Record<any, EsModule<object>> = import.meta.glob(['@/i18n/*/*.js', '@/i18n/*/*.ts'], { eager: true });
    for (const [path, mod] of Object.entries(locales)) {
        if (typeof mod !== 'object' || mod === null) {
            continue;
        }

        const matched = path.match(/([a-z-]+)\/([a-z0-9-_]+)\.[jt]s$/i);
        if (matched && matched.length > 2) {
            const locale = matched[1];
            const group = matched[2];
            if (typeof messages[locale] === 'undefined') {
                messages[locale] = {};
            }

            messages[locale][group] = ('default' in mod) ? mod.default : mod;
        }
    }

    return messages;
}

Vue.use(VueI18n);

export default createI18n({
    locale: import.meta.env.VITE_I18N_LOCALE || 'ru',
    fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'ru',
    messages: loadLocaleMessages(),
    pluralizationRules: {
        ru (choice, choicesLength) {
            if (choice === 0) {
                return 0;
            }

            const teen = choice > 10 && choice < 20;
            const endsWithOne = choice % 10 === 1;

            if (choicesLength < 4) {
                return (!teen && endsWithOne) ? 1 : 2;
            }
            if (!teen && endsWithOne) {
                return 1;
            }
            if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
                return 2;
            }

            return (choicesLength < 4) ? 2 : 3;
        },
    },
});
