import request from '@/utils/request';

const baseUri = '/timeline';

export const getTimelines = (params) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getTimeline = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    });

export const createComment = (data) =>
    request({
        url: `${baseUri}/comment`,
        method: 'POST',
        data,
    });
