import * as PaymentStageRuleAPI from '@/api/planning/FundingClaimPaymentStageRule';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchSingle (id: string, params?: object) {
        return PaymentStageRuleAPI.getStageRule(id, params);
    },
    doCreate (data: object) {
        return PaymentStageRuleAPI.createStageRule(data);
    },
    doUpdate (id: string, data: object) {
        return PaymentStageRuleAPI.updateStageRule(id, data);
    },
    doDelete (id: string) {
        return PaymentStageRuleAPI.deleteStageRule(id);
    },
});
