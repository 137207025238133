import * as contractorLocationApi from '@/api/contractLocation';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return contractorLocationApi.getContractLocations(params);
    },
    doFetchSingle (id: string, params: object) {
        return contractorLocationApi.getContractLocation(id, params);
    },
    doCreate (data: object) {
        return contractorLocationApi.createContractLocation(data);
    },
    doUpdate (id: string, data: object) {
        return contractorLocationApi.updateContractLocation(id, data);
    },
    doDelete (id: string) {
        return contractorLocationApi.deleteContractLocation(id);
    },
});
