import { createCrudModule } from '@/utils/vuex-crud';
import { createContractNumberingRule, deleteContractNumberingRule, getContractNumberingRule, getContractNumberingRules, updateContractNumberingRule } from '@/api/contractNumberingRule';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return getContractNumberingRules(params);
    },
    doFetchSingle (id: string) {
        return getContractNumberingRule(id);
    },
    doCreate (data: object) {
        return createContractNumberingRule(data);
    },
    doUpdate (id: string, data: object) {
        return updateContractNumberingRule(id, data);
    },
    doDelete (id: string) {
        return deleteContractNumberingRule(id);
    }
});
