import { getContractStageRule, createContractStageRule, deleteContractStageRule, getContractStageRules, updateContractStageRule } from '@/api/contractStageRule';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return getContractStageRules(params);
    },
    doFetchSingle (id: string) {
        return getContractStageRule(id);
    },
    doCreate (data: object) {
        return createContractStageRule(data);
    },
    doUpdate (id: string, data: object) {
        return updateContractStageRule(id, data);
    },
    doDelete (id: string) {
        return deleteContractStageRule(id);
    }
});