import * as api from '@/api/possessions/possessions/movementFixedAsset';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchSingle (id, params) {
        return api.getMovementFixedAsset(id, params);
    },
    doCreate (data) {
        return api.createMovementFixedAsset(data);
    },
    doUpdate (id, data) {
        return api.editMovementFixedAsset(id, data);
    },
});
