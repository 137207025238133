import dayjs, { ConfigType } from 'dayjs';

// TODO: По хорошему убрать null | undefined

export function useFormat () {
    const formatDate = (date: ConfigType | null | undefined, format = 'DD.MM.YYYY HH:mm:ss', utc = false): string => {
        if (!date) {
            return '';
        }
        let result = utc ? dayjs.utc(date).format(format) : dayjs(date).format(format);
        if (format.indexOf('MMMM') === 0) {
            result = result[0].toUpperCase() + result.substring(1);
        }
        return result;
    };

    const formatUtcDate = (date: ConfigType | null | undefined, format = 'DD.MM.YYYY HH:mm:ss'): string => {
        return formatDate(date, format, true);
    };

    const formatNumber = (value: number | string | null | undefined, decimals = 2, fluent = false): string => {
        return Number(value ?? 0).toLocaleString('ru', {
            minimumFractionDigits: fluent ? 0 : decimals,
            maximumFractionDigits: decimals,
        });
    };

    const formatPrice = (value: number | string | null | undefined): string => {
        return Number(value ?? 0).toLocaleString('ru', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    const formatPhoneNumber = (value: string | number | null | undefined): string => {
        const cleaned = String(value).replace(/\D/g, '');
        const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
        if (match) {
            const intlCode = (match[1] ? '+7 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
        }

        return '';
    };

    const formatFileSize = (size: number | string): string => {
        const normalized = Number(size);
        const i = Math.floor(Math.log(normalized) / Math.log(1024));
        return `${formatNumber(normalized / Math.pow(1024, i))} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`;
    };

    return {
        formatDate,
        formatUtcDate,
        formatNumber,
        formatPrice,
        formatPhoneNumber,
        formatFileSize,
    };
}
