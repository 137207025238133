import { getEventPath } from '@/utils';

export default {
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        currentVisible: {
            get () {
                return this.visible;
            },
            set (value) {
                this.$emit('update:visible', value);
            },
        },
    },

    methods: {
        handleDialogMousedown (event) {
            const path = getEventPath(event);
            if (!path.some(el => el.classList && el.classList.contains('el-dialog'))) {
                this.currentVisible = false;
            }
        },
    },
};
