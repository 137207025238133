import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { ContractType } from '@/types/ContractType';

const baseUri = '/contract_locations';

export const getContractLocations = (params?: object): Promise<PagedCollection<ContractType>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getContractLocation = (id: string, params?: object): Promise<ContractType> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createContractLocation = (data: object, params?: object): Promise<ContractType> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
        params,
    });

export const updateContractLocation = (id: string, data: object, params?: object): Promise<ContractType> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
        params,
    });

export const deleteContractLocation = (id: string, params?: object): Promise<null> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
        params,
    });
