import * as ProductAPI from '@/api/contractor/dealProduct';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return ProductAPI.getProducts(params);
    },
    doFetchSingle (id) {
        return ProductAPI.getProduct(id);
    },
});
