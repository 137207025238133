import * as FixedAsset from '@/api/possessions/fixedAssets/fixedAsset';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return FixedAsset.getFixedAssets(params);
    },
    doFetchSingle (id, params) {
        return FixedAsset.getFixedAsset(id, params);
    },
    doCreate (data, params) {
        return FixedAsset.createFixedAsset(data, params);
    },
    doUpdate (id, data, params) {
        return FixedAsset.editFixedAsset(id, data, params);
    },
    doDelete (id) {
        return FixedAsset.deleteFixedAsset(id);
    },
});
