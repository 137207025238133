import request from '@/utils/request';

export const baseUri = '/funding_claims';

export const getClaims = (params) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getClaim = (id, params) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const updateClaim = (id, data) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });

export const createClaim = (data) =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });

export const deleteClaim = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });

export const getClaimPayments = (id, params) =>
    request({
        url: `${baseUri}/${id}/payments`,
        method: 'GET',
        params,
    });
