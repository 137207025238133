import WorkingModeAPI from '@/api/staff/workingMode';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return WorkingModeAPI.findAll(params);
    },
    doFetchSingle (id) {
        return WorkingModeAPI.find(id);
    },
    doCreate (data) {
        return WorkingModeAPI.create(data);
    },
    doUpdate (id, data) {
        return WorkingModeAPI.update(id, data);
    },
    doDelete (id) {
        return WorkingModeAPI.delete(id);
    },
});
