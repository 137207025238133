import request from '@/utils/request';
import { PagedCollection } from "@/types/hydra";
import { ContractStage } from "@/types/Contract";

const baseUri = '/contract_stages';

export const getContractStages = (params: object): Promise<PagedCollection<ContractStage>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getContractStage = (id: string, params?: object): Promise<ContractStage> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createContractStage = (data: object): Promise<ContractStage> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });

export const deleteContractStage = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });

export const updateContractStage = (id: string, data: object, params?: object): Promise<ContractStage> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
        params
    });
