import { getContractStage, createContractStage, deleteContractStage, getContractStages, updateContractStage } from '@/api/contractStage';
import { createCrudModule } from '@/utils/vuex-crud';
import Vue from 'vue';
import module from '@/store/modules/fundingClaimPaymentStage';

const module = createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return getContractStages(params);
    },
    doFetchSingle (id: string, params?: object) {
        return getContractStage(id, params);
    },
    doCreate (data: object) {
        return createContractStage(data);
    },
    doUpdate (id: string, data: object, params?: object) {
        return updateContractStage(id, data, params);
    },
    doDelete (id: string) {
        return deleteContractStage(id);
    }
});

Object.assign(module.mutations, {
    RESET_STAGE_RULES (state, id) {
        Vue.set(state.entities[id], 'rules', []);
    }
});

Object.assign(module.actions, {
    resetStageRules ({ commit }, { id }) {
        commit('RESET_STAGE_RULES', id);
    }
});

export default module;
