import creditCardType from 'credit-card-type';

const typesWithIcon = ['maestro', 'mastercard', 'mir', 'visa'];

export default {
    methods: {
        formatCreditCardNumber (number) {
            return number.replace(/\D+/g, '').replace(/(.{4})/g, '$1 ').trim();
        },

        getCreditCardIconName (number) {
            if (number) {
                const cards = creditCardType(number);

                if (cards.length > 0 && typesWithIcon.includes(cards[0].type)) {
                    const type = cards[0].type;

                    if (type === 'mir') {
                        return type;
                    } else {
                        return `logos:${type}`;
                    }
                }
            }

            return 'bi:credit-card-2-front-fill';
        },
    },
};
