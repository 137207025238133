import { createAnalyticEntity, deleteAnalyticEntity, getAnalyticEntities, getAnalyticEntity, updateAnalyticEntity } from '@/api/analyticEntity';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return getAnalyticEntities(params);
    },
    doFetchSingle (id: string) {
        return getAnalyticEntity(id);
    },
    doCreate (data: object) {
        return createAnalyticEntity(data);
    },
    doUpdate (id: string, data: object) {
        return updateAnalyticEntity(id, data);
    },
    doDelete (id: string) {
        return deleteAnalyticEntity(id);
    },
});
