<template>
    <div
        class="money-value"
        :class="{ 'is-colorized': colorized, 'is-positive': isPositive, 'is-negative': isNegative }"
        v-html="formattedValue"
    />
</template>

<script>
import FormatMixin from '@/mixins/format';

export default {
    name: 'UiMoneyValue',

    mixins: [FormatMixin],

    props: {
        value: {
            type: [Number, String],
            required: true,
        },

        currency: {
            type: String,
            default: 'RUB',
        },

        colorized: {
            type: Boolean,
            default: true,
        },

        absolute: {
            type: Boolean,
            default: false,
        },

        hideZero: {
            type: Boolean,
            default: true,
        },

        hidePlus: {
            type: Boolean,
            default: false,
        },

        hideCurrency: {
            type: Boolean,
            default: false,
        },

        withoutDecimals: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        formattedValue () {
            if (this.hideZero && !this.tmpValue) {
                return '';
            }

            const options = {};

            if (!this.hideCurrency) {
                options.style = 'currency';
                options.currency = this.currency;
            } else {
                options.style = 'decimal';
                options.minimumFractionDigits = 2;
            }

            if (this.withoutDecimals) {
                options.minimumFractionDigits = 0;
                options.maximumFractionDigits = 0;
            }

            const formatter = new Intl.NumberFormat('ru', options);

            let value = '';
            const formatParts = formatter.formatToParts(Number(this.tmpValue));
            formatParts.forEach(formatPart => {
                if (formatPart.type === 'decimal') {
                    value += '<sub>' + formatPart.value;
                } else if (formatPart.type === 'fraction') {
                    value += formatPart.value + '</sub>';
                } else {
                    value += formatPart.value;
                }
            });

            return (Number(this.tmpValue) > 0 && !this.absolute && !this.hidePlus ? '+' : '') + value;
        },

        tmpValue () {
            return this.absolute ? Math.abs(Number(this.value)) : Number(this.value);
        },

        isPositive () {
            return this.value && this.colorized && Number(this.value) > 0;
        },

        isNegative () {
            return this.value && this.colorized && Number(this.value) < 0;
        },
    },
};
</script>
