import CurrencyAPI from '@/api/currency';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    identifier: 'code',
    only: ['FETCH_LIST'],
    doFetchList (params) {
        return CurrencyAPI.findAll(params);
    },
});
