import * as Product from '@/api/possessions/product/productInventory';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return Product.getProducts(params);
    },
    doFetchSingle (id: string, params: object) {
        return Product.getProduct(id, params);
    },
    doCreate (data: object) {
        return Product.createProduct(data);
    },
    doUpdate (id: string, data: object) {
        return Product.updateProduct(id, data);
    },
    doDelete (id: string) {
        return Product.deleteProduct(id);
    },
});
