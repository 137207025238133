<template>
    <div
        ref="messageEl"
        class="chat-message"
    >
        <div
            class="chat-message__content"
            v-html="getAnchoredText(message)"
        />
        <div class="chat-message__time">
            {{ formatDate(date, 'HH:mm') }}
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'UiChatMessage'
});
</script>

<script setup lang="ts">
import { useFormat } from '@/composable/useFormat';
import { parse } from 'marked';
import { useIntersectionObserver } from '@vueuse/core';
import { ref, watch } from 'vue';
import DOMPurify from 'dompurify';
import { throttle } from 'lodash';

const { formatDate } = useFormat();

const emit = defineEmits<{
    (e: 'visibility-change', value: boolean): void
}>();

interface Props {
    date: string,
    message?: string
}

withDefaults(defineProps<Props>(), {
    message: ''
});

const messageEl = ref(null);
const isVisible = ref(false);
const { stop } = useIntersectionObserver(
    messageEl,
    throttle(([{ isIntersecting }]) => {
        isVisible.value = isIntersecting;
    }, 500)
);

watch(isVisible, (val) => {
    emit('visibility-change', val);
});

function getAnchoredText (text: string) {
    return DOMPurify.sanitize(parse(text) as string);
}
</script>

<style lang="scss">
.chat-message {
    max-width: 420px;
    background-color: $--color-primary-light-9;
    padding: 4px 8px 16px;
    border-radius: $--border-radius-base;
    position: relative;
    font-size: 14px;
    line-height: 18px;
    word-break: normal;

    p {
        margin: 0;
    }

    &__time {
        position: absolute;
        bottom: 4px;
        right: 8px;
        font-size: 11px;
        line-height: 12px;
        color: $--color-text-secondary;
    }

    &__content {
        word-break: break-word;
    }
}
</style>
