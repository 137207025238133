import PositionAPI from '@/api/staff/position';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return PositionAPI.findAll(params);
    },
    doFetchSingle (id) {
        return PositionAPI.find(id);
    },
    doCreate (data) {
        return PositionAPI.create(data);
    },
    doUpdate (id, data) {
        return PositionAPI.update(id, data);
    },
    doDelete (id) {
        return PositionAPI.delete(id);
    },
});
