import TimesheetAPI from '@/api/staff/timesheet';
import { createCrudModule } from '@/utils/vuex-crud';

const module = createCrudModule({
    businessDependent: true,
    only: ['FETCH_LIST', 'RESET'],
    doFetchList (params) {
        return TimesheetAPI.findAll(params);
    },
});

Object.assign(module.state, {
    isSettingValue: false,
    setValueError: null,
});

Object.assign(module.mutations, {
    SET_VALUE_START (state) {
        state.isSettingValue = true;
    },
    SET_VALUE_SUCCESS (state) {
        state.isSettingValue = false;
        state.setValueError = null;
    },
    SET_VALUE_ERROR (state, error) {
        state.isSettingValue = false;
        state.setValueError = error;
    },
});

Object.assign(module.actions, {
    async setValue ({ commit }, { month, employee, workingMode, type, value }) {
        commit('SET_VALUE_START');
        try {
            await TimesheetAPI.setValue({
                month,
                employee,
                workingMode,
                type,
                value,
            });
            commit('SET_VALUE_SUCCESS');
            return true;
        } catch (error) {
            console.error(error);
            commit('SET_VALUE_ERROR', error);
            return false;
        }
    },
});

export default module;
