import request from '@/utils/request';
import { PagedCollection } from "@/types/hydra";
import { AnalyticEntity } from "@/types/AnalyticEntity";

const baseUri = '/analytic_entities';

export const getAnalyticEntities = (params?: object): Promise<PagedCollection<AnalyticEntity>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getAnalyticEntity = (id: string): Promise<AnalyticEntity> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    });

export const createAnalyticEntity = (data: object): Promise<AnalyticEntity> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });

export const updateAnalyticEntity = (id: string, data: object): Promise<AnalyticEntity> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });

export const deleteAnalyticEntity = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });
