<template>
    <ui-select-cascader-popover
        :items="currentItems"
        :fetch-items="fetchItems"
    >
        <el-input />
    </ui-select-cascader-popover>
</template>

<script lang="ts">
import Vue from 'vue';


export default Vue.extend({
    name: 'UiSelectCascader'
});
</script>

<script setup lang="ts">
import { computed, ref } from 'vue';

interface Props {
    fetchItems: (query: string, parent: any, cb: (items: any[]) => void) => void,
    value: string | string[]
}

const emit = defineEmits<{
    (e: 'input', value: boolean): void
}>();

const props = defineProps<Props>();

const currentItems = ref([]);
const currentValue = computed({
    get () {
        return props.value;
    },
    set (val) {
        emit('input', val);
    }
});
</script>

<style scoped lang="scss">

</style>
