import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { getTopWindow } from '@/utils';

const bus = new Vue();
const eventPrefix = 'eventBus:';

export function initialize () {
    getTopWindow().addEventListener('message', ({ source, data }) => {
        // noinspection JSIncompatibleTypesComparison
        if (
            window.self === source ||
            !(data?.event ?? '').startsWith(eventPrefix)
        ) {
            return;
        }

        const eventName = data?.event.substring(eventPrefix.length);
        // Ищем в шине событий подписку на событие с фрейма (костыльно)
        if (eventName in bus._events) {
            bus.$emit(eventName, ...data.args);
        }
    });
}

export default {
    /**
     * @param {string|string[]} event
     * @param {Function} callback
     * @return {void}
     */
    on (event, callback) {
        bus.$on(event, callback);
    },

    /**
     * @param {string|string[]} event
     * @param {Function} callback
     * @return {void}
     */
    once (event, callback) {
        bus.$once(event, callback);
    },

    /**
     * @param {string|string[]} event
     * @param {Function} [callback]
     * @return {void}
     */
    off (event, callback) {
        bus.$off(event, callback);
    },

    /**
     * @param {string} event
     * @param {...any} args
     * @return {void}
     */
    emit (event, ...args) {
        bus.$emit(event, ...args);

        // Кидаем событие в родительское окно (если оно есть)
        if (window.self !== window.parent) {
            window.parent.postMessage({
                event: eventPrefix + event,
                args: [...cloneDeep(args)],
            }, window.self.location.origin);
        }
    },
};
