<template>
    <div
        class="expandable-item"
    >
        <div
            v-if="$slots.title"
            class="expandable-item__header"
            @click.prevent.stop="toggleExpanded"
        >
            <iconify-icon
                v-if="clickable"
                class="expandable-item__icon"
                :icon="expanded ? 'fa6-solid:minus' : 'fa6-solid:plus'"
            />
            <span class="expandable-item__title">
                <slot name="title" />
            </span>
        </div>
        <div v-show="currentExpanded && $slots.title">
            <slot name="content" />
        </div>

        <slot
            v-if="!$slots.title"
            name="content"
        />
    </div>
</template>

<script>
export default {
    name: 'UiExpandableItem',

    props: {
        clickable: {
            type: Boolean,
            default: true,
        },

        expanded: {
            type: Boolean,
            default: false,
            required: true,
        },
    },

    computed: {
        currentExpanded: {
            get () {
                return this.expanded;
            },

            set (value) {
                this.$emit('update:expanded', value);
            },
        },
    },

    methods: {
        toggleExpanded () {
            if (this.clickable) {
                this.currentExpanded = !this.currentExpanded;
                this.$emit('expand-change', this.currentExpanded);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.expandable-item {
    &__header {
        cursor: pointer;
        display: flex;
    }

    &__icon {
        background-color: $--color-primary-light-5;
        border-radius: 4px;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        text-align: center;
        color: #ffffff;
        font-weight: bold;
    }

    &__title {
        margin-left: 5px;
        display: flex;
        align-items: center;

        & > div {
            padding-left: 10px
        }
    }

    &__content {
        margin-left: 15px;
    }
}
</style>
