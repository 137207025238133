import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { TransactionImport } from '@/types/MoneyTransaction';

const baseUri = '/transaction_imports';

export const getTransactionImports = (params: object): Promise<PagedCollection<TransactionImport>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getTransactionImport = (id: string, params?: object): Promise<TransactionImport> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createTransactionImport = (data: any): Promise<TransactionImport> => {
    const formData = new FormData();

    Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
    });

    return request({
        url: baseUri,
        method: 'POST',
        data: formData,
    });
};

export const executeImport = (id: string, data: object): Promise<any> =>
    request({
        url: `${baseUri}/${id}/execute`,
        method: 'POST',
        data,
    });

export const deleteTransactionImport = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });

export const updateTransactionImport = (id: string, data: object): Promise<TransactionImport> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });
