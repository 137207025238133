<template>
    <router-link
        to="/"
        class="logo"
    >
        {{ logoText }}
    </router-link>
</template>

<script>
export default {
    name: 'UiLogo',

    computed: {
        logoText () {
            const env = import.meta.env;
            if (env.MODE === 'preprod') {
                return 'ТЕСТ-ФИНИК';
            }

            return 'ФИНИК';
        },
    },
};
</script>
