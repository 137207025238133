import type { PluginFunction } from 'vue';
import Vue, { ref } from 'vue';
import { UnleashClient } from 'unleash-proxy-client';

const env = import.meta.env;

export const client = ref(new UnleashClient({
    url: env.VITE_UNLEASH_URL,
    appName: env.VITE_UNLEASH_APP_NAME,
    clientKey: env.VITE_UNLEASH_CLIENT_KEY,
    refreshInterval: env.VITE_UNLEASH_REFRESH_INTERVAL,
    disableMetrics: true,
}));

export const isFeatureEnabled = (name: string) => client.value.isEnabled(name);

export default ((app: typeof Vue): void => {
    client.value.start();
}) as PluginFunction<any>;
