import { createRecruitment, getRecruitment, updateRecruitment } from '@/api/staff/personnelDocument';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchSingle (id, params) {
        return getRecruitment(id, params);
    },
    doCreate (data) {
        return createRecruitment(data);
    },
    doUpdate (id, data) {
        return updateRecruitment(id, data);
    },
});
