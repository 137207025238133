import { getDocument, getDocuments } from '@/api/integration/businessIntegration';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return getDocuments(params);
    },
    doFetchSingle (id, params) {
        return getDocument(id, params);
    },
});
