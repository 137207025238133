<template>
    <ui-dialog
        :title="`Настройка списка '${title}'`"
        :visible.sync="currentVisible"
        width="450px"
    >
        <el-form
            ref="form"
            v-loading="isLoading"
            class="table-settings-form"
            label-width="80px"
        >
            <el-checkbox-group v-model="selectedItems">
                <el-checkbox
                    v-for="item in formList"
                    :key="item.prop"
                    :label="item.prop"
                    class="table-settings-form__checkbox"
                >
                    {{ item.label }}
                </el-checkbox>
            </el-checkbox-group>
        </el-form>

        <template slot="footer">
            <el-button
                type="flat"
                @click="currentVisible = false"
            >
                Отменить
            </el-button>
            <el-button
                type="primary"
                :loading="isLoading"
                :disabled="isLoading || selectedItems.length === 0"
                @click="handleSubmit"
            >
                Сохранить
            </el-button>
        </template>
    </ui-dialog>
</template>

<script lang="ts">
import DialogMixin from '@/mixins/dialog';
import { changeTableSettings } from '@/api/tableSettings';
import Vue from 'vue';
import { uniq } from 'lodash';

export interface ColumnRow {
    label: string,
    property: string,
    defaultVisible: boolean,
}

export interface SettingRow {
    columns: [string],
}

export default Vue.extend({
    mixins: [DialogMixin],

    props: {
        columns: {
            type: Array as () => ColumnRow[],
            default: null,
        },

        settings: {
            type: Object as () => SettingRow,
            default: null,
        },
    },

    data () {
        return {
            isLoading: false,
            selectedItems: [],
        };
    },

    computed: {
        title () {
            return this.$route.meta.title;
        },

        business () {
            return this.$store.getters['business/current'];
        },

        formList () {
            return this.columns?.map(i => {
                return {
                    label: i.label,
                    prop: i.property,
                    defaultVisible: i.defaultVisible,
                };
            });
        },
    },

    watch: {
        visible () {
            if (this.settings?.columns?.length > 0) {
                this.selectedItems = this.settings.columns;
            } else {
                this.columns?.forEach(i => {
                    if (i.defaultVisible) {
                        this.selectedItems.push(i.property);
                    }
                });
            }
        },
    },

    methods: {
        async handleSubmit () {
            this.isLoading = true;
            try {
                await changeTableSettings(this.$route.name, {
                    business: this.business.id,
                    settings: {
                        columns: uniq(this.selectedItems),
                    },
                });
                if (this.settings) {
                    this.$store.commit('tableSettings/SET_ENTITY', {
                        contextId: this.$route.name,
                        settings: {
                            columns: uniq(this.selectedItems),
                        },
                    });
                } else {
                    this.$store.dispatch('tableSettings/fetchList');
                }

                this.$emit('persist');
                this.currentVisible = false;
            } catch (e) {
                this.$flashError(e as Error);
            }
            this.isLoading = false;
        },
    },
});
</script>


<style lang="scss">
.table-settings-form {
    &__checkbox {
        width: 185px;
        margin: 10px;
    }
}
</style>
