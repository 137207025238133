import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { Contractor } from '@/types/Contractor';

const baseUri = '/contractors';

export const getContractors = (params: object): Promise<PagedCollection<Contractor>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getContractor = (id: string, params: object): Promise<Contractor> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createContractor = (data: object): Promise<Contractor> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });

export const updateContractor = (id: string, data: object): Promise<Contractor> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });

export const deleteContractor = (id: string): Promise<Contractor> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });
