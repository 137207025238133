<template>
    <ui-table-filter-select
        :key="selectKey"
        v-model="currentValue"
        v-bind="proxyProps"
        :fetch-items="fetchItems"
        :init-method="fetchInitItems"
        :multiple="multiple"
        :fetch-on-create="multiple ? currentValue.length > 0 : currentValue"
        v-on="$listeners"
    >
        <template #option="{ item }">
            <div :style="getOptionStyle(item)">
                {{ item.name }}
            </div>
        </template>
    </ui-table-filter-select>
</template>
<script>
import { pickBy } from 'lodash';
import WrapperMixin from '@/mixins/wrapper';
import { getContractTypes } from '@/api/contractType';

export default {
    name: 'UiTableFilterContractType',

    mixins: [WrapperMixin],

    props: {
        value: {
            type: [String, Array],
            default: '',
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        category: {
            type: [String, Array],
            default: '',
        },
    },

    data () {
        return {
            selectKey: 0,
        };
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (value) {
                this.$emit('input', value);
            },
        },

        business () {
            return this.$store.getters['business/current'];
        },
    },

    watch: {
        category: {
            handler () {
                this.selectKey++;
                if (this.value.length > 0) {
                    this.$emit('clear');
                }
            },

            immediate: true,
        },
    },

    methods: {
        async fetchItems (query, cb) {
            const params = {
                ...this.fetchParams,
                business: this.business?.id,
                order: {
                    name: 'asc'
                }
            };

            if (query) {
                params.name = query;
            }
            if (this.category) {
                params.category = this.category;
            }

            try {
                const data = await getContractTypes(pickBy(params));
                const items = data['hydra:member'] || [];
                items.push({
                    name: "Без типа",
                    id: 'without_contract_type',
                    active: true
                });
                cb(items);
            } catch (e) {
                this.$flashError(e);
            }
        },

        async fetchInitItems (items) {
            const params = {
                business: this.business?.id,
                id: items.filter(item => item !== 'without_contract_type'),
            };

            try {
                const data = await getContractTypes(pickBy(params));
                const items = data['hydra:member'] || [];
                items.push({
                    name: "Без типа",
                    id: 'without_contract_type',
                    active: true
                });
                return items;
            } catch (e) {
                this.$flashError(e);
                return [];
            }
        },

        getOptionStyle (item) {
            const style = {
                paddingLeft: `${item.level * 20}px`,
            };

            if (!item.active) {
                style.color = '#9daab6';
            }

            return style;
        },
    },
};
</script>
