import { createBusinessActivity, deleteBusinessActivity, getBusinessActivities, getBusinessActivity, updateBusinessActivity } from '@/api/businessActivity';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return getBusinessActivities(params);
    },
    doFetchSingle (id: string, params: object) {
        return getBusinessActivity(id, params);
    },
    doCreate (data: object) {
        return createBusinessActivity(data);
    },
    doUpdate (id: string, data: object) {
        return updateBusinessActivity(id, data);
    },
    doDelete (id: string) {
        return deleteBusinessActivity(id);
    },
});
