<template>
    <div
        class="route-drawer-item"
        :class="{ 'is-loading' : loading, 'is-modal': modal }"
        :style="{ width: currentWidth, maxWidth: `calc(100% - ${64 * (index + 1)}px` }"
    >
        <ui-loading
            v-if="loading"
            height="100%"
        />
        <iframe
            v-show="!loading"
            v-iframeresize="resizeOptions"
            :src="iframeSrc"
            class="route-drawer-item__frame"
            @load="handleIframeLoad"
        />

        <el-button
            :type="!modal ? 'primary' : 'flat'"
            class="route-drawer-item__close"
            @click="close"
        >
            <iconify-icon icon="akar-icons:cross" />
        </el-button>
    </div>
</template>

<script>
export default {
    name: 'UiRouteDrawerItem',

    props: {
        route: {
            type: Object,
            required: true,
        },

        index: {
            type: Number,
            default: 0,
        },

        modal: {
            type: Boolean,
            default: false,
        },

        width: {
            type: [Number, String],
            default: '',
        },
    },

    data () {
        return {
            loading: true,
        };
    },

    computed: {
        iframeSrc () {
            // noinspection JSCheckFunctionSignatures
            const { href } = this.$router.resolve(this.route);

            return href;
        },

        currentWidth () {
            if (typeof this.width === 'number') {
                return `${this.width}px`;
            }

            if (this.width) {
                return this.width;
            }

            return !this.modal ? '100%' : '';
        },

        resizeOptions () {
            if (!this.modal) {
                return false;
            }

            return {
                maxHeight: 'calc(100vh - 128px)',
                scrolling: true,
            };
        },
    },

    methods: {
        close () {
            this.$emit('close');
        },

        keyupListener (event) {
            if (event.keyCode === 27) {
                this.close();
            }
        },

        handleIframeLoad (event) {
            event.target.contentWindow.addEventListener('keyup', this.keyupListener);
            this.loading = false;
        },
    },
};
</script>

<style lang="scss">
.route-drawer-item {
    $b: &;

    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $--body-background-color;
    box-shadow: $--box-shadow-base;
    transition: all .3s ease;

    &__frame {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        border: 0;
    }

    &__close {
        position: absolute;
        top: 10px;
        left: -36px;
        width: 36px;
        height: 36px;
        padding: 0;
        border-radius: 18px 0 0 18px;
    }

    &:not(.is-modal) {
        &.transition-enter,
        &.transition-leave-to {
            transform: translateX(100%);
        }

        &.transition-enter-to {
            transform: translateX(0);
        }
    }

    &.is-modal {
        top: 64px;
        left: 50%;
        overflow: hidden;
        width: 800px;
        height: auto;
        min-height: 100px;
        transform: translate(-50%, 0);
        border-radius: $--border-radius-base;
        background: transparent;

        #{$b}__frame {
            border-radius: $--border-radius-base;
        }

        #{$b}__close {
            top: 15px;
            right: 15px;
            left: auto;
            border-radius: $--border-radius-base;
        }

        &.transition-enter,
        &.transition-leave-to {
            top: -100%;
            opacity: 0;
        }

        &.transition-enter-to {
            top: 64px;
            opacity: 1;
        }
    }
}
</style>
