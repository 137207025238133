/* eslint comma-dangle: ["error", "always-multiline"] */
export default {
    choose: 'Выбрать',
    chooseAccount: 'Выбрать счет',
    chooseAccountGroup: 'Выбрать группу счетов',
    chooseBusiness: 'Выбрать бизнес',
    indicatedWhenFilling: 'Указывается при заполнении',
    input: 'Введите название',
    withoutGrouping: 'Без группировки',
    writeMessage: 'Написать сообщение...',
};
