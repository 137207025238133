import request from '@/utils/request';
import { Group } from '@/types/Notification';

const baseUri = '/notification_groups';

export const getNotificationGroups = (): Promise<{ groups: Group[] }> =>
    request({
        url: baseUri,
        method: 'GET'
    });
