<template>
    <el-drawer
        ref="drawer"
        v-bind="proxyProps"
        v-on="$listeners"
        @mousedown.native="handleDrawerMousedown"
        @open="handleOpen"
    >
        <slot />
        <portal-target
            :name="panelTarget"
            slim
        />
    </el-drawer>
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';
import { getEventPath } from '@/utils';

export default {
    name: 'UiDrawer',

    mixins: [WrapperMixin],

    props: {
        nestedOffset: {
            type: Number,
            default: 80,
        },
        panelTarget: {
            type: String,
            default: 'drawer-panel',
        },
    },

    methods: {
        getDefaultProps () {
            return {
                size: 'calc(100% - 240px)',
                appendToBody: true,
                destroyOnClose: true,
                wrapperClosable: false,
            };
        },

        handleDrawerMousedown (event) {
            const path = getEventPath(event);
            if (
                this.$refs.drawer &&
                !path.some(el => el.classList && el.classList.contains('el-drawer'))
            ) {
                this.$refs.drawer.closeDrawer();
            }
        },

        handleOpen () {
            setTimeout(() => {
                const prevSibling = this.$refs.drawer.$el.previousElementSibling;
                if (prevSibling.classList.contains('el-drawer__wrapper')) {
                    const width = prevSibling.querySelector('.el-drawer').clientWidth;
                    this.$refs.drawer.$el.querySelector('.el-drawer').style.maxWidth = `${width - this.nestedOffset}px`;
                }
                this.$emit('open');
            }, 1);
        },
    },
};
</script>
