import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { ContractType } from '@/types/Contract';

const baseUri = '/contract_types';

export const getContractTypes = (params?: object): Promise<PagedCollection<ContractType>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getContractTypesHasContracts = (params?: object): Promise<PagedCollection<ContractType>> =>
    request({
        url: `${baseUri}/has_contracts`,
        method: 'GET',
        params,
    });

export const getContractTypesHasInternalContracts = (params?: object): Promise<PagedCollection<ContractType>> =>
    request({
        url: `${baseUri}/has_internal_documents`,
        method: 'GET',
        params,
    });

export const getContractType = (id: string, params?: object): Promise<ContractType> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createContractType = (data: object, params?: object): Promise<ContractType> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
        params,
    });

export const updateContractType = (id: string, data: object, params?: object): Promise<ContractType> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
        params,
    });

export const deleteContractType = (id: string, params?: object): Promise<null> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
        params,
    });
