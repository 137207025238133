import { findRouteRecursively, getFirstChildRoute } from '@/utils/route';
import store from '@/store';
import { isFeatureEnabled } from '@/plugins/unleash';
import { AccessTypes } from '@/enums';

export default {
    methods: {
        hasRolesInBusiness (businessSlug) {
            return this.$store.state.security.businessMemberships.some(bm => bm.businessSlug === businessSlug);
        },

        filterRoutes (routes = []) {
            const result = [];

            routes.forEach(route => {
                if (route?.meta?.feature && !isFeatureEnabled(route.meta.feature)) {
                    return true;
                }

                const r = { ...route };
                const businessSettings = store.getters['business/current']?.settings ?? {};

                if (r.meta?.businessFlag && !businessSettings[r.meta.businessFlag]) {
                    return true;
                }

                if (this.hasRouteAccess(r)) {
                    if (r.children) {
                        r.children = this.filterRoutes(r.children);
                    }
                    result.push(r);
                }
            });

            return result;
        },

        hasRouteAccess (route) {
            // TODO
            if (route?.matched) {
                for (const r of [...route.matched].reverse()) {
                    if (typeof r.meta?.hasRouteAccess === 'function') {
                        return r.meta.hasRouteAccess();
                    }
                }
            } else if (typeof route.meta?.hasRouteAccess === 'function') {
                return route.meta.hasRouteAccess();
            }

            // TODO как-то костыльно, мб переделать
            if (route.params?.businessSlug && !this.hasRolesInBusiness(route.params?.businessSlug)) {
                return false;
            }

            // Когда нужно проверить по свойству из /users/me (вне бизнеса)
            let permissionAttributes = [];
            if (route.meta?.perms && Array.isArray(route.meta.perms)) {
                permissionAttributes = route.meta.perms;
            }

            const businessId = store.getters['business/currentId'];

            let isDenied = false;
            isDenied = permissionAttributes.length > 0;
            for (const attribute of permissionAttributes) {
                const codes = this.$store.getters['security/filterCodes'](attribute, businessId).filter(i => i);
                if (codes && [AccessTypes.BASIC, AccessTypes.EXTENDED].some(type => codes.includes(type))) {
                    if (!codes?.includes(AccessTypes.EXTENDED)) {
                        return false;
                    }
                }

                if (businessId) {
                    if (this.$store.getters['security/hasAccess'](attribute, businessId)) {
                        isDenied = false;
                        break;
                    }
                } else {
                    for (const key of Object.keys(store.getters['security/permissions']())) {
                        if (this.$store.getters['security/hasAccess'](attribute, key)) {
                            isDenied = false;
                            break;
                        }
                    }
                }
            }

            if (this.$route.name === 'BusinessDetail') {
                const routeWithChildren = findRouteRecursively(this.$route, this.$router.options.routes);
                const redirectRoute = getFirstChildRoute(routeWithChildren);
                if (!redirectRoute) {
                    isDenied = true;
                }
            }

            return !isDenied;
        },
    },
};
