import type { PluginFunction } from 'vue';
import Vue from 'vue';
import tinycolor from 'tinycolor2';

declare module 'vue/types/vue' {
    interface Vue {
        readonly $tinycolor: tinycolor.Constructor;
    }
}

export default ((app: typeof Vue): void => {
    if (app.prototype.$tinycolor) {
        return;
    }

    Object.defineProperties(app.prototype, {
        $tinycolor: {
            get (): tinycolor.Constructor {
                return tinycolor;
            },
        },
    });
}) as PluginFunction<any>;
