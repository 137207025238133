import EmployeeAPI from '@/api/staff/employee';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return EmployeeAPI.findAll(params);
    },
    doFetchSingle (id: string, params: object) {
        return EmployeeAPI.find(id, params);
    },
    doCreate (data: object) {
        return EmployeeAPI.create(data);
    },
    doUpdate (id: string, data: object, params: object) {
        return EmployeeAPI.update(id, data, params);
    },
    doDelete (id: string) {
        return EmployeeAPI.delete(id);
    },
});
