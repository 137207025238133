import store from '@/store';
import BusinessAPI from '@/api/business';
import { createCrudModule } from '@/utils/vuex-crud';
import Vue from 'vue';

const module = createCrudModule({
    businessDependent: false,
    only: ['FETCH_LIST', 'FETCH_SINGLE', 'CREATE', 'DELETE', 'UPDATE'],
    doFetchList (params) {
        return BusinessAPI.findAll(params);
    },
    doFetchSingle (id) {
        return BusinessAPI.find(id);
    },
    doCreate (data) {
        return BusinessAPI.create(data);
    },
    doDelete (id) {
        return BusinessAPI.delete(id);
    },
    doUpdate (id, data) {
        return BusinessAPI.update(id, data);
    },
});

Object.assign(module.state, {
    currentId: '',
    isUpdating: false,
    updateError: null,
});

Object.assign(module.getters, {
    currentId: state => state.currentId,
    current: state => state.currentId ? state.entities[state.currentId] : null,
    currentMonthCloseDates: state => state.entities?.[state.currentId]?.settings?.monthCloseDates ?? null,
});

Object.assign(module.mutations, {
    SET_CURRENT (state, id) {
        state.currentId = id;
    },
    FETCH_SINGLE_BY_SLUG_START (state) {
        state.isFetchingSingle = true;
    },
    FETCH_SINGLE_BY_SLUG_SUCCESS (state, data) {
        Vue.set(state.entities, data?.id.toString(), data);
        state.isFetchingSingle = false;
        state.fetchSingleError = null;
    },
    FETCH_SINGLE_BY_SLUG_ERROR (state, error) {
        state.fetchSingleError = error;
        state.isFetchingSingle = false;
    },
    UPDATE_CURRENT (state, data) {
        Vue.set(state.entities, data.id, data);
    },
});

Object.assign(module.actions, {
    async fetchSingleBySlug ({ commit }, { slug, params = {} }) {
        commit('FETCH_SINGLE_BY_SLUG_START');
        try {
            const data = await BusinessAPI.findBySlug(slug, params);
            commit('FETCH_SINGLE_BY_SLUG_SUCCESS', data);
            return data;
        } catch (error) {
            console.error(error);
            commit('FETCH_SINGLE_BY_SLUG_ERROR', error);
            return null;
        }
    },

    changeCurrent ({ commit }, id) {
        commit('SET_CURRENT', id);
    },

    resetCurrent ({ commit, dispatch }) {
        commit('SET_CURRENT', '');

        // TODO: Может можно как-то переделать
        Object.entries(store._modules.root._children).forEach(([moduleName, module]) => {
            const meta = (module.state && module.state.meta) || {};
            if (meta.businessDependent && 'reset' in module._rawModule.actions) {
                dispatch(`${moduleName}/reset`, null, { root: true });
            }
        });
    },

    updateCurrent ({ commit }, data) {
        commit('UPDATE_CURRENT', data);
    },
});

export default module;
