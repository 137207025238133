import request from '@/utils/request';
import { Employee, EmployeeZupInfo } from '@/types/Employee';
import { PagedCollection } from '@/types/hydra';

const baseUri = '/staff/employees';

export default {
    find (id: string, params: object): Promise<Employee> {
        return request.get(`${baseUri}/${id}`, { params });
    },

    findAll (params: object): Promise<PagedCollection<Employee>> {
        return request.get(baseUri, { params });
    },

    create (payload = {}): Promise<Employee> {
        return request.post(baseUri, payload);
    },

    update (id: string, payload: object, params: object): Promise<Employee> {
        return request.patch(`${baseUri}/${id}`, payload, { params });
    },

    delete (id: string): Promise<void> {
        return request.delete(`${baseUri}/${id}`);
    },

    listAssessmentRules (id: string) {
        return request.get(`${baseUri}/${id}/assessment_rules`);
    },

    listAssessments (id: string) {
        return request.get(`${baseUri}/${id}/assessments`);
    },

    getZupInfo (id: string): Promise<EmployeeZupInfo> {
        return request.get(`${baseUri}/${id}/zup_info`);
    },

    getPresets (params: object) {
        return request({
            url: `${baseUri}/presets`,
            method: 'GET',
            params,
        });
    },

    getVacationReport (id: string, params: object) {
        return request.get(`${baseUri}/${id}/vacation_report`, { params });
    },
};
