import { getIntegrations } from '@/api/integration/tochkaBankIntegration';
import { PagedCollection } from '@/types/hydra';
import { TochkaIntegration } from '@/types/TochkaBank';

export default  {
    namespaced: true,
    state: {
        integrations: [],
        loading: false,
        fetchError: null,
    },

    getters: {
        currentIntegration: (state, getters, rootState, rootGetters) =>
            state.integrations.find(i => i.business?.id === rootGetters['business/currentId']) ?? null,

        isLoading: state => state.loading,

        accountById: state => (accountId: string) => state.integrations
            .flatMap(i => i.profiles)
            .flatMap(i => i.accounts)
            .find(i => i.id === accountId) ?? null
    },

    mutations: {
        FETCH_INTEGRATIONS_START (state) {
            state.loading = true;
        },
        FETCH_INTEGRATIONS_SUCCESS (state, data: PagedCollection<TochkaIntegration>) {
            state.integrations = data['hydra:member'] ?? [];
            state.loading = false;
            state.fetchError = null;
        },
        FETCH_INTEGRATIONS_ERROR (state, error: Error) {
            state.fetchError = error;
            state.integrations = [];
            state.loading = false;
        },
    },

    actions: {
        async fetchIntegrations ({ commit }, { params }){
            commit('FETCH_INTEGRATIONS_START');
            try {
                const data = await getIntegrations(params);
                commit('FETCH_INTEGRATIONS_SUCCESS', data);
                return data;
            } catch (e) {
                console.error(e);
                commit('FETCH_INTEGRATIONS_ERROR', e);
                return null;
            }
        }
    }
};
