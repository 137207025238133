import type { PluginFunction } from 'vue';
import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { CanceledError } from 'axios';

export default ((): void => {
    if (!import.meta.env.VITE_SENTRY_DSN) {
        return;
    }

    Sentry.init({
        Vue,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_SENTRY_ENV ?? 'dev',
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            Sentry.captureConsoleIntegration({
                levels: ['error'],
            }),
            Sentry.replayIntegration({
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
        beforeSend (event, hint) {
            const error = hint?.originalException;
            if (
                error instanceof CanceledError ||
                (error instanceof Error && error.message.match(/canceled/i))
            ) {
                return null;
            }

            return event;
        },
    });
}) as PluginFunction<any>;
