import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { SalaryReportRow } from '@/types/SalaryReport';
import { MIMETypes } from '@/enums';

const baseUri = '/staff/salary_report';

export const getRows = (params?: object): Promise<PagedCollection<SalaryReportRow>> =>
    request({
        url: `${baseUri}/rows`,
        method: 'GET',
        params,
    });

export const getRow = (id: string, params?: object): Promise<SalaryReportRow> =>
    request({
        url: `${baseUri}/rows/${id}`,
        method: 'GET',
        params,
    });

export const resync = (data: { business: string, month: string }): Promise<null> =>
    request({
        url: `${baseUri}/resync`,
        method: 'POST',
        data,
    });

export const exportSalaryReport = (params: object) =>
    request({
        url: `${baseUri}/export`,
        method: 'GET',
        params,
        responseType: 'arraybuffer',
        headers: {
            accept: MIMETypes.XLSX,
        },
    });
