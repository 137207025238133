import { getInvoiceProduct, getInvoiceProducts } from '@/api/contractor/invoiceProduct';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    only: ['FETCH_LIST', 'FETCH_LIST_MORE', 'FETCH_SINGLE', 'RESET'],
    doFetchList (params) {
        return getInvoiceProducts(params);
    },
    doFetchListMore (params) {
        return getInvoiceProducts(params);
    },
    doFetchSingle (id) {
        return getInvoiceProduct(id);
    },
});
