<template>
    <ui-table-filter-select
        v-model="currentValue"
        v-bind="proxyProps"
        :fetch-items="fetchContractorRequisites"
        :init-method="fetchInitContractorRequisites"
        :set-style="nested ? setStyle : () => {}"
        :disabled-item-method="disabledItemMethod"
        remote
        enable-select-group
        v-on="$listeners"
    />
</template>
<script>
import WrapperMixin from '@/mixins/wrapper';
import SelectNestedMixin from '@/mixins/select-nested';
import { TransactionTypes } from '@/enums';
import { getRequisites } from '@/api/requisite';
import { reorderNested } from '@/utils';
import { sortBy } from 'lodash';
import { getContractors } from '@/api/contractor/contractor';
import Businesses from '@/api/business';

export default {
    name: 'UiTableFilterContractorRequisites',

    mixins: [WrapperMixin, SelectNestedMixin],

    props: {
        multiple: {
            type: Boolean,
            default: true,
        },

        fetchOnCreate: {
            type: Boolean,
            default: false,
        },

        value: {
            type: [String, Array],
            default: '',
        },

        nested: {
            type: Boolean,
            default: true,
        },

        fetchParams: {
            type: Object,
            default: null,
        },
    },

    computed: {
        business () {
            return this.$store.getters['business/current'];
        },
    },

    methods: {
        reorderData (items) {
            return [...reorderNested(items, this.sortByField)].sort((a, b) => a.sort - b.sort);
        },

        async fetchContractorRequisites (query, cb) {
            const params = {
                business: this.business?.id || this.$route.query?.business,
                transactionClient: {},
                order: {
                    name: 'asc',
                },
            };

            if (this.type === TransactionTypes.TRANSFER) {
                params.transactionClient.type = [TransactionTypes.INNER_INCOME, TransactionTypes.INNER_OUTCOME];
            } else if (this.type !== 'all') {
                params.transactionClient.type = [this.type];
            }

            if (query) {
                params.contractorOrRequisiteName = query;
            }

            try {
                const data = await getRequisites(params);
                const items = data['hydra:member'].map(item => ({
                    ...item,
                    name: item.info.shortName,
                })) || [];
                cb(this.groupRequisitesByBusiness(items));
            } catch (e) {
                this.$flashError(e);
            }
        },

        async fetchInitContractorRequisites (items) {
            const params = {
                business: this.business?.id || this.$route.query?.business,
                order: {
                    'contractor.name': 'asc',
                },
                id: items,
            };

            if (this.type && this.type !== 'all') {
                params.transactionTypes = [this.type];
            }

            try {
                const [data, dataContractor, dataBusiness] = await Promise.all([
                    getRequisites(params),
                    getContractors(params),
                    Businesses.findAll(params),
                ]);
                const contractorsWithRequisites = data['hydra:member'].concat(dataContractor['hydra:member'], dataBusiness['hydra:member']);


                return contractorsWithRequisites.map(item => ({
                    id: item.id,
                    '@type': item['@type'],
                    name: item.info?.shortName || item.name,
                })) || [];
            } catch (e) {
                this.$flashError(e);
            }
        },

        groupRequisitesByBusiness (requisites) {
            const businesses = [];
            let groupItems = [];
            let sortCounter = 0;

            requisites.forEach(requisite => {
                const hasParent = businesses.some(business => {
                    const isContractorRequisite = requisite['@type'] === 'ContractorRequisite';
                    const isBusinessRequisite = requisite['@type'] === 'BusinessRequisite';

                    return (isContractorRequisite && business.id === requisite?.contractor?.id) ||
                        (isBusinessRequisite && business.id === requisite?.business?.id) ||
                        // у некоторых реквизитов выводится businessName вместо сущности бизнеса
                        business.id === requisite.businessName;
                });
                let parent = requisite?.contractor || requisite?.business || null;

                if (!parent && requisite?.businessName) {
                    parent = {
                        isGroup: true,
                        name: requisite.businessName,
                        id: requisite.businessName,
                    };
                }

                parent.isGroup = true;

                if (!groupItems.some(i => i.id === parent.id)) {
                    groupItems.push(parent);
                }

                const obj = {
                    ...requisite,
                    parent,
                    type: 'requisite',
                };

                if (hasParent || !obj.parent) {
                    businesses.push(obj);
                } else {
                    businesses.push({
                        ...parent,
                        type: 'parent',
                    }, obj);
                }
            });

            groupItems = sortBy(groupItems, 'name').map(i => {
                sortCounter++;
                return { ...i, sort: sortCounter };
            });

            return this.reorderData(businesses.map(i => {
                if (i.parent) {
                    const parent = groupItems.find(it => it.id === i.parent?.id);
                    return {
                        ...i,
                        sort: i.active ? parent.sort : parent.sort + 0.5,
                    };
                }
                return i;
            }));
        },

        disabledItemMethod (item) {
            return this.value.some(i => i === item.parentId);
        },
    },
};
</script>
