import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { ModulBankIntegration, ModulBankProfile } from '@/types/ModulBank';

const baseUri = '/modul_bank_integrations';

export const getModulBankIntegrations = (params: object): Promise<PagedCollection<ModulBankIntegration>> =>
    request({
        url: baseUri,
        method: 'GET',
        params
    });

export const createModulBankProfile = (data: object): Promise<ModulBankProfile> =>
    request({
        url: `${baseUri}/profiles`,
        method: 'POST',
        data
    });

export const deleteModulBankProfile = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/profiles/${id}`,
        method: 'DELETE'
    });

export const updateModulBankProfile = (id: string, data: object): Promise<ModulBankProfile> =>
    request({
        url: `${baseUri}/profiles/${id}`,
        method: 'PATCH',
        data
    });

export const refreshModulBankProfile = (id: string) =>
    request({
        url: `${baseUri}/profiles/${id}/refresh`,
        method: 'POST'
    });
