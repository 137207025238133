<template>
    <el-row
        :type="type"
        :gutter="gutter"
        style="flex-grow: 1"
    >
        <slot />
    </el-row>
</template>

<script>
export default {
    name: 'UiRow',

    props: {
        type: {
            type: String,
            default: 'flex',
        },

        gutter: {
            type: Number,
            default: 20,
        },
    },
};
</script>
