import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { PossessionProduct } from '@/types/Possession';

const baseUri = '/possessions/product_inventories';

export const getProducts = (params: object): Promise<PagedCollection<PossessionProduct>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });
export const getProduct = (id: string, params: object): Promise<PossessionProduct> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });
export const createProduct = (data: object): Promise<PossessionProduct> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });
export const deleteProduct = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });
export const updateProduct = (id: string, data: object): Promise<PossessionProduct> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });
