import type { PluginFunction } from 'vue';
import Vue from 'vue';

interface ScrollTo {
    (component: Vue, options: ScrollToOptions): void;
}

declare module 'vue/types/vue' {
    interface Vue {
        readonly $scrollTo: ScrollTo;
    }
}

export default ((app: typeof Vue): void => {
    if (app.prototype.$scrollTo) {
        return;
    }

    Object.defineProperties(app.prototype, {
        $scrollTo: {
            get (): ScrollTo {
                return (component: Vue, options: ScrollToOptions): void => {
                    let parentElement = component.$el.parentElement;
                    let hasScroll = parentElement && parentElement.scrollHeight > parentElement.clientHeight;

                    while (parentElement && !hasScroll) {
                        parentElement = parentElement.parentElement;

                        if (parentElement) {
                            hasScroll = parentElement.scrollHeight > parentElement.clientHeight;
                        }
                    }

                    if (!parentElement) {
                        return;
                    }

                    if (hasScroll) {
                        parentElement.scrollTo({
                            ...options,
                            top: parentElement?.scrollTop + (options.top ?? 0),
                        });
                    }
                };
            },
        },
    });
}) as PluginFunction<any>;
