import { getContractVersion, createContractVersion, getContractVersions } from '@/api/contractVersion';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    only: ['FETCH_LIST', 'FETCH_SINGLE', 'CREATE', 'RESET'],
    doFetchList (params: object) {
        return getContractVersions(params);
    },
    doFetchSingle (id: string) {
        return getContractVersion(id);
    },
    doCreate (data: object) {
        return createContractVersion(data);
    },
});