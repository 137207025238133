import request from '@/utils/request';

const baseUri = '/access_rights';

export const getAccessRights = (params) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getAccessRight = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    });
