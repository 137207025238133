<template>
    <el-tooltip
        :disabled="nativeTitle || !text || !isOverflow"
        :content="text"
        class="tooltiped-text"
    >
        <div ref="wrapperEl">
            <span
                ref="contentEl"
                :title="nativeTitle && isOverflow ? text : undefined"
            >
                {{ text }}
            </span>
        </div>
    </el-tooltip>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'UiTooltipedText'
});
</script>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useElementBounding, useElementSize } from '@vueuse/core';

interface Props {
    text?: string,
    nativeTitle?: boolean
}

withDefaults(defineProps<Props>(), {
    text: '',
    nativeTitle: false
});

const contentEl = ref(null);
const wrapperEl = ref(null);

const { width: contentWidth } = useElementBounding(contentEl);
const { width: wrapperWidth } = useElementBounding(wrapperEl);

const isOverflow = computed(() => contentWidth.value > wrapperWidth.value);
</script>

<style scoped lang="scss">
.tooltiped-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
