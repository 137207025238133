import { createLoanAnalytic, deleteLoanAnalytic, getLoanAnalytic, getLoanAnalytics, updateLoanAnalytic } from '@/api/contractor/loanAnalytic';
import { createCrudModule } from '@/utils/vuex-crud';

export const config = {
    businessDependent: true,
    doFetchList (params) {
        return getLoanAnalytics(params);
    },
    doFetchSingle (id) {
        return getLoanAnalytic(id);
    },
    doCreate (data) {
        return createLoanAnalytic(data);
    },
    doUpdate (id, data) {
        return updateLoanAnalytic(id, data);
    },
    doDelete (id) {
        return deleteLoanAnalytic(id);
    },
};

export default createCrudModule(config);
