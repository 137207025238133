<template>
    <div
        class="el-input"
        :class="{
            [`el-input--${size}`]: size,
            'el-input--suffix': showClear,
            'el-input-group': $slots.prepend || $slots.append,
            'el-input-group--prepend': $slots.prepend,
            'el-input-group--append': $slots.append,
            'is-disabled': inputDisabled
        }"
        @mouseover="hovering = true"
        @mouseleave="hovering = false"
    >
        <div
            v-if="$slots.prepend"
            class="el-input-group__prepend"
        >
            <slot name="prepend" />
        </div>

        <imask-input
            ref="input"
            v-model="currentValue"
            :disabled="inputDisabled"
            class="el-input__inner"
            v-bind="proxyProps"
            v-on="$listeners"
            @focus="focused = true"
            @blur="handleInputBlur"
        />
        <span
            v-if="showClear"
            class="el-input__suffix"
        >
            <span class="el-input__suffix-inner">
                <iconify-icon
                    icon="akar-icons:circle-x"
                    class="el-input__icon el-input__clear"
                    @click.native="clear"
                />
            </span>
        </span>

        <div
            v-if="$slots.append"
            class="el-input-group__append"
        >
            <slot name="append" />
        </div>
    </div>
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';
import { IMaskComponent } from 'vue-imask';

export default {
    name: 'UiMaskedInput',

    components: {
        'imask-input': IMaskComponent,
    },

    mixins: [WrapperMixin],

    inject: {
        elForm: {
            default: '',
        },
    },

    props: {
        value: {
            type: [String, Number],
            default: '',
        },

        size: {
            type: String,
            default: 'medium',
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        clearable: {
            type: Boolean,
            default: false,
        },

        validateEvent: {
            type: Boolean,
            default: true,
        },
    },

    data () {
        return {
            hover: false,
            focused: false,
        };
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (val) {
                this.$emit('input', val);
            },
        },

        textLength () {
            if (typeof this.value === 'number') {
                return String(this.value).length;
            }

            return (this.value || '').length;
        },

        showClear () {
            return this.clearable && (this.focused || this.hovering) && this.textLength > 0 && !this.inputDisabled;
        },

        inputDisabled () {
            return this.disabled || (this.elForm || {}).disabled;
        },
    },

    watch: {
        value (val) {
            if (this.validateEvent) {
                this.dispatch('ElFormItem', 'el.form.change', [val]);
            }
        },
    },

    methods: {
        clear () {
            this.$emit('input', '');
            this.$emit('clear');
            this.$emit('blur');
        },

        dispatch (componentName, eventName, params) {
            let parent = this.$parent || this.$root;
            let name = parent.$options.componentName;

            while (parent && (!name || name !== componentName)) {
                parent = parent.$parent;

                if (parent) {
                    name = parent.$options.componentName;
                }
            }
            if (parent) {
                parent.$emit(eventName, ...params);
            }
        },

        handleInputBlur (event) {
            this.focused = false;
            this.$emit('blur', event);
            if (this.validateEvent) {
                this.dispatch('ElFormItem', 'el.form.blur', [this.value]);
            }
        },
    },
};
</script>
