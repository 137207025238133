import request from '@/utils/request';

const baseUri = '/contract_stage_rules';

export const getContractStageRules = (params: object) =>
    request({
        url: baseUri,
        method: 'GET',
        params
    });

export const getContractStageRule = (id: string) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET'
    });

export const createContractStageRule = (data: object) =>
    request({
        url: baseUri,
        method: 'POST',
        data
    });

export const deleteContractStageRule = (id: string) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE'
    });

export const updateContractStageRule = (id: string, data: object) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data
    });