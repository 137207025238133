<template>
    <el-date-picker
        ref="datePicker"
        :picker-options="currentPickerOptions"
        v-bind="proxyProps"
        v-on="$listeners"
    />
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';

export default {
    name: 'UiDatePicker',

    mixins: [WrapperMixin],

    props: {
        pickerOptions: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        currentPickerOptions () {
            return {
                firstDayOfWeek: 1,
                ...this.pickerOptions,
            };
        },
    },
    methods: {
        getDefaultProps () {
            return {
                format: 'dd.MM.yyyy',
                valueFormat: 'yyyy-MM-dd',
                firstDayOfWeek: 1,
            };
        },
        focus () {
            this.$refs.datePicker.focus();
        },

    },
};
</script>
