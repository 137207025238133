import { createPayment, deletePayment, getPayment, getPayments, updatePayment } from '@/api/planning/fundingClaimPayment';
import { createCrudModule } from '@/utils/vuex-crud';
import { mapValues } from 'lodash';

const module = createCrudModule({
    businessDependent: true,
    doFetchList (params, loadMore) {
        return getPayments(params, loadMore);
    },
    doFetchSingle (id) {
        return getPayment(id);
    },
    doCreate (data, params) {
        return createPayment(data, params);
    },
    doUpdate (id, data, params) {
        return updatePayment(id, data, params);
    },
    doDelete (id) {
        return deletePayment(id);
    },
});

Object.assign(module.state, {
    filter: initFilter()
});

Object.assign(module.mutations, {
    SET_FILTER_VALUE (state, { key, value }) {
        if (state.filter[key]) {
            state.filter[key].value = value;
        }
    },

    SET_FILTER_ENTITIES (state, { key, entities }) {
        if (state.filter[key]?.entities) {
            state.filter[key].entities = entities;
        }
    },
    RESET_FILTER (state) {
        state.filter = initFilter();
    }
});

Object.assign(module.getters, {
    filterValues: (state) => mapValues(state.filter, 'value'),
    filterEntities: (state) => mapValues(state.filter, 'entities')
});

Object.assign(module.actions, {
    setFilterValue ({ commit }, { key, value }) {
        commit('SET_FILTER_VALUE', { key, value });
    },
    setFilterEntities ({ commit }, { key, entities }) {
        commit ('SET_FILTER_ENTITIES', { key, entities });
    },
    resetFilter ({ commit }) {
        commit('RESET_FILTER');
    }
});

function initFilter () {
    return {
        invoiceDate: {
            value: [],
            entities: []
        },
        accountingPeriod: {
            value: [],
            entities: []
        },
        plannedDate: {
            value: [],
            entities: []
        },
        stage: {
            value: [],
            entities: []
        },
        number: {
            value: [],
            entities: []
        },
        amount: {
            value: []
        },
        applicant: {
            value: [],
            entities: []
        },
        description: {
            value: '',
            entities: []
        },
        business: {
            value: '',
            entities: []
        },
        purpose: {
            value: '',
        },
        invoice: {
            value: '',
        },
        contractorOrRequisite: {
            value: [],
            entities: []
        },
        contractorAccount: {
            value: [],
            entities: []
        },
        payerAccount: {
            value: [],
            entities: []
        },
        analyticActivity: {
            value: [],
            entities: []
        },
        category: {
            value: [],
            entities: []
        },
        analyticDeal: {
            value: [],
            entities: []
        },
        analyticEmployee: {
            value: [],
            entities: []
        },
        analyticDividendAllocation: {
            value: [],
            entities: []
        },
        analyticLoan: {
            value: [],
            entities: []
        },
        analyticContractor: {
            value: [],
            entities: []
        },
    };
}

export default module;
