import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { BusinessActivity } from '@/types/BusinessActivity';

const baseUri = '/business_activities';

export const getBusinessActivities = (params: object): Promise<PagedCollection<BusinessActivity>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getBusinessActivity = (id: string, params: object): Promise<BusinessActivity> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createBusinessActivity = (data: object): Promise<BusinessActivity> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });

export const deactivateBusinessActivity = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/${id}/deactivate`,
        method: 'POST',
    });

export const updateBusinessActivity = (id: string, data: object): Promise<BusinessActivity> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });

export const deleteBusinessActivity = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });
