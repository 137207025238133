import request from '@/utils/request';
import { PagedCollection } from "@/types/hydra";
import { Contract } from "@/types/Contract";

const baseUri = '/internal_documents';

export const getContracts = (params: object): Promise<PagedCollection<Contract>> =>
    request({
        url: baseUri,
        method: 'GET',
        params
    });

export const getContract = (id: string): Promise<Contract> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET'
    });

export const createContract = (data: object): Promise<Contract> =>
    request({
        url: baseUri,
        method: 'POST',
        data
    });

export const deleteContract = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE'
    });

export const updateContract = (id: string, data: object): Promise<Contract> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data
    });
