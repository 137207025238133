import request from '@/utils/request';

const baseUri = '/deal_stages';

export const getDealStages = (params) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getDealStage = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    });

export const createDealStage = (data) =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });

export const updateDealStage = (id, data) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });

export const deleteDealStage = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });
