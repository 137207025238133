import EmploymentAPI from '@/api/staff/employment';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return EmploymentAPI.findAll(params);
    },
    doFetchSingle (id) {
        return EmploymentAPI.find(id);
    },
    doCreate (data) {
        return EmploymentAPI.create(data);
    },
    doUpdate (id, data) {
        return EmploymentAPI.update(id, data);
    },
    doDelete (id) {
        return EmploymentAPI.delete(id);
    },
});
