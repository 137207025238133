<template>
    <div class="form-reset-wrapper">
        <slot />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'UiFormResetWrapper',
    provide () {
        return {
            elForm: {},
        };
    },
});
</script>
