import { getTransactionVerification, createTransactionVerification, deleteTransactionVerification, getTransactionVerifications } from '@/api/money/transactionVerification';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return getTransactionVerifications(params);
    },
    doFetchSingle (id: string) {
        return getTransactionVerification(id);
    },
    doCreate (data: object) {
        return createTransactionVerification(data);
    },
    doDelete (id: string) {
        return deleteTransactionVerification(id);
    }
});
