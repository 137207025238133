import type { PluginFunction } from 'vue';
import Vue from 'vue';
import store from '@/store';

interface CanFunction {
    (attributes: string, businessId?: string): boolean;
}

declare module 'vue/types/vue' {
    interface Vue {
        readonly $can: CanFunction;
    }
}

export default ((app: typeof Vue): void => {
    if (app.prototype.$can) {
        return;
    }

    Object.defineProperties(app.prototype, {
        $can: {
            get (): CanFunction {
                return (attributes: string, businessId?: string): boolean => {
                    let isDenied = true;
                    businessId = businessId ?? store.getters['business/currentId'];

                    if (businessId) {
                        if (store.getters['security/hasAccess'](attributes, businessId)) {
                            isDenied = false;
                        }
                    } else {
                        for (const key of Object.keys(store.getters['security/permissions']())) {
                            if (store.getters['security/hasAccess'](attributes, key)) {
                                isDenied = false;
                                break;
                            }
                        }
                    }

                    return !isDenied;
                };
            },
        },
    });
}) as PluginFunction<any>;
