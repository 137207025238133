import request from '@/utils/request';

const baseUri = '/possessions/possession_documents';

export const getPossessionDocuments = (params) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });
export const deletePossessionDocument = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });
export const approvePossessionDocument = (id, data) =>
    request({
        url: `${baseUri}/${id}/approve`,
        method: 'POST',
        data,
    });
export const disapprovePossessionDocument = (id, data) =>
    request({
        url: `${baseUri}/${id}/disapprove`,
        method: 'POST',
        data,
    });
