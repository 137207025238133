import * as api from '@/api/possessions/possessions/receiptDocument';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return api.getReceiptDocuments(params);
    },
    doFetchSingle (id: string, params: object) {
        return api.getReceiptDocument(id, params);
    },
    doCreate (data: object) {
        return api.createReceiptDocument(data);
    },
    doUpdate (id: string, data: object) {
        return api.editReceiptDocument(id, data);
    },
    doDelete (id: string) {
        return api.deleteReceiptDocument(id);
    },
});
