import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import type { AccountingCategory, AnalyticsLogicOutput } from '@/types/AccountingCategory';

const baseUri = '/accounting_categories';

export const getCategories = (params?: object): Promise<PagedCollection<AccountingCategory>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getCategory = (id: string, params?: object): Promise<AccountingCategory> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createCategory = (data: object, params?: object): Promise<AccountingCategory> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
        params,
    });

export const updateCategory = (id: string, data: object, params?: object): Promise<AccountingCategory> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
        params,
    });

export const deleteCategory = (id: string, params?: object): Promise<null> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
        params,
    });

export const getAnalyticsLogic = (params?: object): Promise<AnalyticsLogicOutput> =>
    request({
        url: `${baseUri}/analytics_logic`,
        method: 'GET',
        params,
    });
