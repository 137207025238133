import * as DealAPI from '@/api/contractor/deal';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return DealAPI.getDeals(params);
    },
    doFetchSingle (id: string, params: object) {
        return DealAPI.getDeal(id, params);
    },
    doCreate (data: object, params: object) {
        return DealAPI.createDeal(data, params);
    },
    doUpdate (id: string, data: object, params: object) {
        return DealAPI.updateDeal(id, data, params);
    },
    doDelete (id: string) {
        return DealAPI.deleteDeal(id);
    },
});
