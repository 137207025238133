<template>
    <ui-loading v-if="isLoading" />
    <div
        v-else
        class="bar-chart"
    >
        <div class="bar-chart__inner">
            <div
                v-for="item in rows"
                :key="item.id"
                class="bar-chart__row"
            >
                <div class="bar-chart__col">
                    <div class="bar-chart__prepend">
                        {{ item.title }}
                    </div>
                </div>
                <div class="bar-chart__col --fill">
                    <ui-progress
                        ref="progress"
                        class="bar-chart__progress"
                        :stroke-width="24"
                        :percentage="item.percent / maxPercent * 100"
                        :color="item.color"
                    >
                        <template #inner>
                            {{ `${formatPrice(item.value)} Р` }}
                        </template>
                        <template #append>
                            <span :style="{ color: item.color }">
                                {{ `${formatNumber(item.percent, 0)}%` }}
                            </span>
                        </template>
                    </ui-progress>
                </div>
            </div>
            <div class="bar-chart__row">
                <div class="bar-chart__col" />
                <div class="bar-chart__col --fill">
                    <div class="bar-chart__scale-wrapper">
                        <ui-bar-chart-scale :max-value="maxPercent" />
                        <div
                            v-if="appendScaleInfo"
                            class="bar-chart__scale-info"
                        >
                            {{ appendScaleInfo }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormatMixin from '@/mixins/format';

export default {
    name: 'UiBarChart',
    mixins: [FormatMixin],
    props: {
        data: {
            type: Array,
            required: true,
        },

        appendScaleInfo: {
            type: String,
            default: '',
        },

        countAsFullValue: {
            type: Number,
            default: null,
        },

        isLoading: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        rows () {
            return this.data.map(i => ({
                title: i.title,
                value: i.value,
                color: i.color,
                percent: i.percent ?? Math.abs(i.value / this.countAsOneHundredPercentValue * 100),
            }));
        },

        countAsOneHundredPercentValue () {
            return this.countAsFullValue ?? Math.max(...this.data.map(i => i.value));
        },

        maxPercent () {
            return Math.max(...this.rows.map(i => Math.abs(i.percent)));
        },
    },
};
</script>

<style lang="scss">
.bar-chart {

    &__inner {
        display: table;
        border-spacing: 0 8px;
        margin: -8px 0;
        padding-right: 88px;
    }

    .el-progress-bar {
        &__outer {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &__inner {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &__row {
        display: table-row;
    }

    &__progress {
        &:before {
            content: "";
            position: absolute;
            top: calc(50% - 2px);
            left: -8px;
            width: 8px;
            height: 2px;
            background-color: $--color-text-secondary;
        }
    }

    &__prepend {
        white-space: nowrap;
        text-align: right;
        color: $--color-text-secondary;
    }

    &__append {
        font-size: 12px;
    }

    &__col {
        display: table-cell;
        vertical-align: middle;

        &:not(:last-child) {
            padding-right: 16px;
        }

        &.--fill {
            width: 100%;
        }
    }

    &__scale-info {
        text-align: right;
        color: $--color-text-secondary;
        font-size: 12px;
        font-style: italic;
    }
}
</style>
