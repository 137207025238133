import * as api from '@/api/contractor/contractor';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return api.getContractors(params);
    },
    doFetchSingle (id: string, params: object) {
        return api.getContractor(id, params);
    },
    doCreate (data: object) {
        return api.createContractor(data);
    },
    doUpdate (id: string, data: object) {
        return api.updateContractor(id, data);
    },
    doDelete (id: string) {
        return api.deleteContractor(id);
    },
});
