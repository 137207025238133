<template>
    <div
        :class="{ 'is-active-label': labelActive }"
        class="table-filter"
    >
        <div class="table-filter__slot">
            <div
                v-if="label"
                class="table-filter__label"
            >
                <iconify-icon
                    slot="reference"
                    icon="fa6-solid:filter"
                    class="table-filter__icon"
                />
                {{ label }}
            </div>
            <ui-remote-select-nested
                ref="selectNested"
                v-model="currentValue"
                :fetch-items="fetchData"
                clearable
                filterable
                class="table-filter__control"
                multiple
                :multiple-limit="multiple ? 5 : 1"
                placeholder=""
                v-bind="proxyProps"
                v-on="$listeners"
                @visible-change="handleControlVisibleChange"
            />
        </div>
    </div>
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';

export default {
    name: 'UiTableFilterRemoteSelectNested',

    mixins: [WrapperMixin],

    props: {
        value: {
            type: [String, Array],
            default: '',
        },

        label: {
            type: String,
            default: '',
        },

        width: {
            type: Number,
            default: 300,
        },

        multiple: {
            type: Boolean,
            default: false,
        },

        fetchItems: {
            type: Function,
            required: true,
        },
    },

    data () {
        return {
            focus: false,
        };
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (value) {
                this.$emit('input', value);
            },
        },

        labelActive () {
            if (
                (Array.isArray(this.currentValue) && this.currentValue.length > 0) ||
                (typeof this.currentValue === 'string' && this.currentValue !== '')
            ) {
                return true;
            }

            return this.focus;
        },
    },

    watch: {
        value () {
            this.$refs.selectNested.$refs.select.blur();
        },
    },

    methods: {
        handleControlVisibleChange (value) {
            this.focus = value;
        },

        fetchData (query, cb) {
            this.fetchItems(query, items => {
                const data = Array.isArray(items) ? items : [];
                cb(data);
            });
        },
    },
};
</script>
