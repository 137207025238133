import request from '@/utils/request';

const baseUri = '/possessions/receipt_product_documents';

export const getReceiptProducts = (params) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });
export const createReceiptProducts = (data) =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    });
export const getReceiptProduct = (id, params) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });
export const deleteReceiptProducts = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    });
export const editReceiptProducts = (id, data) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    });
