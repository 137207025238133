import * as DealStageAPI from '@/api/dealStage';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return DealStageAPI.getDealStages(params);
    },
    doFetchSingle (id) {
        return DealStageAPI.getDealStage(id);
    },
    doCreate (data) {
        return DealStageAPI.createDealStage(data);
    },
    doUpdate (id, data) {
        return DealStageAPI.updateDealStage(id, data);
    },
    doDelete (id) {
        return DealStageAPI.deleteDealStage(id);
    },
});
