import * as api from '@/api/possessions/possessions/receiptFixedAsset';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return api.getReceiptFixedAssets(params);
    },
    doFetchSingle (id, params) {
        return api.getReceiptFixedAsset(id, params);
    },
    doCreate (data) {
        return api.createReceiptFixedAsset(data);
    },
    doUpdate (id, data) {
        return api.editReceiptFixedAsset(id, data);
    },
});
