import request from '@/utils/request';

export default {
    login (email, password) {
        return request.post('/auth/login', {
            email,
            password,
        }, { withCredentials: true });
    },

    logout () {
        return request.post('/auth/logout');
    },

    refreshToken () {
        return request.post('/auth/refresh', null, { withCredentials: true });
    },
};
