import type { PluginFunction } from 'vue';
import Vue from 'vue';
import eventBus, { initialize } from '@/event-bus';

declare module 'vue/types/vue' {
    interface Vue {
        readonly $bus: {
            on: (event: string, callback: (item: any) => void) => void,
            once: (event: string, callback: (item: any) => void) => void,
            off: (event: string, callback: (item: any) => void) => void,
            emit: (event: string, ...args: any[]) => void,
        };
    }
}

export default ((app: typeof Vue): void => {
    if (app.prototype.$bus) {
        return;
    }

    initialize();

    Object.defineProperties(app.prototype, {
        $bus: {
            get () {
                return eventBus;
            },
        },
    });
}) as PluginFunction<any>;
