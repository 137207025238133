/* eslint comma-dangle: ["error", "always-multiline"] */
export default {
    actions: 'Действия',
    add: 'Добавить',
    addAndApprove: 'Добавить и утвердить',
    addCondition: 'Добавить условие',
    addRow: 'Добавить строку',
    addRule: 'Добавить правило',
    agree: 'Согласовать',
    agreeForAll: 'Согласовать за всех',
    agreementLogicCheck: 'Проверка логики согласования',
    approve: 'Утвердить',
    backToIndex: 'Вернуться на главную',
    businessAdd: 'Добавить бизнес',
    cancel: 'Отменить',
    changePaymentDate: 'Изменить плановую дату оплаты',
    createNestedAccountingItem: 'Добавить вложенную статью',
    createNestedActivity: 'Добавить вложенное направление',
    createNestedDepartment: 'Добавить вложенный отдел',
    delete: 'Удалить',
    deleteIntegration: 'Удалить интеграцию',
    disapprove: 'Отменить утверждение',
    edit: 'Редактировать',
    income: 'Поступление',
    logout: 'Выйти',
    outcome: 'Списание',
    printPayroll: 'Печать расчетного листа',
    recalculate: 'Пересчитать',
    reject: 'Отклонить',
    reset: 'Сбросить',
    returnToApplicant: 'Вернуть заявителю',
    save: 'Сохранить',
    saveAndApprove: 'Сохранить и утвердить',
    search: 'Поиск',
    select: 'Подобрать',
    send: 'Отправить',
    sendForAgreement: 'Отправить на согласование',
    setLeader: 'Назначить руководителем',
    settings: 'Настройки',
    setup: 'Настроить',
    transfer: 'Перевод',
    uploadPayments: 'Выгрузить платежи',
    view: 'Просмотреть',
};
