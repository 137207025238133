/* eslint comma-dangle: ["error", "always-multiline"] */
export default {
    account: 'нет счетов | {n} счет | {n} счета | {n} счетов',
    symbol: 'нет символов | {n} символ | {n} символа | {n} символов',
    employee: 'нет сотрудников | {n} сотрудник | {n} сотрудника | {n} сотрудников',
    days: '0 дней | {n} день | {n} дня | {n} дней',
    hours: '0 часов | {n} час | {n} часа | {n} часов',
    unlinkedEmployee: '0 непривязанных сотрудников | {n} непривязанный сотрудник | {n} непривязанных сотрудника | {n} непривязанных сотрудников',
    department: '0 отделов | {n} отдел | {n} отдела | {n} отделов',
    deal: '0 сделок | {n} сделка | {n} сделки | {n} сделок',
    unloadedRow: '0 незагруженных строк | {n} незагруженная строка | {n} незагруженные строки | {n} незагруженных строк',
    duplicateRow: '0 дубликатов | {n} дубликат | {n} дубликата | {n} дубликатов',
    errorInImportedTransaction: '0 ошибок в импортированных операциях | {n} ошибка в импортированных операциях | {n} ошибки в импортированных операциях | {n} ошибок в импортированных операциях',
    foundAccounts: 'Найдено 0 счетов | Найден {n} счёт | Найдено {n} счёта | Найдено {n} счетов',
    month: '0 месяцев | {n} месяц | {n} месяца | {n} месяцев',
    transaction: '0 операций | {n} операция | {n} операции | {n} операций',
    fundingClaims: '0 заявок | {n} заявка | {n} заявки | {n} заявок',
    invoices: '0 счетов | {n} счёт | {n} счёта | {n} счетов',
    payrolls: '0 ведомостей | {n} ведомость | {n} ведомости | {n} ведомостей',
};
