import request from '@/utils/request';
import { AlfaAccount, AlfaIntegration, AlfaProfile } from '@/types/AlfaBank';
import { PagedCollection } from '@/types/hydra';

const baseUri = '/alfa_bank_integrations';

export const getAlfaIntegrations = (params: object): Promise<PagedCollection<AlfaIntegration>> =>
    request({
        url: baseUri,
        method: 'GET',
        params
    });

export const getAlfaIntegration = (id: string): Promise<AlfaIntegration> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET'
    });

export const updateAlfaIntegration = (id: string, data: object): Promise<AlfaIntegration> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data
    });

export const getAlfaAccounts = (params: object): Promise<PagedCollection<AlfaAccount>> =>
    request({
        url: `${baseUri}/accounts`,
        method: 'GET',
        params
    });

export const getAlfaAccount = (id: string): Promise<AlfaAccount> =>
    request({
        url: `${baseUri}/accounts/${id}`,
        method: 'GET'
    });

export const updateAlfaAccount = (id: string, data: object): Promise<AlfaAccount> =>
    request({
        url: `${baseUri}/accounts/${id}`,
        method: 'PATCH',
        data
    });

export const createAlfaProfile = (data: object): Promise<AlfaProfile> =>
    request({
        url: `${baseUri}/profiles`,
        method: 'POST',
        data
    });

export const getAlfaProfile = (id: string): Promise<AlfaProfile> =>
    request({
        url: `${baseUri}/profiles/${id}`,
        method: 'GET'
    });

export const deleteAlfaProfile = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/profiles/${id}`,
        method: 'DELETE'
    });

export const updateAlfaProfile = (id: string, data: object): Promise<AlfaProfile> =>
    request({
        url: `${baseUri}/profiles/${id}`,
        method: 'PATCH',
        data
    });

export const refreshAlfaProfile = (id: string) =>
    request({
        url: `${baseUri}/profiles/${id}/refresh`,
        method: 'POST'
    });
