import type { PluginFunction } from 'vue';
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isBeetween from 'dayjs/plugin/isBetween';
import minMax from 'dayjs/plugin/minMax';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

declare module 'vue/types/vue' {
    interface Vue {
        $dayjs (date?: dayjs.ConfigType, option?: dayjs.OptionType, locale?: string): dayjs.Dayjs;
    }
}

export default ((app: typeof Vue): void => {
    if (app.prototype.$dayjs) {
        return;
    }

    dayjs.locale('ru');
    dayjs.extend(timezone);
    dayjs.extend(localizedFormat);
    dayjs.extend(utc);
    dayjs.extend(customParseFormat);
    dayjs.extend(quarterOfYear);
    dayjs.extend(isBeetween);
    dayjs.extend(minMax);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(isSameOrAfter);

    Object.defineProperties(app.prototype, {
        $dayjs: {
            get () {
                return dayjs;
            },
        },
    });
}) as PluginFunction<any>;
