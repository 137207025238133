import * as AccessGroupAPI from '@/api/accessGroup';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return AccessGroupAPI.getAccessGroups(params);
    },
    doFetchSingle (id) {
        return AccessGroupAPI.getAccessGroup(id);
    },
    doCreate (data) {
        return AccessGroupAPI.createAccessGroup(data);
    },
    doDelete (id) {
        return AccessGroupAPI.deleteAccessGroup(id);
    },
    doUpdate (id, data) {
        return AccessGroupAPI.updateAccessGroup(id, data);
    },
});
