<template>
    <div
        class="navigation-drawer"
        :class="{ 'is-collapsed': isCollapsed, 'is-hovered': isHovered }"
        @mouseover="handleMouseover"
        @mouseleave="handleMouseleave"
    >
        <div class="navigation-drawer__prepend">
            <slot name="prepend">
                <div
                    v-if="showTitle"
                    class="navigation-drawer__title"
                    v-text="title"
                />
            </slot>
        </div>
        <div class="navigation-drawer__content u-scrollable u-scrollable--y">
            <slot />
        </div>
        <div class="navigation-drawer__border">
            <div
                class="navigation-drawer__resize-button"
                @click="handleResizeButtonClick"
            >
                <el-button
                    circle
                    size="mini"
                >
                    <iconify-icon icon="akar-icons:chevron-left" />
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showTitle: {
            type: Boolean,
            default: true,
        },
    },

    data () {
        return {
            isHovered: false,
            hoverEvents: false,
        };
    },

    computed: {
        isCollapsed () {
            return this.$store.getters['ui/isNavDrawerCollapsed'];
        },

        title () {
            const { matched } = this.$route;
            const matchedRoute = matched[matched.length - 1];
            let parent = matchedRoute.parent;
            if (matchedRoute.meta && matchedRoute.meta.navigationParent) {
                parent = matched.find(route => route.name === matchedRoute.meta.navigationParent);
            }
            return parent && parent.meta && parent.meta.title ? parent.meta.title : '';
        },
    },

    watch: {
        $route () {
            this.disableHoverEventsTemporary();
        },

        isCollapsed () {
            this.disableHoverEventsTemporary();
        },
    },

    mounted () {
        if (this.isCollapsed) {
            this.hoverEvents = true;
        }
    },

    methods: {
        handleMouseover () {
            if (this.hoverEvents) {
                this.isHovered = true;
            }
        },

        handleMouseleave () {
            if (this.hoverEvents) {
                this.isHovered = false;
            }
        },

        handleResizeButtonClick () {
            this.$store.dispatch('ui/toggleNavDrawer');
        },

        disableHoverEventsTemporary () {
            this.hoverEvents = false;
            if (this.isCollapsed) {
                this.isHovered = false;
                setTimeout(() => {
                    this.hoverEvents = true;
                }, 200);
            }
        },
    },
};
</script>
