<template>
    <div class="error-page">
        <div class="error-page__title">
            {{ title }}
        </div>
        <div
            v-if="description"
            class="error-page__description"
        >
            {{ description }}
        </div>
        <router-link
            v-if="showBackButton"
            v-slot="{ navigate }"
            to="/"
        >
            <el-button @click="navigate">
                Вернуться на главную
            </el-button>
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            default: '',
        },

        showBackButton: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
