import { createParentalLeave, getParentalLeave, updateParentalLeave } from '@/api/staff/personnelDocument';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchSingle (id) {
        return getParentalLeave(id);
    },
    doCreate (data) {
        return createParentalLeave(data);
    },
    doUpdate (id, data) {
        return updateParentalLeave(id, data);
    },
});
