import { createVacationCompensation, getVacationCompensation, updateVacationCompensation } from '@/api/staff/personnelDocument';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchSingle (id, params) {
        return getVacationCompensation(id, params);
    },
    doCreate (data) {
        return createVacationCompensation(data);
    },
    doUpdate (id, data) {
        return updateVacationCompensation(id, data);
    },
});
