import Pusher, { Channel } from 'pusher-js';

interface Params {
    channelName: () => string, // почему функция? Чтобы норм computed переменные работали
    events: [
        eventName: string,
        cb: (data?: any, metadata?: any) => void
    ][]
}

export function usePusherHelper (pusher: Pusher, {
    channelName,
    events,
}: Params) {
    let channel: Channel | undefined;

    function subscribe () {
        channel = pusher.subscribe(channelName());
        channel?.bind('pusher:subscription_succeeded', () => {
            events.forEach(e => {
                channel?.bind(...e);
            });
        });
    }

    function unsubscribe () {
        channel?.unsubscribe();
    }

    return {
        channel,
        subscribe,
        unsubscribe,
    };
}
