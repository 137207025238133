import { createCrudModule } from '@/utils/vuex-crud';
import { createTransactionImport, deleteTransactionImport, getTransactionImport, getTransactionImports, updateTransactionImport } from '@/api/money/transactionImport';


export default createCrudModule({
    businessDependent: true,
    doFetchList: (params: object) => getTransactionImports(params),
    doFetchSingle: (id: string, params?: object) => getTransactionImport(id, params),
    doCreate: (data: object) => createTransactionImport(data),
    doDelete: (id: string) => deleteTransactionImport(id),
    doUpdate: (id: string, data: object) => updateTransactionImport(id, data),
});
