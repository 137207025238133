import { assign, keys, orderBy, pick } from 'lodash';
import { arrayToTree } from 'performant-array-to-tree';

export const assignWithExistsKeys = (obj1, obj2) => {
    return assign(obj1, pick(obj2, keys(obj1)));
};

export const getMessageFromError = (error, nl2br = true) => {
    let message = '';
    if (typeof error === 'string') {
        message = error;
    } else if (error?.response) {
        if (error.response.status >= 400 && error.response.status <= 499 && error.response.data) {
            if (error.response.data.violations?.length > 0) {
                message = error.response.data.violations.reduce((accumulator, currentValue, index, array) => {
                    if (array.length === 1) {
                        return currentValue.message;
                    }
                    if (index === 0) {
                        accumulator += `${index + 1}) ${currentValue.message}`;
                    } else {
                        accumulator += `\n${index + 1}) ${currentValue.message}`;
                    }
                    return accumulator;
                }, '');
            } else if (error.response.data['hydra:description']) {
                message = error.response.data['hydra:description'];
            } else if (error.response.data.detail) {
                message = error.response.data.detail;
            } else if (error.response.data.message) {
                message = error.response.data.message;
            }
        } else {
            message = 'Системная ошибка';
        }
    } else if (error?.message) {
        message = error.message;
    } else {
        message = 'Неизвестная ошибка';
    }

    if (nl2br) {
        return message.replaceAll('\n', '<br>');
    }
    if (message === 'Access Denied') {
        return 'Ошибка доступа';
    }
    return message;
};

export const reorderNested = (items, sortByField, direction = 'asc', config = { parent: 'parent' }) => {
    const sortedItems = orderBy(items, sortByField, direction);

    const tree = arrayToTree(
        sortedItems.map(item => {
            item.parentId = items.find(current => current.id === item[config.parent]?.id)
                ? item[config.parent].id
                : null;
            return item;
        }),
        { dataField: null },
    );

    const flatTree = (level = 0) => ({ children = [], ...object }) => [
        {
            ...object,
            level,
        },
        ...children.flatMap(flatTree(level + 1)),
    ];

    return tree.flatMap(flatTree());
};

export const getTopWindow = () => {
    try {
        if (window.self.origin === window.parent.origin) {
            return window.parent;
        }
    } catch {
    }

    return window.self;
};

export const getClosestElementWithScroll = fromElement => {
    let el = fromElement.parentElement;

    while (el) {
        el = el.parentElement;
        if (el && el.scrollHeight > el.clientHeight) {
            return el;
        }
    }

    return null;
};

export const getEventPath = (event) => event.path ?? (event.composedPath && event.composedPath()) ?? [];

export const validatePassword = (value) => {
    const regex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[`~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{6,}$/g;
    return regex.test(value);
};

export const isJSON = (value) => {
    try {
        JSON.parse(value);
    } catch (e) {
        return false;
    }
    return true;
};
