import request from '@/utils/request';

const baseUri = '/contract_agreements';

export const getContractAgreements = (params: object) =>
    request({
        url: baseUri,
        method: 'GET',
        params
    });

export const getContractAgreement = (id: string) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET'
    });

export const createContractAgreement = (data: object) =>
    request({
        url: baseUri,
        method: 'POST',
        data
    });

export const updateContractAgreement = (id: string, data: object) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data
    });

export const deleteContractAgreement = (id: string) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE'
    });

