import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { GroupSummary, Notification } from '@/types/Notification';

const baseUri = '/notifications';

interface GetNotificationsParams {
    page?: number,
    pageSize?: number,
    notificationGroup?: string | string[]
}

export const getNotifications = (params?: GetNotificationsParams): Promise<PagedCollection<Notification>> =>
    request({
        url: baseUri,
        method: 'GET',
        params
    });

interface GetLastNotificationsParams {
    page?: number,
    pageSize?: number
}
export const getLastNotifications = (params?: GetLastNotificationsParams): Promise<{ groups: GroupSummary[] }> =>
    request({
        url: `${baseUri}/last`,
        method: 'GET',
        params
    });

export const getNotification = (id: string): Promise<Notification> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET'
    });

export const updateNotification = (id: string, data: object): Promise<Notification> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data
    });

export const bulkRead = (data: { ids: string[] }): Promise<void> =>
    request({
        url: `${baseUri}/bulk_read`,
        method: 'POST',
        data
    });
