<template>
    <div
        class="drawer-layout"
        :class="{ 'is-full-height': $route.meta.fullHeightLayout }"
    >
        <div
            v-if="hasPageHeader"
            class="drawer-layout__page-header"
        >
            <portal-target
                name="page-title"
                class="drawer-layout__page-title"
            >
                {{ $route.meta?.title }}
            </portal-target>

            <portal-target
                name="page-actions"
                multiple
                class="drawer-layout__page-actions"
            />
        </div>

        <div class="drawer-layout__body">
            <slot />
        </div>

        <portal-target
            name="sticky-panel"
            class="sticky-panel"
        />
    </div>
</template>

<script>
import { Wormhole } from 'portal-vue';

export default {
    props: {
        mode: {
            type: String,
            default: 'default'
        }
    },

    computed: {
        hasPageHeader () {
            return Wormhole.hasContentFor('page-title') ||
                Wormhole.hasContentFor('page-actions');
        }
    }
};
</script>

<style lang="scss">
.drawer-layout {
    padding: 15px 30px;

    &__page-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 36px;
        margin-bottom: 16px;
    }

    &__page-title {
        font-size: 20px;
        font-weight: bold;

        .el-link {
            font-size: inherit;
            font-weight: inherit;
        }
    }

    & > .el-loading-parent--relative {
        position: fixed !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100vh !important;
    }

    .sticky-panel {
        bottom: 0;
        padding: 20px 30px;
        margin: 0 -30px;
    }

    @media (max-width: 1000px) {
        padding-right: 20px;
        padding-left: 20px;

        .sticky-panel {
            padding: 20px;
            margin: 0 -20px;
        }
    }

    &.is-full-height {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &.is-full-height &__body {
        flex-grow: 1;
    }
}
</style>
