<template>
    <el-dialog
        :visible.sync="currentVisible"
        append-to-body
        lock-scroll
        destroy-on-close
        top="60px"
        custom-class="doc-viewer"
        :style="{ '--viewer-max-width': maxWidth }"
    >
        <template #title>
            <div class="doc-viewer__title">
                <slot name="title">
                    {{ props.file.originalName }}

                    <el-button
                        type="text"
                        circle
                        class="doc-viewer__download"
                        title="Скачать"
                        @click="handleDownload"
                    >
                        <iconify-icon icon="bi:download" />
                    </el-button>
                </slot>
            </div>
        </template>
        <div class="doc-viewer__wrapper">
            <ui-loading
                v-if="!isIframeLoaded && (isOfficeFile || isRtfFile)"
                class="u-m-auto"
            />
            <iframe
                v-if="isOfficeFile"
                v-show="isIframeLoaded"
                :src="`https://view.officeapps.live.com/op/embed.aspx?src=${props.file.publicUrl}`"
                class="doc-viewer__viewer"
                @load="handleIframeLoad"
            />
            <iframe
                v-else-if="isRtfFile"
                v-show="isIframeLoaded"
                :src="`https://docs.google.com/gview?embedded=true&url=${props.file.publicUrl}`"
                class="doc-viewer__viewer"
                @load="handleIframeLoad"
            />
            <object
                v-else-if="isPdfFile"
                :data="props.file.publicUrl"
                type="application/pdf"
                class="doc-viewer__viewer"
            >
                <embed
                    :src="props.file.publicUrl"
                    type="application/pdf"
                >
            </object>
            <ui-image-viewer
                v-else-if="isImageFile"
                :src="file.publicUrl"
                class="doc-viewer__viewer"
            />
            <div
                v-else
                class="u-m-auto"
            >
                Неподдерживаемый формат файла
            </div>
            <slot name="append" />
        </div>
    </el-dialog>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'UiOfficeDocViewer'
});
</script>

<script setup lang="ts">
import type { MediaObject } from '@/types/MediaObject';
import { computed, ref, watch } from 'vue';
import mime from 'mime';

const emit = defineEmits<{
    (e: 'update:visible', value: boolean): void
}>();

interface Props {
    file: MediaObject,
    visible?: boolean,
    maxWidth?: string
}

const props = withDefaults(defineProps<Props>(), {
    visible: false,
    maxWidth: '1200px'
});

const isIframeLoaded = ref(false);

const isOfficeFile = ref(false);
const isPdfFile = ref(false);
const isImageFile = ref(false);
const isRtfFile = ref(false);

function handleIframeLoad() {
    isIframeLoaded.value = true;
}

const currentVisible = computed({
    get() {
        return props.visible;
    },
    set(val: boolean) {
        emit('update:visible', val);
    }
});

function checkFileType() {
    const mimeType = mime.getType(props.file.mimeType ?? '') ?? '';

    isOfficeFile.value = (
        [
            'application/msword',
            'application/vnd.ms-excel',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        ].includes(mimeType) ||
        /\.(docx?|xlsx?|pptx?)$/i.test(props.file.originalName)
    );

    isPdfFile.value = (
        mimeType === 'application/pdf' ||
        /\.pdf$/i.test(props.file.originalName)
    );

    isImageFile.value = (
        mimeType.startsWith('image/') ||
        /\.(jpe?g|png|gif)$/i.test(props.file.originalName)
    );

    isRtfFile.value = (
        mimeType === 'application/rtf' ||
        /\.rtf$/i.test(props.file.originalName)
    );

    if (!isOfficeFile.value && !isPdfFile.value && !isImageFile.value && !isRtfFile.value) {
        handleDownload();
        currentVisible.value = false;

    }
}

watch(() => currentVisible.value, (val) => {
    if (!val) {
        isIframeLoaded.value = false;
    } else {
        checkFileType();
    }
}, { immediate: true });

function handleDownload() {
    const url = props.file.publicUrl;
    const filename = props.file.originalName;
    const link = document.createElement('a');

    link.href = url + '&download=1';
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
</script>

<style lang="scss">
.doc-viewer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 90px);
    margin-bottom: 0;
    background: transparent;
    box-shadow: none;
    width: 100%;
    max-width: var(--viewer-max-width);

    &__wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: $--color-text-gray;
        display: flex;
        flex-direction: column;
    }

    &__viewer {
        width: 100%;
        height: 100%;
        border: none;
    }

    &__download {
        color: rgba(255, 255, 255, .7);

        &:hover,
        &:active {
            color: $--color-white;
        }
    }

    &__title {
        color: $--color-white;
        font-weight: bold;
        font-size: 18px;
        min-height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .el-dialog {
        &__header {
            background: #323639;
            padding: 8px 16px;
        }

        &__headerbtn {
            top: 20px;

            .el-dialog__close {
                color: rgba(255, 255, 255, .7);
            }

            &:hover,
            &:active {
                .el-dialog__close {
                    color: $--color-white;
                }
            }
        }

        &__body {
            flex-grow: 1;
            padding: 0;
            max-height: calc(100% - 90px);
        }

        &__footer {
            display: none;
        }
    }
}
</style>
