import request from '@/utils/request';
import type { LiabilityReportOutput, LiabilityReportPaymentDocument } from '@/types/Liability';
import { PagedCollection } from '@/types/hydra';
import { Deal } from '@/types/Deal';
import { FundingClaimPayment } from '@/types/FundingClaim';
import { Transaction } from '@/types/Transaction';
import { CashFlowBudgetOutput } from '@/types/CashFlowBudget';
import { OnecTaxContributionReport } from '@/types/OnecZupTaxContribution';

const baseUri = '/reports';

export const getPaymentCalendar = (params: object) =>
    request({
        url: `${baseUri}/payment_calendar`,
        method: 'GET',
        params,
    });

export const getFundingClaimPaying = (params: object) =>
    request({
        url: `${baseUri}/funding_claim_paying`,
        method: 'GET',
        params,
    });

export const getOpiu = (params: object) =>
    request({
        url: `${baseUri}/opiu`,
        method: 'GET',
        params,
    });

export const printSalaryReport = (data: object) =>
    request({
        url: `${baseUri}/salary/payroll_sheet`,
        method: 'POST',
        data,
    });

export const getDividendReport = (params: object) =>
    request({
        url: `${baseUri}/dividends_allocations`,
        method: 'GET',
        params,
    });

export const getLiability = (params: object): Promise<LiabilityReportOutput> =>
    request({
        url: `${baseUri}/liability`,
        method: 'GET',
        params,
    });

export const getLiabilityDocuments = (params: object): Promise<PagedCollection<LiabilityReportPaymentDocument>> =>
    request({
        url: `${baseUri}/liability/payment_documents`,
        method: 'GET',
        params,
    });

export const getTaxesReport = (params: object) =>
    request({
        url: `${baseUri}/taxes`,
        method: 'GET',
        params,
    });

export const getTaxesReportDetails = (params: object) =>
    request({
        url: `${baseUri}/taxes/details`,
        method: 'GET',
        params,
    });

export const updateTaxesReport = (data: object) =>
    request({
        url: `${baseUri}/taxes/assess`,
        method: 'POST',
        data,
    });

export const getRevenuesExpendituresBudgetReport = (params: object) =>
    request({
        url: `${baseUri}/revenues_expenditures_budget`,
        method: 'GET',
        params,
    });

export const setRevenuesExpendituresBudgetMonthPlansBudget = (data: object) =>
    request({
        url: `${baseUri}/revenues_expenditures_budget/set_month_plan`,
        method: 'POST',
        data,
    });

export const getRevenuesExpendituresBudgetMonthPlan = (params: object) =>
    request({
        url: `/revenues_expenditures_budget_month_plans`,
        method: 'GET',
        params,
    });

export const getOpiuDeals = (params: object): Promise<PagedCollection<Deal>> =>
    request({
        url: `${baseUri}/opiu/deals`,
        method: 'GET',
        params,
    });

export const getOpiuPayments = (params: object): Promise<PagedCollection<FundingClaimPayment>> =>
    request({
        url: `${baseUri}/opiu/funding_claim_payments`,
        method: 'GET',
        params,
    });

export const getOpiuTransactions = (params: object): Promise<PagedCollection<Transaction>> =>
    request({
        url: `${baseUri}/opiu/transactions`,
        method: 'GET',
        params,
    });

export const getCashFlowBudget = (params: object): Promise<CashFlowBudgetOutput> =>
    request({
        url: `${baseUri}/cash_flow_budget`,
        method: 'GET',
        params,
    });

export const setCashFlowBudgetMonthPlan = (data: object): Promise<void> =>
    request({
        url: `${baseUri}/cash_flow_budget/set_month_plan`,
        method: 'POST',
        data,
    });

export const getTaxContributionReport = (params: object): Promise<OnecTaxContributionReport> =>
    request({
        url: `${baseUri}/tax_contributions`,
        method: 'GET',
        params,
    });
