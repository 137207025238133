<template>
    <el-button
        v-if="!editingMode"
        :type="type"
        :disabled="disabled"
        @click="handleClick(null)"
    >
        {{ label }}
    </el-button>
    <el-dropdown
        v-else
        trigger="click"
        @command="handleClick"
    >
        <el-button
            :type="type"
            :disabled="disabled"
        >
            {{ label }}
        </el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                v-for="approver in approvers"
                :key="approver.id"
                :command="approver.id"
            >
                {{ approver.name }}
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
export default {
    name: 'UiStageMovingMixedButton',

    props: {
        editingMode: {
            type: Boolean,
            default: false,
        },

        approvers: {
            type: Array,
            default: () => [],
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        type: {
            type: String,
            default: 'default',
        },

        label: {
            type: String,
            default: '',
        },
    },

    methods: {
        handleClick (value) {
            this.$emit('click', value);
        },
    },
};
</script>
