import type { AccessRole } from '@/types/AccessRole';
import request from '@/utils/request';
import { PagedCollection } from '@/types/hydra';
import { AxiosRequestConfig } from 'axios';

const baseUri = '/access_roles';

export const getAccessRoles = (params: any): Promise<PagedCollection<AccessRole>> =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    } as AxiosRequestConfig);

export const getAccessRole = (id: string): Promise<AccessRole> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    } as AxiosRequestConfig);

export const createAccessRole = (data: any): Promise<AccessRole> =>
    request({
        url: baseUri,
        method: 'POST',
        data,
    } as AxiosRequestConfig);

export const updateAccessRole = (id: string, data: any): Promise<AccessRole> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data,
    } as AxiosRequestConfig);

export const putAccessRoleRights = ({ roleId, actions }: { roleId: string, actions: any }) =>
    request({
        url: `${baseUri}/${roleId}/rights`,
        method: 'PUT',
        data: { actions },
    });

export const deleteAccessRole = (id: string): Promise<null> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE',
    } as AxiosRequestConfig);
