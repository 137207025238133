import { getContractAmendment, getContractAmendments } from '@/api/contractAmendment';
import { createCrudModule } from '@/utils/vuex-crud';
import { approve, decline, reject, startApproving } from '@/api/contractAmendment';

const module = createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return getContractAmendments(params);
    },
    doFetchSingle (id: string) {
        return getContractAmendment(id);
    },
});

Object.assign(module.actions, {
    async startApproving ({ commit }, { id, data }: { id: string, data: object }) {
        commit('UPDATE_START');
        try {
            const response = await startApproving(id, data);
            commit('UPDATE_SUCCESS', response);
            return response;
        } catch (error) {
            console.error(error);
            commit('UPDATE_ERROR', error);
            return null;
        }
    },

    async approve ({ commit }, { id, data }: { id: string, data: object }) {
        commit('UPDATE_START');
        try {
            const response = await approve(id, data);
            commit('UPDATE_SUCCESS', response);
            return response;
        } catch (error) {
            console.error(error);
            commit('UPDATE_ERROR', error);
            return null;
        }
    },

    async decline ({ commit }, { id, data }: { id: string, data: object }) {
        commit('UPDATE_START');
        try {
            const response = await decline(id, data);
            commit('UPDATE_SUCCESS', response);
            return response;
        } catch (error) {
            console.error(error);
            commit('UPDATE_ERROR', error);
            return null;
        }
    },

    async reject ({ commit }, { id, data }: { id: string, data: object }) {
        commit('UPDATE_START');
        try {
            const response = await reject(id, data);
            commit('UPDATE_SUCCESS', response);
            return response;
        } catch (error) {
            console.error(error);
            commit('UPDATE_ERROR', error);
            return null;
        }
    },
});

export default module;
