<template>
    <portal :to="`contextMenuBelow${portal}`">
        <el-menu
            ref="menu"
            menu-trigger="click"
            @close="handleClose"
            @open="handleOpen"
        >
            <el-submenu
                v-for="item in data"
                :key="item.id"
                :index="item.id"
            >
                <template #title>
                    <div @click.stop="handleClick(item)">
                        <!--                        <i-->
                        <!--                            v-if="item.children.length > 0"-->
                        <!--                            :class="isSubmenuOpen(item.id) ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"-->
                        <!--                        />-->
                        <span :class="{ 'is-active': currentItem?.id === item.id }">
                            {{ item.name }}
                        </span>
                    </div>
                </template>
                <el-menu-item
                    v-for="i in item.children"
                    :key="i.id"
                    :index="i.id"
                    style="margin-left: 35px;  display: inline-flex;"
                    @click="handleClick(i)"
                >
                    <span
                        v-if="isSubmenuOpen(i.parentId)"
                        class="is-clipped"
                        :title="i.name"
                    >
                        {{ i.name }}
                    </span>
                </el-menu-item>
            </el-submenu>
        </el-menu>
    </portal>
</template>

<script lang="ts">
import Vue from 'vue';
import { remove } from 'lodash';

export default Vue.extend({
    name: 'UiContextMenuBelow',

    props: {
        portal: {
            type: String,
            required: true,
        },

        data: {
            type: Array,
            required: true,
        },
    },

    data () {
        return {
            openedSubmenus: [] as [string] | [],
            currentItem: null,
        };
    },

    methods: {
        forceClearActiveIndex () {
            const menu = this.$refs?.menu;

            if (menu) {
                menu.activeIndex = '';
            }
        },

        handleClick (item: object) {
            if (item.isParent) {
                this.forceClearActiveIndex();
            }
            this.currentItem = item;
            this.$emit('click', item);
        },

        isSubmenuOpen (id: string) {
            return this.openedSubmenus.includes(id);
        },

        handleClose (key: string) {
            remove(this.openedSubmenus, (i) => i === key);
        },

        handleOpen (key: string) {
            if (!this.openedSubmenus.includes(key)) {
                this.openedSubmenus.push(key);
            }
        }
    },
});
</script>

<style lang="scss">
.sidebar-menu {
    .el-menu {
        background-color: $--background-color-base !important;
        border: none;

        .el-submenu {
            padding-left: 8px;
            &__icon-arrow {
                right: auto !important;
            }

            &__title {
                padding: 0;
                height: 36px !important;
                line-height: 36px !important;
            }

            .el-menu-item {
                padding-left: 25px !important;
                height: 36px !important;
                line-height: 36px !important;
                &:focus {
                    background: none;
                }
            }
        }

        .el-submenu__icon-arrow {
            display: none;
        }

        .is-active {
            color: $--color-primary;
        }

        .is-clipped {
            max-width: 160px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>

