<template>
    <div class="chat-window">
        <div
            ref="scrollWrapper"
            class="chat-window__main u-scrollable u-scrollable--y"
        >
            <ui-chat-message-group
                v-for="item in groupedMessages"
                :key="item.date"
                :date="item.date"
                :messages="item.messages"
                @visibility-change="handleVisibilityChange"
            />

            <div
                class="chat-window__wrapper"
                :style="{ 'padding-top': isLoading || !lastItem ? '0' : '64px' }"
            >
                <ui-loading
                    v-if="isLoading"
                    height="64px"
                />

                <div
                    v-else-if="groupedMessages.length === 0"
                    class="chat-window__info"
                >
                    Сообщений пока нет
                </div>
            </div>
        </div>

        <transition name="el-fade-in">
            <el-button
                v-show="scrollToBottomVisible"
                circle
                class="chat-window__scroll-bottom"
                type="primary"
                @click="handleScrollToBottomClick"
            >
                <iconify-icon icon="akar-icons:chevron-down" />
            </el-button>
        </transition>
    </div>
</template>

<script setup lang="ts">
import { NotificationGroups } from '@/enums';
import { computed, ref, watch } from 'vue';
import { useFormat } from '@/composable/useFormat';
import { useStore } from '@/composable/useStore';
import { Notification } from '@/types/Notification';
import { useDayjs } from '@/composable/useDayjs';
import { useInfiniteScroll, useScroll } from '@vueuse/core';
import { debounce } from 'lodash';

const { formatDate } = useFormat();
const store = useStore();
const dayjs = useDayjs();

interface Props {
    title?: string,
    group?: NotificationGroups | undefined
}

const props = withDefaults(defineProps<Props>(), {
    title: '',
    group: undefined
});

const groupedMessages = computed(() => store.getters['notification/groupedNotifications']);
const isLoading = computed(() => store.state.notification.isNotificationsLoading);

watch(() => props.group, (val) => {
    if (val) {
        fetchNotifications();
    }
}, { immediate: true });

const isNotificationsLoading = computed(() => store.state.notification.isNotificationsLoading);
const markedMessages = ref<Notification[]>([]);
watch(() => markedMessages.value.length, debounce(async (val) => {
    if (
        val > 0 &&
        (await store.dispatch('notification/bulkRead', {
            data: markedMessages.value
        }))
    ) {
        markedMessages.value = [];
    }
}, 750));

async function fetchNotifications (loadMore = false) {
    const params: Record<string, any> = { notificationGroup: props.group };

    if (loadMore && lastItem.value) {
        params.cursor = lastItem.value.id;
    }

    const data = await store.dispatch('notification/fetchNotifications', {
        params,
        loadMore
    });

    lastItem.value = (data['hydra:member'] ?? []).at(-1);
}

function handleVisibilityChange ({ visible, message }: { visible: boolean, message: Notification }) {
    if (visible && !message.readAt && !markedMessages.value.some(i => i.id === message.id)) {
        markedMessages.value.push(message);
    }
}

const lastItem = ref(null);
const scrollWrapper = ref(null);
const scrollToBottomVisible = computed(() => !arrivedState.bottom);

const { y, arrivedState } = useScroll(scrollWrapper, { behavior: 'smooth' });
const { reset } = useInfiniteScroll(
    scrollWrapper,
    () => {
        if (lastItem.value && !isNotificationsLoading.value) {
            fetchNotifications(true);
        }
    },
    {
        direction: 'top',
        interval: 300
    }
);

function handleScrollToBottomClick () {
    y.value = scrollWrapper.value.scrollHeight;
}
</script>

<style lang="scss">
.chat-window {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    &__header {
        flex-shrink: 0;
    }

    &__main {
        flex-grow: 1;
        display: flex;
        flex-direction: column-reverse;
    }

    &__info {
        border-radius: 16px;
        background-color: $--color-primary-light-9;
        font-size: 11px;
        line-height: 12px;
        padding: 4px 8px;
        box-shadow: $--box-shadow-dark;
    }

    &__wrapper {
        margin: auto;
    }

    &__scroll-bottom {
        position: absolute;
        bottom: 32px;
        right: 32px;
    }
}
</style>
