import type { PluginFunction } from 'vue';
import Vue from 'vue';
import type { RawLocation } from 'vue-router';
import { MessageBox } from 'element-ui';
import { cloneDeep, isObject, transform } from 'lodash';
import { getTopWindow } from '@/utils';
import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';

export type RouteDrawerRoute = RawLocation;

export interface RouteDrawerOptions {
    modal?: boolean,
    width?: number | string,
    preventHistoryChange?: boolean,
    dirty?: boolean
}

export interface RouteDrawer {
    push (route: RouteDrawerRoute, options?: RouteDrawerOptions): void,

    replace (to: RouteDrawerRoute, from?: RouteDrawerRoute, options?: RouteDrawerOptions): void,

    pop (): void,

    markDirty (state: boolean, route?: RouteDrawerRoute): void,

    install: PluginFunction<never>
}

declare module 'vue/types/vue' {
    interface Vue {
        readonly $drawer: RouteDrawer;
    }
}

export function createDrawer (): RouteDrawer {
    const createRouteForMessage = (route: RouteDrawerRoute) => {
        if (!route) {
            return null;
        }

        let normalizedRoute;
        // Докидываем в параметры данные из текущего роута
        if (typeof route === 'object') {
            normalizedRoute = {
                ...route,
                params: {
                    ...router.currentRoute.params,
                    ...(route.params ?? {}),
                },
            };
        } else {
            normalizedRoute = route;
        }
        const { route: resolvedRoute } = router.resolve(normalizedRoute);

        return Object.freeze({
            name: resolvedRoute.name,
            meta: resolvedRoute.meta,
            path: resolvedRoute.path,
            hash: resolvedRoute.hash,
            query: { ...resolvedRoute.query },
            params: { ...resolvedRoute.params },
            fullPath: resolvedRoute.fullPath,
        });
    };

    const eventPrefix = 'drawerRoute:';

    const cloneDeepOmitted = (obj: Record<string, any>, keys: string[]) =>
        transform(obj, (result, value, key) => {
            if (keys.includes(key)) {
                return;
            }
            result[key] = isObject(value) ? cloneDeepOmitted(value, keys) : value;
        }, {} as Record<string, any>);

    const postMessage = (action: string, params?: object) => {
        getTopWindow().postMessage(
            {
                action: eventPrefix + action,
                /**
                 * Почему омитим mobilePath?
                 * Потому что postMessage не хавает функции и фаталится
                 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm#things_that_dont_work_with_structured_clone
                 */
                params: cloneDeepOmitted(params ?? {}, ['mobilePath'])
            },
            window.location.origin,
        );
    };
    let stopPopstateListener = false;

    // Редирект на нормальный URL с хэшового
    if (window.location.hash) {
        const resolved = router.resolve(window.location.hash.substring(1));
        if (resolved.href !== '/' && window.location.href !== resolved.href) {
            window.location.href = resolved.href;
        }
    }

    // Запрещаем открывать iframe=1 ссылки вне iframe-а
    const cleanHref = window.location.href.replace(/[?&]iframe=1/g, '');
    if (window.self === window.top && window.location.href !== cleanHref) {
        window.location.href = cleanHref;
    }

    window.addEventListener('message', ({ origin, data }) => {
        if (
            origin !== window.location.origin ||
            !(data?.action || '').startsWith(eventPrefix)
        ) {
            return;
        }

        const action = data.action.substring(eventPrefix.length);
        const params = data.params ?? {};
        const options = params.options ?? {};

        if (action === 'push') {
            const { route } = router.resolve(params.route);
            store.dispatch('ui/routeDrawer/push', { route, options });
            if (!route.meta?.drawerOptions?.preventHistoryChange && !options.preventHistoryChange) {
                window.history.pushState({ iframe: route.fullPath }, '', `#${route.fullPath}`);
            }
        } else if (action === 'pop') {
            const items = store.getters['ui/routeDrawer/items'];
            if (items.length > 0) {
                const topItem = items[items.length - 1];
                const dirty = topItem.options.dirty ?? false;
                const doPop = () => {
                    store.dispatch('ui/routeDrawer/pop');
                    if (window.history.state?.iframe) {
                        // Костыль, чтобы popstate-обработчик не закрывал следующий drawer
                        stopPopstateListener = true;
                        setTimeout(() => {
                            stopPopstateListener = false;
                        }, 50);
                        window.history.back();
                    }
                };
                if (dirty) {
                    MessageBox
                        .confirm(i18n.t('message.confirmDataIsDirty') as string)
                        .then(doPop);
                } else {
                    doPop();
                }
            }
        } else if (action === 'replace') {
            const items = store.getters['ui/routeDrawer/items'];
            let from;
            if (params.from) {
                const { route } = router.resolve(params.from);
                from = route;
            }

            const { route: to } = router.resolve(params.to);

            if (items.length === 0) {
                window.location.href = to.fullPath;
                return;
            }

            store.dispatch('ui/routeDrawer/replace', {
                to,
                from,
                options,
            });
            if (!to.meta?.drawerOptions?.preventHistoryChange && !options.preventHistoryChange) {
                window.history.replaceState({ iframe: to.fullPath }, '', `#${to.fullPath}`);
            }
        } else if (action === 'updateOptions') {
            const payload: any = {
                options,
                extend: Boolean(params.extend),
            };
            if (params.route) {
                const { route } = router.resolve(params.route);
                payload.route = route;
            }
            store.dispatch('ui/routeDrawer/updateOptions', payload);
        } else if (action === 'empty') {
            while (store.getters['ui/routeDrawer/items'].length > 0) {
                store.dispatch('ui/routeDrawer/pop');
            }
        }
    });

    window.addEventListener('popstate', () => {
        if (!stopPopstateListener) {
            store.dispatch('ui/routeDrawer/pop');
        }
    });

    window.addEventListener('click', (event) => {
        if (
            event.target instanceof HTMLAnchorElement &&
            event.target.href &&
            event.target.dataset?.drawer
        ) {
            const url = new URL(event.target.href);
            if (url.origin === window.location.origin) {
                postMessage('push', {
                    route: createRouteForMessage(url.pathname + url.search),
                });
                event.preventDefault();
            }
        }
    });

    function push (route: RouteDrawerRoute, options?: RouteDrawerOptions): void {
        postMessage('push', {
            route: createRouteForMessage(route),
            options,
        });
    }

    function replace (to: RouteDrawerRoute, from?: RouteDrawerRoute, options?: RouteDrawerOptions) {
        postMessage('replace', {
            to: createRouteForMessage(to),
            from: from ? createRouteForMessage(from) : undefined,
            options,
        });
    }

    function updateOptions (options: RouteDrawerOptions, route?: RouteDrawerRoute, extend = false) {
        postMessage('updateOptions', {
            options: cloneDeep(options),
            route: route ? createRouteForMessage(route) : undefined,
            extend,
        });
    }

    function pop () {
        postMessage('pop');
    }

    function markDirty (state: boolean, route?: RouteDrawerRoute) {
        updateOptions({ dirty: state }, route, true);
    }

    return {
        push,
        replace,
        pop,
        markDirty,
        install (app: typeof Vue): void {
            if (app.prototype.$drawer) {
                return;
            }

            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const drawer: RouteDrawer = this;

            Object.defineProperties(app.prototype, {
                $drawer: {
                    get (): RouteDrawer {
                        return drawer;
                    },
                },
            });
        },
    };
}

export default createDrawer();
