<template>
    <ui-timeline-item-changeset-table
        :display-field="displayField"
        :data-from="itemsFrom"
        :data-to="itemsTo"
        :headers="headers"
    />
</template>

<script lang="ts">
import Vue from 'vue';
import TimelineChangeMixin from "@/mixins/timeline-changeset-items";
import FormatMixin from "@/mixins/format";
import { MeasureTypes } from "@/enums";

export default Vue.extend({
    name: 'UiTimelineItemChangesetProducts',
    mixins: [TimelineChangeMixin, FormatMixin],

    computed: {
        itemsFrom () {
            return this.changeset.changes.flatMap(i => i.from);
        },

        itemsTo () {
            return this.changeset.changes.flatMap(i => i.to);
        },

        headers () {
            return [
                { title: 'Наименование', key: 'service.name', forceShow: true, },
                { title: 'Описание', key: 'description', forceShow: true, },
                { title: 'Цена', key: 'price', align: 'right', value: (v: any) => this.formatPrice(v.price), },
                { title: 'Количество', key: 'quantity', align: 'right', value: (v: any) => `${this.formatNumber(v.quantity, undefined, v.unit === MeasureTypes.UNITS)} ${this.$t(`enum.measureTypes.${v.unit}`)}`, },
                { title: 'Сумма', key: 'priceSum', align: 'right', value: (v: any) => this.formatPrice(v.priceSum), forceShow: true, },
            ];
        },
    }
});
</script>
