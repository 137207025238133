<template>
    <div
        :class="['chat-list-item', { '--active': active }]"
        @click="$emit('click')"
    >
        <div class="chat-list-item__main">
            <div class="chat-list-item__title">
                {{ title }}
            </div>
            <div
                v-if="unreadQuantity"
                class="chat-list-item__tag"
            >
                {{ unreadQuantity }}
            </div>
            <div class="chat-list-item__content">
                <div
                    class="chat-list-item__subtitle"
                    :class="{ '--system': !formattedSubtitle }"
                >
                    {{ formattedSubtitle || 'Сообщений пока нет' }}
                </div>
                <div
                    v-if="date"
                    class="chat-list-item__date"
                    :title="formatDate(date, 'DD.MM.YYYY HH:mm')"
                >
                    {{ formattedDate }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'UiChatListItem'
});
</script>

<script setup lang="ts">
import { computed } from 'vue';
import { useDayjs } from '@/composable/useDayjs';
import { useFormat } from '@/composable/useFormat';
import DOMPurify from 'dompurify';
import { parse } from 'marked';

const dayjs = useDayjs();
const { formatDate } = useFormat();

interface Props {
    title: string,
    subtitle?: string | undefined,
    date?: string | undefined,
    active?: boolean,
    unreadQuantity?: number | undefined
}

const props = withDefaults(defineProps<Props>(), {
    active: false,
    date: undefined,
    unreadQuantity: undefined,
    subtitle: undefined
});

const formattedSubtitle = computed(() => DOMPurify.sanitize(
    parse(props.subtitle ?? '') as string,
    { USE_PROFILES: { html: false } }
));

const formattedDate = computed(() => {
    const isSameDay = dayjs().isSame(props.date as string, 'date');
    const isSameWeek = dayjs().subtract(6, 'day').isSameOrBefore(props.date as string, 'date');

    if (isSameDay) {
        return formatDate(props.date, 'HH:mm');
    }

    if (isSameWeek) {
        return formatDate(props.date, 'dddd');
    }

    return formatDate(props.date, 'DD.MM.YYYY');
});
</script>

<style lang="scss">
.chat-list-item {
    $b: &;
    padding: 8px;
    border-bottom: 1px solid $--border-color-base;
    transition: 0.2s;
    cursor: pointer;
    position: relative;

    &:hover {
        background-color: $--background-color-base;
    }

    &.--active {
        background-color: $--color-primary-light-9;
    }

    &:not(.--active) {
        #{$b} {
            &__subtitle {
                color: $--color-text-secondary;
            }

            &__date {
                color: $--color-text-secondary;
            }
        }
    }

    &__title-row {
        display: flex;
        align-items: center;
    }

    &__title {
        font-weight: bold;
        line-height: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 32px;
        margin-bottom: 8px;
    }

    &__tag {
        position: absolute;
        top: 8px;
        right: 8px;
        border-radius: 10px;
        background-color: $--color-danger;
        flex-shrink: 0;
        font-size: 11px;
        font-weight: bold;
        color: $--color-white;
        line-height: 20px;
        text-align: center;
        min-width: 20px;
        max-width: 48px;
        height: 20px;
        padding-left: 6px;
        padding-right: 6px;
        margin-left: auto;
    }

    &__content {
        display: flex;
        align-items: flex-end;
    }

    &__subtitle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 8px;
        flex-grow: 1;

        &.--system {
            font-style: italic;
        }
    }

    &__date {
        flex-shrink: 0;
        margin-left: auto;
        font-size: 12px;
    }
}
</style>
