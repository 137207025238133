import type { PluginFunction } from 'vue';
import Vue from 'vue';
import { iframeResizer } from 'iframe-resizer';

export default ((app: typeof Vue): void => {
    app.directive('iframeresize', {
        bind (el, { value = {} }) {
            if (value === false) {
                return;
            }

            el.addEventListener('load', () => iframeResizer(value, el));
        },
        unbind (el: any) {
            el.iFrameResizer?.removeListeners();
        },
    });
}) as PluginFunction<any>;
