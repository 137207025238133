<template>
    <div
        class="home-layout"
        :class="{
            'is-nav-drawer-show': isNavDrawerShow,
            'no-transitions': stopTransitions
        }"
    >
        <main class="home-layout__main">
            <navigation-drawer v-if="hasNavDrawer">
                <template #prepend>
                    <div class="home-layout__logo">
                        <ui-logo />
                    </div>
                </template>

                <user-panel expanded />

                <business-group-current-select />

                <context-menu />
            </navigation-drawer>

            <div class="home-layout__content">
                <div class="home-layout__page-header">
                    <portal-target
                        name="page-title"
                        class="page-title home-layout__page-title"
                    >
                        {{ routeTitle }}
                        <portal-target
                            class="page-title home-layout__page-action-double"
                            name="page-action-double"
                        />
                    </portal-target>

                    <portal-target
                        name="page-actions"
                        multiple
                        class="default-layout__page-actions"
                    />
                </div>

                <div
                    :key="currentBusinessGroupId"
                    class="default-layout__page-body"
                >
                    <slot />
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import ContextMenu from '@/components/layout/ContextMenu';
import NavigationDrawer from '@/components/layout/NavigationDrawer';
import NavigationDrawerMixin from '@/mixins/navigation-drawer';
import { getRouteTitle } from '@/utils/route';
import BusinessGroupCurrentSelect from '@/components/business-group/BusinessGroupCurrentSelect.vue';
import UserPanel from '@/components/layout/UserPanel.vue';

export default {
    components: {
        UserPanel,
        BusinessGroupCurrentSelect,
        ContextMenu,
        NavigationDrawer
    },

    mixins: [NavigationDrawerMixin],

    props: {
        mode: {
            type: String,
            default: 'default',
        },
    },

    computed: {
        me () {
            return this.$store.getters.me;
        },
        routeTitle () {
            return getRouteTitle(this.$route);
        },
        currentBusinessGroupId () {
            return this.$store.getters.currentBusinessGroupId;
        }
    }
};
</script>
