import * as api from '@/api/contractor/contractorService';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return api.getServices(params);
    },
    doFetchSingle (id, params) {
        return api.getService(id, params);
    },
    doCreate (data) {
        return api.createService(data);
    },
    doUpdate (id, data) {
        return api.updateService(id, data);
    },
    doDelete (id) {
        return api.deleteService(id);
    },
});
