<template>
    <div class="department-cascader">
        <el-radio-group
            v-if="withRoot"
            v-model="type"
            @change="handleTypeChange"
        >
            <el-radio-button label="root">
                <template v-if="rootLabel">
                    {{ rootLabel }}
                </template>
                <template v-else-if="currentBusinessName">
                    {{ currentBusinessName }}
                </template>
                <template v-else>
                    {{ $t('label.business') }}
                </template>
            </el-radio-button>
            <el-radio-button label="select">
                {{ $t('label.department') }}
            </el-radio-button>
        </el-radio-group>

        <el-cascader
            v-if="!withRoot || type === 'select'"
            v-model="currentValue"
            :options="departmentTree"
            :props="cascaderProps"
            v-bind="proxyProps"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import WrapperMixin from '@/mixins/wrapper';

export default {
    name: 'UiDepartmentCascader',

    mixins: [WrapperMixin],

    props: {
        value: {
            type: [String, Array],
            default: null,
        },

        multiple: {
            type: Boolean,
            default: false,
        },

        withRoot: {
            type: Boolean,
            default: false,
        },

        rootLabel: {
            type: String,
            default: '',
        },

        props: {
            type: Object,
            default: () => ({}),
        },
    },

    data () {
        return {
            type: '',
        };
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (value) {
                this.$emit('input', value);
            },
        },

        cascaderProps () {
            return {
                label: 'name',
                value: 'id',
                multiple: this.multiple,
                checkStrictly: true,
                emitPath: false,
                expandTrigger: 'hover',
                ...this.props,
            };
        },

        departments () {
            return this.$store.getters['department/list'];
        },

        departmentTree () {
            const tree = [];
            const mappedArr = {};

            const departments = cloneDeep(this.departments)
                .filter(item => item.level !== 0)
                .map(item => {
                    item.level--;
                    if (item.level === 0) {
                        item.parent = null;
                    }
                    return item;
                });

            departments
                .forEach(item => {
                    const id = item.id;
                    if (!Object.prototype.hasOwnProperty.call(mappedArr, id)) {
                        mappedArr[id] = item;
                    }
                });

            for (const id in mappedArr) {
                if (Object.prototype.hasOwnProperty.call(mappedArr, id)) {
                    const mappedElem = mappedArr[id];
                    if (mappedElem.parent && mappedElem.parent.id) {
                        const parentId = mappedElem.parent.id;
                        if (typeof mappedArr[parentId].children === 'undefined') {
                            mappedArr[parentId].children = [];
                        }
                        mappedArr[parentId].children.push(mappedElem);
                    } else {
                        tree.push(mappedElem);
                    }
                }
            }

            return tree;
        },

        rootDepartment () {
            return this.departments.find(item => item.level === 0);
        },

        isRootSelected () {
            return this.departments.some(item => item.id === this.currentValue && item.level === 0);
        },

        currentBusinessName () {
            const business = this.$store.getters['business/current'];
            if (business) {
                return business.name;
            }

            return '';
        },
    },

    watch: {
        isRootSelected: {
            handler (value) {
                if (value) {
                    this.type = 'root';
                } else if (this.currentValue) {
                    this.type = 'select';
                }
            },

            immediate: true,
        },
    },

    created () {
        if (!this.$store.state.department.isFetchedList && this.$store.getters['department/list'].length === 0) {
            this.$store.dispatch('department/fetchList');
        }
    },

    methods: {
        handleTypeChange (value) {
            if (value === 'root' && this.rootDepartment) {
                this.$emit('input', this.multiple ? [this.rootDepartment.id] : this.rootDepartment.id);
                this.$emit('change', this.multiple ? [this.rootDepartment.id] : this.rootDepartment.id);
            } else {
                this.$emit('input', this.multiple ? [] : '');
                this.$emit('change', this.multiple ? [] : '');
            }
        },
    },
};
</script>
