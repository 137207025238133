<template>
    <div class="section">
        <div class="section__inner">
            <div class="section__title">
                <slot
                    v-if="$slots.title"
                    name="title"
                />
                <template v-else>
                    {{ title }}
                </template>
            </div>
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'UiSection',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss">
.section {
    position: relative;
    padding-top: 8px;

    &__title {
        position: absolute;
        top: -8px;
        margin-left: -5px;
        padding: 0 5px;
        background: #fff;
        line-height: 16px;
        font-weight: bold;

        .el-link {
            display: inline;
            font-size: inherit;
            font-weight: inherit;

            &:before {
                content: '-';
                margin-right: 4px;
            }

            &:after {
                display: none;
            }
        }
    }

    &__inner {
        position: relative;
        padding: 24px 10px 16px;
        border: $--border-base;
        border-radius: $--border-radius-base;
    }
}
</style>
